import React, { useEffect, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

// Custom hook to scroll to top on route change
function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

interface NavigationItem {
  name: string;
  href: string;
  newTab?: boolean;
  disabled?: boolean;
}

const navigation: {
  navigare: NavigationItem[];
  legal: NavigationItem[];
  servicii: NavigationItem[];
} = {
  navigare: [
    { name: 'Acasa', href: '/' },
    { name: 'Servicii', href: '/?section=servicii' },
    { name: 'Despre Noi', href: '/?section=despre-noi' },
    { name: 'Portofoliu', href: '/portfolio' },
    { name: 'Contact', href: '/contact' },
  ],
  legal: [
    { name: 'Termeni și Condiții', href: '/termeni-si-conditii' },
    { name: 'Politica Cookie', href: '/politica-cookie' },
    { name: 'Politica de Confidențialitate', href: '/politica-de-confidentialitate' },
    { name: 'ANPC', href: 'https://anpc.ro/ce-este-sal/', newTab: true },
    { name: 'SOL', href: 'https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage', newTab: true },
  ],
  servicii: [
    { name: 'Pentru Acasă', href: '/mos-craciun-acasa' },
    { name: 'Pentru Companii', href: '/mos-craciun-companii' },
    { name: 'Pentru Serbări', href: '/mos-craciun-serbare' },
    { name: 'Pentru Campanii', href: '#', disabled: true },
    { name: 'Pentru Influenceri', href: '#', disabled: true },
  ],
};

interface ScrollToTopLinkProps {
  to: string;
  children: ReactNode;
  className?: string;
}

export default function PublicFooter() {
  useScrollToTop();

  const ScrollToTopLink: React.FC<ScrollToTopLinkProps> = ({ to, children, className }) => {
    const handleClick = () => {
      window.scrollTo(0, 0);
    };

    return (
      <Link to={to} className={className} onClick={handleClick}>
        {children}
      </Link>
    );
  };

  return (
    <footer aria-labelledby="footer-heading" className="bg-white">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <img
            src="/logoinchiriazamoscraciun.png"
            alt="InchiriazaMosCraciun"
            className="h-16 w-auto"
          />

          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900">Navigare</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.navigare.map((item) => (
                    <li key={item.name}>
                      <ScrollToTopLink to={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                        {item.name}
                      </ScrollToTopLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-gray-900">Legal</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      {item.newTab ? (
                        <a 
                          href={item.href} 
                          className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                          target="_blank" 
                          rel="noopener noreferrer"
                        >
                          {item.name}
                        </a>
                      ) : (
                        <ScrollToTopLink to={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                          {item.name}
                        </ScrollToTopLink>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-1 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900">Servicii</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.servicii.map((item) => (
                    <li key={item.name}>
                      {item.disabled ? (
                        <span className="text-sm leading-6 text-gray-400 cursor-not-allowed">
                          {item.name}
                        </span>
                      ) : (
                        <ScrollToTopLink to={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                          {item.name}
                        </ScrollToTopLink>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 flex justify-start items-center space-x-4">
          <a href="https://anpc.ro/ce-este-sal/" target="_blank" rel="noopener noreferrer">
            <img src="/anpc-sal.webp" alt="ANPC SAL" className="h-8 w-auto" />
          </a>
          <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage" target="_blank" rel="noopener noreferrer">
            <img src="/anpc-sol.webp" alt="ANPC SOL" className="h-8 w-auto" />
          </a>
        </div>

        <div className="mt-4 border-t border-gray-900/10 pt-4 sm:mt-6 lg:mt-8 lg:flex lg:items-center lg:justify-between">
          <div>
            <h3 className="text-sm font-semibold leading-6 text-gray-900">
              Un proiect susținut de către <a href="http://www.agentiadecasting.ro" className="underline text-red-600">Agenția de Casting</a>
            </h3>
            <p className="mt-2 text-sm leading-6 text-gray-600">
              Cu o experiență de peste 15 ani în selectarea actorilor și personajelor pentru reclame, filme, am dus acest know-how într-un nou univers: Sărbătorile de Iarnă.
            </p>
          </div>
          <div className="mt-4 lg:mt-0 lg:ml-4 lg:flex-shrink-0">
            <img
              src="/logoagentiadecasting.png"
              alt="Agenția de Casting"
              className="h-16 w-auto"
            />
          </div>
        </div>

        <div 
          className="mt-8 border-t border-gray-900/10 md:flex md:items-center md:justify-between"
          style={{
            paddingTop: '1rem',
            marginTop: '1rem',
          }}
        >
          <p className="text-xs leading-5 text-gray-500 md:order-1 md:mt-0">
            &copy; 2024 inchiriazamoscraciun.ro. Toate drepturile rezervate.
          </p>
        </div>
      </div>
    </footer>
  );
}