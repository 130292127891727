import React, { useState, useEffect } from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppFloatingButton: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const handleWhatsAppClick = () => {
    window.open(`https://wa.me/40748161436`, '_blank');
  };

  return (
    <div
      className={`fixed bottom-5 right-5 z-50 cursor-pointer transition-all duration-300 ease-in-out whatsapp-button ${
        isVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-95'
      }`}
      onClick={handleWhatsAppClick}
    >
      <div className="relative w-24 h-24">
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-14 h-14 rounded-full bg-green-500 flex items-center justify-center shadow-lg">
            <FaWhatsapp className="text-white text-3xl" />
          </div>
        </div>
        <svg className="w-24 h-24 animate-spin-slow" viewBox="0 0 100 100">
          <path
            id="circlePath"
            d="M 50, 50 m -35, 0 a 35,35 0 1,1 70,0 a 35,35 0 1,1 -70,0"
            fill="none"
          />
          <text>
            <textPath
              xlinkHref="#circlePath"
              className="text-green-600 text-[8px] font-bold"
              startOffset="50%"
              textAnchor="middle"
              letterSpacing="1px"
            >
              Rezervă acum • Un Moș Crăciun autentic •
            </textPath>
          </text>
        </svg>
      </div>
    </div>
  );
};

export default WhatsAppFloatingButton;