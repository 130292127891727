import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MagnifyingGlassIcon, ChevronRightIcon, PlusIcon } from '@heroicons/react/20/solid';
import { getPublicCharacters, PublicCharacter, addPublicCharacter } from '../../services/publicCharacterService';
import { useNotification, NotificationProvider } from '../components/Notification';
import AddPublicCharacterDrawer from '../components/AddPublicCharacterDrawer';

// Funcție pentru a elimina un singur /uploads la începutul cailor care au duplicat acest prefix
const removeExtraUploadsPrefix = (imagePath: string) => {
  if (imagePath.startsWith('/uploads/uploads')) {
    return imagePath.replace('/uploads/uploads', '/uploads');
  }
  return imagePath;
};

const PublicModelPageContent: React.FC = () => {
  const navigate = useNavigate();
  const [models, setModels] = useState<PublicCharacter[]>([]);
  const [filteredModels, setFilteredModels] = useState<PublicCharacter[]>([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 10;
  const { addNotification } = useNotification();
  const [filterCriteria, setFilterCriteria] = useState({ searchTerm: '' });

  useEffect(() => {
    fetchCharacters();
  }, []);

  useEffect(() => {
    const filtered = applyFilters();
    setFilteredModels(filtered);
  }, [models, filterCriteria]);

  const fetchCharacters = async () => {
    setIsLoading(true);
    try {
      const data = await getPublicCharacters();
      setModels(data);
      setFilteredModels(data);
    } catch (error) {
      addNotification('error', 'Eroare la obținerea personajelor publice', 'Nu s-au putut încărca personajele.');
    } finally {
      setIsLoading(false);
    }
  };

  const applyFilters = () => {
    return models.filter((model) => {
      const matchesSearchTerm = model.name.toLowerCase().includes(filterCriteria.searchTerm.toLowerCase());
      return matchesSearchTerm;
    });
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFilterCriteria((prev) => ({ ...prev, searchTerm: value }));
    setCurrentPage(1); // Reset la prima pagină când se schimbă filtrul
  };

  const getCharacterImage = (model: PublicCharacter) => {
    if (model.mainImage) {
      const photoUrl = removeExtraUploadsPrefix(model.mainImage);
      return <img className="h-12 w-12 rounded-full object-cover" src={photoUrl} alt={model.name} />;
    } else {
      const initials = model.name.split(' ').map((n) => n[0]).join('');
      const color = '#3498db';
      const svgAvatar = `
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
          <rect width="100" height="100" fill="${color}" />
          <text x="50" y="50" font-family="Arial, sans-serif" font-size="40" fill="white" text-anchor="middle" dominant-baseline="central">
            ${initials.toUpperCase()}
          </text>
        </svg>
      `;
      const svgUrl = `data:image/svg+xml;base64,${btoa(svgAvatar)}`;
      return <img className="h-12 w-12 rounded-full" src={svgUrl} alt={model.name} />;
    }
  };

  const handleRowClick = (model: PublicCharacter) => {
    navigate(`/dashboard/public/personaje/${model._id}`);
  };

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleAddCharacter = async (formData: globalThis.FormData) => {
    try {
      await addPublicCharacter(formData);
      addNotification('success', 'Personaj public adăugat cu succes!');
      setIsDrawerOpen(false);
      fetchCharacters();
    } catch (error) {
      addNotification('error', 'Eroare la adăugarea personajului public', 'Nu s-a putut adăuga personajul.');
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  const totalPages = Math.ceil(filteredModels.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentModels = filteredModels.slice(startIndex, endIndex);

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">Personaje Publice</h1>
          <p className="mt-2 text-sm text-gray-700">O listă cu toate personajele publice disponibile.</p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            onClick={() => setIsDrawerOpen(true)}
            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            Adaugă Personaj Public
          </button>
        </div>
      </div>

      {/* Filtru de căutare */}
      <div className="w-full md:w-1/4">
        <label htmlFor="searchTerm" className="block text-sm font-medium leading-6 text-gray-900">
          Căutare
        </label>
        <div className="relative mt-2 rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="text"
            name="searchTerm"
            id="searchTerm"
            value={filterCriteria.searchTerm}
            onChange={handleFilterChange}
            className="block w-full rounded-md border-0 py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Caută personaje..."
          />
        </div>
      </div>

      {/* Tabel */}
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Nume</th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Acțiuni</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {currentModels.map((model) => (
                  <tr
                    key={model._id}
                    onClick={() => handleRowClick(model)}
                    className="group hover:bg-gray-50 cursor-pointer"
                  >
                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                      <div className="flex items-center">
                        <div className="h-12 w-12 flex-shrink-0">
                          {getCharacterImage(model)}
                        </div>
                        <div className="ml-4">
                          <div className="font-medium text-gray-900">{model.name}</div>
                        </div>
                      </div>
                    </td>
                    <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <ChevronRightIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-700" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Paginare */}
      <div className="mt-4 flex items-center justify-between">
        <div className="flex items-center">
          <span className="text-sm text-gray-700">
            Afișare {startIndex + 1} - {Math.min(endIndex, filteredModels.length)} din {filteredModels.length} rezultate
          </span>
        </div>
        {totalPages > 1 && (
          <div className="flex items-center justify-center space-x-2">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 disabled:opacity-50"
            >
              Anterior
            </button>
            {Array.from({ length: totalPages }).map((_, index) => (
              <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                  currentPage === index + 1
                    ? 'bg-indigo-600 text-white focus:z-10'
                    : 'ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="relative inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 disabled:opacity-50"
            >
              Următorul
            </button>
          </div>
        )}
      </div>

      <AddPublicCharacterDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onAddCharacter={handleAddCharacter}
      />
    </div>
  );
};

const PublicModelPage: React.FC = () => {
  return (
    <NotificationProvider>
      <PublicModelPageContent />
    </NotificationProvider>
  );
};

export default PublicModelPage;