import React, { useState, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { addOrder, Order } from '../../services/orderService';
import { useNotification } from '../components/Notification';
import OrderDetailsSection from '../components/orders/OrderDetailsSection';
import CustomerSection from '../components/orders/ClientSection';
import BillingSection from '../components/orders/BillingSection';
import { Customer } from '../../services/customerService';
import { Character } from '../../services/characterService';
import { DocumentIcon } from '@heroicons/react/24/outline';

interface ExtraCharacter {
  character: Character;
  modelFee: number;
}

interface ExtendedOrder extends Omit<Order, 'documents' | 'address' | 'zone'> {
  documents?: (string | File)[];
  address: string;
  zone: string;
  notes?: string;
  extraCharacters: ExtraCharacter[];
}

const NewOrder: React.FC = () => {
  const [orderData, setOrderData] = useState<Partial<ExtendedOrder>>({
    extraCharacters: []
  });
  const [customerData, setCustomerData] = useState<{ client: string | null; newCustomer?: Partial<Customer> }>({ client: null });
  const [documents, setDocuments] = useState<File[]>([]);
  const [orderAddress, setOrderAddress] = useState('');
  const [orderZone, setOrderZone] = useState('');
  const { addNotification } = useNotification();
  const navigate = useNavigate();

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setDocuments(Array.from(e.target.files));
    }
  };

  const handleAddExtraCharacter = () => {
    const newCharacter: Character = {
      _id: '',
      firstName: '',
      lastName: '',
      characterType: '',
      email: '',
      phone1: '',
      cnp: '',
      address: '',
      iban: '',
      contractNumber: '',
      eyeColor: '',
      experience: 0,
      birthDate: '',
      languages: [],
      profileStatus: 'active',
      photos: [],
      createdAt: '',
      updatedAt: ''
    };

    setOrderData(prevData => ({
      ...prevData,
      extraCharacters: [
        ...(prevData.extraCharacters || []),
        { character: newCharacter, modelFee: 0 }
      ]
    }));
  };

  const handleRemoveExtraCharacter = (index: number) => {
    setOrderData(prevData => ({
      ...prevData,
      extraCharacters: prevData.extraCharacters?.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const formData = new FormData();

      const orderDataToSend = {
        ...orderData,
        extraCharacters: orderData.extraCharacters?.map(char => ({
          character: char.character._id,
          modelFee: char.modelFee
        })),
        character: orderData.character?._id,
        driver: orderData.driver?._id,
        billingInfo: {
          ...orderData.billingInfo,
          extraFees: undefined
        }
      };

      Object.entries(orderDataToSend).forEach(([key, value]) => {
        if (key !== 'documents' && value !== undefined) {
          formData.append(key, JSON.stringify(value));
        }
      });

      documents.forEach((doc) => {
        formData.append('documents', doc, doc.name);
      });

      if (customerData.client) {
        formData.append('client', JSON.stringify({ _id: customerData.client }));
      } else if (customerData.newCustomer) {
        formData.append('newCustomer', JSON.stringify(customerData.newCustomer));
      }

      await addOrder(formData);

      addNotification('success', 'Succes', 'Comanda a fost adăugată cu succes.');
      navigate('/dashboard/comenzi');
    } catch (error) {
      console.error('Eroare la adăugarea comenzii:', error);
      addNotification('error', 'Eroare', 'Nu s-a putut adăuga comanda.');
    }
  };

  const updateOrderData = useCallback((sectionData: Partial<ExtendedOrder>) => {
    setOrderData(prevData => {
      const newData = { ...prevData, ...sectionData };

      if (sectionData.billingInfo && 'extraFees' in sectionData.billingInfo) {
        const extraFees = sectionData.billingInfo.extraFees as Record<number, number>;
        newData.extraCharacters = (newData.extraCharacters || []).map((char, index) => ({
          ...char,
          modelFee: extraFees[index] ?? char.modelFee
        }));
      }

      if (sectionData.address !== undefined) {
        setOrderAddress(sectionData.address);
      }
      if (sectionData.zone !== undefined) {
        setOrderZone(sectionData.zone);
      }

      return {
        ...newData,
        billingInfo: {
          ...prevData.billingInfo,
          ...(sectionData.billingInfo || {}),
          totalAmount: sectionData.billingInfo?.totalAmount ?? prevData.billingInfo?.totalAmount ?? 0,
          modelFee: sectionData.billingInfo?.modelFee ?? prevData.billingInfo?.modelFee ?? 0,
          driverFee: sectionData.billingInfo?.driverFee ?? prevData.billingInfo?.driverFee ?? 0,
          billingType: sectionData.billingInfo?.billingType ?? prevData.billingInfo?.billingType ?? 'individual',
        },
        address: sectionData.address ?? prevData.address,
        zone: sectionData.zone ?? prevData.zone,
        notes: sectionData.notes ?? prevData.notes,
      };
    });
  }, []);

  const updateCustomerData = useCallback((data: { client: string | null; newCustomer?: Partial<Customer> }) => {
    setCustomerData(data);
  }, []);

  return (
    <div className="">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div className="sm:flex sm:items-center">
          <Link to="/dashboard/comenzi" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
            ← Înapoi la lista de comenzi
          </Link>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 mt-6">
        <div className="space-y-8 sm:space-y-5">
          <OrderDetailsSection 
            updateOrderData={updateOrderData} 
            setOrderAddress={setOrderAddress}
            setOrderZone={setOrderZone}
            extraCharacters={orderData.extraCharacters || []}
            onAddExtraCharacter={handleAddExtraCharacter}
            onRemoveExtraCharacter={handleRemoveExtraCharacter}
          />
          <CustomerSection 
            updateOrderData={updateCustomerData}
            orderAddress={orderAddress}
            orderZone={orderZone}
          />
          <BillingSection 
            updateOrderData={updateOrderData} 
            extraCharacters={orderData.extraCharacters || []}  // Trimitem `extraCharacters`
          />
        </div>

        <div className="pt-8">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Documente</h3>
          <div className="mt-4">
            <label htmlFor="file-upload" className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500">
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                <div className="space-y-1 w-100 text-center">
                  <DocumentIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />
                  <div className="flex text-sm text-gray-600 w-full justify-center">
                    <span className="w-full text-center">Încarcă documente</span>
                    <input id="file-upload" name="file-upload" type="file" multiple className="sr-only" onChange={handleFileUpload} />
                  </div>
                  <p className="text-xs text-gray-500">PNG, JPG, PDF până la 10MB</p>
                </div>
              </div>
            </label>
          </div>
          {documents.length > 0 && (
            <div className="mt-4">
              <h4 className="text-sm font-medium text-gray-900">Documente încărcate:</h4>
              <ul className="mt-2 divide-y divide-gray-200">
                {documents.map((doc, index) => (
                  <li key={index} className="py-2 flex items-center justify-between">
                    <span className="text-sm text-gray-500">{doc.name}</span>
                    <button
                      type="button"
                      onClick={() => setDocuments(docs => docs.filter((_, i) => i !== index))}
                      className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Elimină
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => navigate('/dashboard/comenzi')}
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Anulează
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Salvează
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewOrder;
