import React from 'react';
import { User } from '../../services/userService';
import { useAuth } from '../../hooks/useAuth';

interface UsersTableProps {
  users: User[];
  onEditUser: (userId: string) => void;
  onDeleteUser: (user: User) => void;
}

const UsersTable: React.FC<UsersTableProps> = ({ users, onEditUser, onDeleteUser }) => {
  const { canEditUsers, canDeleteUsers, isSuperAdmin } = useAuth();

  return (
    <div className="flow-root">
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">Nume</th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Telefon</th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Rol</th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4">
                  <span className="sr-only">Acțiuni</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {users.map((user) => (
                <tr key={user._id}>
                  <td className="whitespace-nowrap py-4 text-sm">
                    <div className="flex items-center">
                      <div className="h-10 w-10 flex-shrink-0">
                        <img className="h-10 w-10 rounded-full" src={`https://ui-avatars.com/api/?name=${user.firstName}+${user.lastName}&background=random`} alt="" />
                      </div>
                      <div className="ml-4">
                        <div className="font-medium text-gray-900">{`${user.firstName} ${user.lastName}`}</div>
                      </div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.email}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.phone}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.role}</td>
                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium">
                    {canEditUsers() && (isSuperAdmin() || user.role !== 'Super Admin') && (
                      <button
                        onClick={() => onEditUser(user._id)}
                        className="text-indigo-600 hover:text-indigo-900 mr-4"
                      >
                        Editează
                      </button>
                    )}
                    {canDeleteUsers() && (isSuperAdmin() || user.role !== 'Super Admin') && (
                      <button
                        onClick={() => onDeleteUser(user)}
                        className="text-red-600 hover:text-red-900"
                      >
                        Șterge
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UsersTable;