import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { ro } from 'date-fns/locale';
import { jwtDecode } from 'jwt-decode';
import {
  getCharacterDetails,
  cleanAddress,
  sortOrdersByDate,
  CharacterDetails,
  OrderDetails
} from '../../services/characterPanelService';
import ModernPagination from '../components/ModernPagination';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';

interface JwtPayload {
  userId: string;
}

const ITEMS_PER_PAGE = 10;

const getGreeting = () => {
  const hour = new Date().getHours();
  if (hour >= 5 && hour < 12) return { text: 'Bună dimineața', emoji: '🌅' };
  if (hour >= 12 && hour < 18) return { text: 'Bună ziua', emoji: '☀️' };
  return { text: 'Bună seara', emoji: '🌙' };
};

const CharacterOrdersPanel = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [details, setDetails] = useState<CharacterDetails | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
          throw new Error('Nu sunteți autentificat');
        }

        const decoded = jwtDecode<JwtPayload>(accessToken);
        const characterId = decoded.userId;

        const characterDetails = await getCharacterDetails(characterId);

        characterDetails.detailedOrders = characterDetails.detailedOrders.sort((a, b) => new Date(a.period.startDate).getTime() - new Date(b.period.startDate).getTime());

        setDetails(characterDetails);
        setError(null);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Eroare la încărcarea datelor');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Se încarcă datele...</div>;
  }

  if (error) {
    return <div className="text-red-600 font-semibold">Eroare: {error}</div>;
  }

  if (!details || !details.detailedOrders.length) {
    return <div>Nu există comenzi.</div>;
  }

  const greeting = getGreeting();
  const totalPages = Math.ceil(details.detailedOrders.length / ITEMS_PER_PAGE);
  const currentOrders = details.detailedOrders.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const stats = [
    { name: 'Total Comenzi', stat: details.totalOrders.toString(), previousStat: 'N/A',  changeType: 'increase' },
    { name: 'Total Câștiguri', stat: `${details.totalEarnings} RON`, previousStat: 'N/A',  changeType: 'increase' },
    { name: 'Total Câștiguri Șoferi', stat: `${details.totalDriverEarnings} RON`, previousStat: 'N/A',  changeType: 'increase' }
  ];

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <div className="bg-gray-100 px-4 sm:px-6 lg:px-8 pb-8">
      {/* Logo and Header */}
      <div className="flex flex-col items-start mb-8 space-y-4">
        <img 
          src="https://inchiriazamoscraciun.ro/logoinchiriazamoscraciun.png" 
          alt="Inchiriaza Mos Craciun" 
          className="w-16 mb-2"
        />
        <div className="text-left">
          <h1 className="text-2xl font-semibold text-gray-900">{greeting.text}, Moș Crăciun! {greeting.emoji}</h1>
          <p className="mt-2 text-sm text-gray-700">
            O listă cu toate comenzile tale, inclusiv detalii despre client, locație și remunerație.
          </p>
        </div>
      </div>

      {/* Summary Stats */}
      <div>
        <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
          {stats.map((item) => (
            <div key={item.name} className="px-4 py-5 sm:p-6">
              <dt className="text-base font-normal text-gray-900">{item.name}</dt>
              <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                  {item.stat}
                  {item.previousStat !== 'N/A' && (
                    <span className="ml-2 text-sm font-medium text-gray-500">din {item.previousStat}</span>
                  )}
                </div>
              </dd>
            </div>
          ))}
        </dl>
      </div>

      {/* Orders Table */}
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300 overflow-hidden rounded-lg bg-white shadow">
              <thead>
                <tr className="divide-x divide-gray-200">
                  <th scope="col" className="py-3.5 pl-4 pr-4 text-center text-sm font-semibold text-gray-900 sm:pl-0">
                    #
                  </th>
                  <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Client
                  </th>
                  <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Data și Ora
                  </th>
                  <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Locație
                  </th>
                  <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Detalii Plăți
                  </th>
                  <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Șofer
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {currentOrders.map((order: OrderDetails, index) => (
                  <tr key={order.orderId} className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap py-4 pl-4 pr-0 text-sm font-medium text-gray-900 text-center sm:pl-0">
                      {index + 1 + (currentPage - 1) * ITEMS_PER_PAGE}
                    </td>
                    <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                      <div className="flex items-center space-x-2">
                        <span>{order.client.name}</span>
                      </div>
                      <div className="flex items-center space-x-2">
                        <a href={`tel:${order.client.phone}`} className="text-blue-500 underline">{order.client.phone}</a>
                      </div>
                    </td>
                    <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                      <div className="flex items-center space-x-2">
                        <span>{format(new Date(order.period.startDate), 'd MMM yyyy', { locale: ro })}</span>
                      </div>
                      <div className="flex items-center space-x-2">
                        <span>{order.period.startTime} - {order.period.endTime}</span>
                      </div>
                    </td>
                    <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                      <div className="flex items-center space-x-2">
                        <span>{cleanAddress(order.location.address)}</span>
                      </div>
                      {order.location.addressDetails && (
                        <div className="flex items-center space-x-2">
                          <span>{cleanAddress(order.location.addressDetails)}</span>
                        </div>
                      )}
                    </td>
                    <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                      <div className="flex items-center space-x-2">
                        <span>Total: {order.financials.orderTotal} RON</span>
                      </div>
                      <div className="flex items-center space-x-2">
                        <span>Câștiguri: {order.financials.characterEarnings} RON</span>
                      </div>
                    </td>
                    <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                      {order.driver ? (
                        <div className="flex items-center space-x-2">
                          <span>{order.driver.name} ({order.driver.phone})</span>
                        </div>
                      ) : (
                        <span>Fără șofer</span>
                      )}
                      {order.financials.driverEarnings > 0 && (
                        <div className="flex items-center space-x-2">
                          <span>Remunerație șofer: {order.financials.driverEarnings} RON</span>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="mt-6">
          <ModernPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        </div>
      )}
    </div>
  );
};

export default CharacterOrdersPanel;
