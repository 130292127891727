import React from 'react';

interface ModernPaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const ModernPagination: React.FC<ModernPaginationProps> = ({ 
  currentPage, 
  totalPages, 
  onPageChange
}) => {
  const DOTS_JUMP = 5; // Numărul de pagini peste care sărim când apăsăm pe "..."

  const getPageNumbers = () => {
    const pageNumbers: (number | 'leftDots' | 'rightDots')[] = [];
    const showLeft = currentPage > 3;
    const showRight = currentPage < totalPages - 2;

    // Mereu arată prima pagină
    pageNumbers.push(1);

    if (showLeft) {
      pageNumbers.push('leftDots');
    }

    // Paginile din jurul celei curente
    for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
      pageNumbers.push(i);
    }

    if (showRight) {
      pageNumbers.push('rightDots');
    }

    // Mereu arată ultima pagină dacă există mai mult de o pagină
    if (totalPages > 1) {
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  const handleDotsClick = (direction: 'left' | 'right') => {
    if (direction === 'left') {
      // Mergem înapoi cu DOTS_JUMP pagini, dar nu mai puțin de pagina 1
      const nextPage = Math.max(1, currentPage - DOTS_JUMP);
      onPageChange(nextPage);
    } else {
      // Mergem înainte cu DOTS_JUMP pagini, dar nu mai mult de ultima pagină
      const nextPage = Math.min(totalPages, currentPage + DOTS_JUMP);
      onPageChange(nextPage);
    }
  };

  return (
    <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        Anterior
      </button>
      
      {getPageNumbers().map((pageNumber, idx) => {
        if (pageNumber === 'leftDots' || pageNumber === 'rightDots') {
          return (
            <button
              key={`${pageNumber}-${idx}`}
              onClick={() => handleDotsClick(pageNumber === 'leftDots' ? 'left' : 'right')}
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 cursor-pointer"
              title={pageNumber === 'leftDots' ? 'Sari înapoi 5 pagini' : 'Sari înainte 5 pagini'}
            >
              ...
            </button>
          );
        }

        return (
          <button
            key={pageNumber}
            onClick={() => onPageChange(pageNumber)}
            className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${
              currentPage === pageNumber 
                ? 'text-indigo-600 bg-indigo-50' 
                : 'text-gray-700 hover:bg-gray-50'
            }`}
          >
            {pageNumber}
          </button>
        );
      })}

      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        Următorul
      </button>
    </nav>
  );
};

export default ModernPagination;