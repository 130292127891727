import React, { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Input from '../../shared/Input';
import Selector from '../../shared/Selector';
import Upload from '../../shared/Upload';

interface AddCharacterDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  onAddCharacter: (character: globalThis.FormData) => Promise<void>;
}

interface Option {
  id: number;
  name: string;
}

const eyeColors: Option[] = [
  { id: 1, name: 'Albastru' },
  { id: 2, name: 'Verde' },
  { id: 3, name: 'Căprui' },
  { id: 4, name: 'Negru' },
  { id: 5, name: 'Gri' },
];

const characterTypes: Option[] = [
  { id: 1, name: 'Moș Crăciun' },
  { id: 2, name: 'Moș Nicolae' },
  { id: 3, name: 'Crăciuniță' },
  { id: 4, name: 'Spiriduș' },
];

const profileStatuses: Option[] = [
  { id: 1, name: 'active' },
  { id: 2, name: 'inactive' },
];

interface FormData {
  firstName: string;
  lastName: string;
  cnp: string;
  birthDate: string;
  phone1: string;
  phone2: string;
  address: string;
  iban: string;
  contractNumber: string;
  eyeColor: Option;
  experience: string;
  languages: string;
  characterType: Option;
  profileStatus: Option;
  photos: File[];
}

const AddCharacterDrawer: React.FC<AddCharacterDrawerProps> = ({ isOpen, onClose, onAddCharacter }) => {
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    cnp: '',
    birthDate: '',
    phone1: '',
    phone2: '',
    address: '',
    iban: '',
    contractNumber: '',
    eyeColor: eyeColors[0],
    experience: '',
    languages: '',
    characterType: characterTypes[0],
    profileStatus: profileStatuses[1],
    photos: [],
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const isFormValid = () => {
    return formData.firstName.trim() !== '' && 
           formData.lastName.trim() !== '' && 
           formData.photos.length > 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isFormValid()) return;
  
    setIsSubmitting(true);
    const data = new FormData();
  
    Object.entries(formData).forEach(([key, value]) => {
      if (key === 'photos') {
        formData.photos.forEach((photo) => {
          data.append('photos', photo);
        });
      } else if (typeof value === 'object' && value !== null) {
        data.append(key, value.name);
      } else {
        data.append(key, value.toString());
      }
    });
  
    try {
      await onAddCharacter(data);
    } catch (error) {
      console.error('Eroare la adăugarea personajului:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setFormData({
        firstName: '',
        lastName: '',
        cnp: '',
        birthDate: '',
        phone1: '',
        phone2: '',
        address: '',
        iban: '',
        contractNumber: '',
        eyeColor: eyeColors[0],
        experience: '',
        languages: '',
        characterType: characterTypes[0],
        profileStatus: profileStatuses[1],
        photos: [],
      });
    }
  }, [isOpen]);

  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={React.Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">Adaugă un nou personaj</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={onClose}
                          >
                            <span className="sr-only">Închide panoul</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-indigo-300">
                          Completează informațiile de mai jos pentru a crea un nou personaj.
                        </p>
                      </div>
                    </div>

                    <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                      <form onSubmit={handleSubmit}>
                        <div className="space-y-8 divide-y divide-gray-200">
                          {/* Informații personale */}
                          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                            <div>
                              <h3 className="text-lg font-medium leading-6 text-gray-900">Informații personale</h3>
                              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
                                <Input
                                  label="Prenume"
                                  id="firstName"
                                  name="firstName"
                                  type="text"
                                  value={formData.firstName}
                                  onChange={handleInputChange}
                                />
                                <Input
                                  label="Nume"
                                  id="lastName"
                                  name="lastName"
                                  type="text"
                                  value={formData.lastName}
                                  onChange={handleInputChange}
                                />
                                <Input
                                  label="CNP"
                                  id="cnp"
                                  name="cnp"
                                  type="text"
                                  value={formData.cnp}
                                  onChange={handleInputChange}
                                />
                                <Input
                                  label="Data nașterii"
                                  id="birthDate"
                                  name="birthDate"
                                  type="date"
                                  value={formData.birthDate}
                                  onChange={handleInputChange}
                                />
                                <Input
                                  label="Telefon 1"
                                  id="phone1"
                                  name="phone1"
                                  type="tel"
                                  value={formData.phone1}
                                  onChange={handleInputChange}
                                />
                                <Input
                                  label="Telefon 2"
                                  id="phone2"
                                  name="phone2"
                                  type="tel"
                                  value={formData.phone2}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </div>

                          {/* Informații contract */}
                          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                            <div className="pt-4 sm:pt-6">
                              <h3 className="text-lg font-medium leading-6 text-gray-900">Informații contract</h3>
                              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
                                <Input
                                  label="Adresă"
                                  id="address"
                                  name="address"
                                  type="text"
                                  value={formData.address}
                                  onChange={handleInputChange}
                                />
                                <Input
                                  label="IBAN"
                                  id="iban"
                                  name="iban"
                                  type="text"
                                  value={formData.iban}
                                  onChange={handleInputChange}
                                />
                                <Input
                                  label="Număr contract"
                                  id="contractNumber"
                                  name="contractNumber"
                                  type="text"
                                  value={formData.contractNumber}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </div>

                          {/* Caracteristici */}
                          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                            <div className="pt-4 sm:pt-6">
                              <h3 className="text-lg font-medium leading-6 text-gray-900">Caracteristici</h3>
                              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
                                <Selector
                                  label="Culoarea ochilor"
                                  options={eyeColors}
                                  selected={formData.eyeColor}
                                  onChange={(option) => setFormData(prev => ({ ...prev, eyeColor: option }))}
                                />
                                <Input
                                  label="Experiență (ani)"
                                  id="experience"
                                  name="experience"
                                  type="number"
                                  value={formData.experience}
                                  onChange={handleInputChange}
                                />
                                <Input
                                  label="Limbi (separate prin virgulă)"
                                  id="languages"
                                  name="languages"
                                  type="text"
                                  value={formData.languages}
                                  onChange={handleInputChange}
                                />
                                <Selector
                                  label="Tip personaj"
                                  options={characterTypes}
                                  selected={formData.characterType}
                                  onChange={(option) => setFormData(prev => ({ ...prev, characterType: option }))}
                                />
                                <Selector
                                  label="Status profil"
                                  options={profileStatuses}
                                  selected={formData.profileStatus}
                                  onChange={(option) => setFormData(prev => ({ ...prev, profileStatus: option }))}
                                />
                              </div>
                            </div>
                          </div>

                          {/* Fotografii */}
                          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                            <div className="pt-4 sm:pt-6">
                              <h3 className="text-lg font-medium leading-6 text-gray-900">Fotografii</h3>
                              <div className="mt-6">
                                <Upload onImagesSelected={(files) => setFormData(prev => ({ ...prev, photos: files }))} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={onClose}
                        disabled={isSubmitting}
                      >
                        Anulează
                      </button>
                      <button
                        type="submit"
                        className={`ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${
                          !isFormValid() || isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                        onClick={handleSubmit}
                        disabled={!isFormValid() || isSubmitting}
                      >
                        {isSubmitting ? (
                          <>
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Adăugăm personajul...
                          </>
                        ) : (
                          'Adaugă personaj'
                        )}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddCharacterDrawer;