import React, { ReactNode, useEffect } from 'react';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';

interface DialogButtonProps {
  onClick: () => void;
  className: string;
  children: ReactNode;
}

interface ReusableDialogProps {
  open: boolean;
  onClose: (value: boolean) => void;
  icon: ReactNode;
  title: string;
  children: ReactNode;
  primaryButton: DialogButtonProps;
  secondaryButton?: DialogButtonProps;
  onCloseReset?: () => void; // Funcția de resetare opțională
}

const ReusableDialog: React.FC<ReusableDialogProps> = ({
  open,
  onClose,
  icon,
  title,
  children,
  primaryButton,
  secondaryButton,
  onCloseReset, // Funcția de resetare
}) => {
  
  // Resetare form la închiderea dialogului
  useEffect(() => {
    if (!open && onCloseReset) {
      onCloseReset(); // Apelăm resetarea când dialogul se închide
    }
  }, [open, onCloseReset]);

  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                {icon}
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                  {title}
                </DialogTitle>
                <div className="mt-2">
                  {children}
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="button"
                onClick={primaryButton.onClick}
                className={primaryButton.className}
              >
                {primaryButton.children}
              </button>
              {secondaryButton && (
                <button
                  type="button"
                  onClick={secondaryButton.onClick}
                  className={secondaryButton.className}
                >
                  {secondaryButton.children}
                </button>
              )}
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default ReusableDialog;
