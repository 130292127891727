import React, { useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { BellIcon, Bars3Icon } from '@heroicons/react/24/outline';
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import { logout, getCurrentUserProfile } from '../../services/userService';

interface AdminNavbarProps {
  setSidebarOpen: (open: boolean) => void;
}

interface UserProfile {
  firstName: string;
  lastName: string;
  email: string;
  avatarUrl: string;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const AdminNavbar: React.FC<AdminNavbarProps> = ({ setSidebarOpen }) => {
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profile = await getCurrentUserProfile();
        setUserProfile({
          firstName: profile.firstName,
          lastName: profile.lastName,
          email: profile.email,
          avatarUrl: `https://ui-avatars.com/api/?name=${profile.firstName}+${profile.lastName}&background=random`
        });
      } catch (error) {
        console.error('Eroare la obținerea profilului utilizatorului', error);
      }
    };
    fetchUserProfile();
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Eroare la logout', error);
    }
  };

  return (
    <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
      <button
        type="button"
        className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>

      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        {/* Câmpul de căutare ascuns cu opacitate */}
        <form className="relative flex flex-1" action="#" method="GET" style={{ opacity: 0, pointerEvents: 'none' }}>
          <label htmlFor="search-field" className="sr-only">
            Search
          </label>
          <MagnifyingGlassIcon
            className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
            aria-hidden="true"
          />
          <input
            id="search-field"
            className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
            placeholder="Search..."
            type="search"
            name="search"
          />
        </form>

        <div className="flex items-center gap-x-4 lg:gap-x-6">
          {/* Iconița de notificări ascunsă cu opacitate */}
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
            style={{ opacity: 0, pointerEvents: 'none' }}
          >
            <span className="sr-only">View notifications</span>
            <BellIcon className="h-6 w-6" aria-hidden="true" />
          </button>

          <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />
          <Menu as="div" className="relative">
            <Menu.Button className="-m-1.5 flex items-center p-1.5">
              <span className="sr-only">Open user menu</span>
              {userProfile ? (
                <img
                  className="h-8 w-8 rounded-full bg-gray-50"
                  src={userProfile.avatarUrl}
                  alt={`${userProfile.firstName} ${userProfile.lastName}`}
                />
              ) : (
                <img
                  className="h-8 w-8 rounded-full bg-gray-50"
                  src="https://via.placeholder.com/150"
                  alt="Avatar placeholder"
                />
              )}
              <span className="hidden lg:flex lg:items-center">
                {userProfile && (
                  <>
                    <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                      {userProfile.firstName} {userProfile.lastName}
                    </span>
                    <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                  </>
                )}
              </span>
            </Menu.Button>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={handleLogout}
                      className={classNames(
                        active ? 'bg-gray-50' : '',
                        'block w-full px-3 py-1 text-sm leading-6 text-gray-900 text-left'
                      )}
                    >
                      Sign out
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default AdminNavbar;
