import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Navbar from '../components/PublicNavbar';
import PublicFooter from '../components/PublicFooter';
import WhatsAppFloatingButton from '../components/WhatsAppFloatingButton';
import CharacterCarousel from '../components/CharacterCarousel';
import { getPublicCharacters, PublicCharacter } from '../../services/publicCharacterService';

interface CharacterCardProps {
  character: PublicCharacter;
  onClick: () => void;
}

const CharacterCard: React.FC<CharacterCardProps> = ({ character, onClick }) => {
  const getBackgroundImage = (imagePath: string) => {
    if (!imagePath) return 'none';
    const encodedPath = encodeURI(imagePath);
    return `url("${encodedPath}")`;
  };

  return (
    <motion.div
      className="w-full h-[32rem] overflow-hidden relative cursor-pointer character-card rounded-lg shadow-lg"
      onClick={onClick}
      whileHover={{ scale: 1.05 }}
      transition={{ duration: 0.2 }}
    >
      <div
        className="w-full h-full bg-cover bg-center"
        style={{
          backgroundImage: getBackgroundImage(character.mainImage),
        }}
      />
      <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-60"></div>
      <div className="absolute bottom-0 left-0 right-0 p-4">
        <h3 className="text-white font-semibold text-lg line-clamp-2">{character.name}</h3>
      </div>
      {!character.mainImage && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-200">
          <span className="text-gray-500 text-sm">No Image</span>
        </div>
      )}
    </motion.div>
  );
};

const Portfolio: React.FC = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedCharacterIndex, setSelectedCharacterIndex] = useState<number | null>(null);
  const [characters, setCharacters] = useState<PublicCharacter[]>([]);
  const [visibleCharacters, setVisibleCharacters] = useState<PublicCharacter[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const pageTitle = "Galerie Moș Crăciun cu Barbă Naturală | Moși Autentici pentru Evenimente în București";
  const pageDescription = "Explorează galeria noastră de Moși Crăciun autentici cu barbă naturală. Oferim servicii premium de închiriere Moș Crăciun pentru evenimente private și corporate în București și Ilfov. Personaje autentice pentru sărbători magice!";
  const canonicalUrl = "https://www.inchiriazamoscraciun.ro/portfolio";

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "CollectionPage",
    "name": pageTitle,
    "description": pageDescription,
    "url": canonicalUrl,
    "mainEntity": {
      "@type": "ImageGallery",
      "name": "Galerie Moși Crăciun Autentici",
      "description": "Colecție de Moși Crăciun autentici cu barbă naturală, disponibili pentru evenimente",
      "about": {
        "@type": "Thing",
        "name": "Moș Crăciun cu barbă naturală pentru evenimente",
        "description": "Personaje autentice de Moș Crăciun cu barbă naturală, costume premium și experiență în evenimente"
      }
    },
    "provider": {
      "@type": "Organization",
      "name": "Închiriază Moș Crăciun",
      "url": "https://www.inchiriazamoscraciun.ro",
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "0748161436",
        "contactType": "customer service",
        "areaServed": ["București", "Ilfov"],
        "availableLanguage": ["Romanian"]
      },
      "address": {
        "@type": "PostalAddress",
        "addressRegion": "București",
        "addressCountry": "RO"
      }
    },
    "specialFeatures": ["Barbă naturală", "Costume premium", "Experiență în evenimente", "Disponibil în București și Ilfov"]
  };

  const isMobile = window.innerWidth <= 768;
  const initialVisibleCount = isMobile ? 5 : 10;

  useEffect(() => {
    const fetchCharacters = async () => {
      setIsLoading(true);
      try {
        const fetchedCharacters = await getPublicCharacters();
        setCharacters(fetchedCharacters);
        setVisibleCharacters(fetchedCharacters.slice(0, initialVisibleCount));
      } catch (error) {
        console.error('Eroare la încărcarea personajelor:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCharacters();
  }, []);

  useEffect(() => {
    document.body.style.overflow = showOverlay ? 'hidden' : '';
    return () => {
      document.body.style.overflow = '';
    };
  }, [showOverlay]);

  const handleCardClick = (index: number) => {
    if (characters[index].mainImage) {
      setSelectedCharacterIndex(index);
      setShowOverlay(true);
    }
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false);
    setSelectedCharacterIndex(null);
  };

  const loadMore = () => {
    const currentCount = visibleCharacters.length;
    const nextCharacters = characters.slice(currentCount, currentCount + initialVisibleCount);
    setVisibleCharacters([...visibleCharacters, ...nextCharacters]);
  };

  return (
    <HelmetProvider>
      <div className="min-h-screen bg-white">
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <link rel="canonical" href={canonicalUrl} />
          
          {/* Open Graph */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content={canonicalUrl} />
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:image" content="https://www.inchiriazamoscraciun.ro/og-image.jpg" />
          <meta property="og:locale" content="ro_RO" />
          <meta property="og:site_name" content="Închiriază Moș Crăciun" />

          {/* Keywords */}
          <meta name="keywords" content="mos craciun barba naturala, mos craciun bucuresti, inchiriere mos craciun, mos craciun autentic, mos craciun evenimente, mos craciun corporate, galerie mos craciun, portofoliu personaje craciun, servicii premium mos craciun" />

          {/* Additional SEO tags */}
          <meta name="robots" content="index, follow" />
          <meta name="author" content="Închiriază Moș Crăciun" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="geo.region" content="RO-B" />
          <meta name="geo.placename" content="București" />
          <meta name="geo.position" content="44.4268;26.1025" />
          <meta name="ICBM" content="44.4268, 26.1025" />
          <meta name="language" content="Romanian" />

          {/* Structured Data */}
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        </Helmet>

        <Navbar />
        <main className="bg-white py-16 sm:py-24">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Portofoliu Moși Crăciun Autentici
              </h1>
              <p className="mt-6 text-lg text-gray-600 mx-auto max-w-2xl">
                Descoperiți galeria noastră de Moși Crăciun autentici cu barbă naturală, pregătiți să aducă magia sărbătorilor la evenimentul dumneavoastră.
              </p>
            </div>
            
            {isLoading ? (
              <div className="flex justify-center items-center h-64 mt-10">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
              </div>
            ) : (
              <div className="mt-16">
                <AnimatePresence>
                  <motion.div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-10">
                    {visibleCharacters.map((character, index) => (
                      <motion.div
                        key={`${character._id}-${index}`}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.5, delay: index * 0.1 }}
                      >
                        <CharacterCard
                          character={character}
                          onClick={() => handleCardClick(index)}
                        />
                      </motion.div>
                    ))}
                  </motion.div>
                </AnimatePresence>
                {visibleCharacters.length < characters.length && (
                  <div className="mt-12 text-center">
                    <button
                      onClick={loadMore}
                      className="bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-6 rounded-lg transition duration-300 text-lg"
                    >
                      Încarcă mai mulți Moși Crăciun
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>

          <WhatsAppFloatingButton />
        </main>
        <PublicFooter />

        {showOverlay && selectedCharacterIndex !== null && (
          <div className="fixed inset-0 z-50">
            <CharacterCarousel
              characters={characters}
              initialCharacterIndex={selectedCharacterIndex}
              onClose={handleCloseOverlay}
            />
          </div>
        )}
      </div>
    </HelmetProvider>
  );
};

export default Portfolio;