import React from 'react';
import { Link } from 'react-router-dom';

export default function ChristmasServicesBento() {
  return (
    <div className="bg-white py-16 sm:pb-24 sm:pt-0 md:py-24 md:pt-0">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <p className="mt-2 max-w-4xl text-center text-pretty text-4xl font-medium tracking-tight text-gray-950 sm:text-5xl mx-auto">
          Tot ce ai nevoie pentru un Crăciun de neuitat
        </p>
        <p className="mt-6 text-lg text-center text-gray-600 mx-auto max-w-2xl">
          Descoperă serviciile noastre unice de Crăciun, concepute pentru a aduce magia sărbătorilor în casa ta, la evenimentele tale și în comunitatea ta.
        </p>
        <div className="mt-10 grid grid-cols-1 gap-4 sm:mt-16 lg:grid-cols-6 lg:grid-rows-2">
          <div className="relative lg:col-span-3">
            <div className="absolute inset-px rounded-lg bg-white max-lg:rounded-t-[2rem] lg:rounded-tl-[2rem]" />
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)] lg:rounded-tl-[calc(2rem+1px)]">
              <img
                alt="Vizită Moș Crăciun"
                src="/smoscraciunacasa.jpg"
                className="h-80 object-cover object-top"
              />
              <div className="p-10">
                <h3 className="text-sm/4 font-semibold text-red-600">Moș Crăciun cu barba naturala</h3>
                <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Magia Crăciunului la tine acasă</p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                  Rezervă-l acum și asigură-te că anul acesta Moș Crăciun va aduce nu doar cadouri, ci și cea mai frumoasă amintire de Crăciun pentru întreaga familie!
                </p>
                <Link to="/mos-craciun-acasa" className="mt-4 inline-block text-sm font-semibold text-red-600 hover:text-red-500">Află mai multe</Link>
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-t-[2rem] lg:rounded-tl-[2rem]" />
          </div>
          <div className="relative lg:col-span-3">
            <div className="absolute inset-px rounded-lg bg-white lg:rounded-tr-[2rem]" />
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-tr-[calc(2rem+1px)]">
              <img
                alt="Evenimente corporative"
                src="/moscraciuncompanii.webp"
                className="h-80 object-cover object-left lg:object-right"
              />
              <div className="p-10">
                <h3 className="text-sm/4 font-semibold text-red-600">Mos Craciun autentic</h3>
                <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Pentru evenimentul sau campania ta</p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                  Nu lăsa Magia Crăciunului să treacă neobservată! Contactează-ne acum și adu bucuria sărbătorilor la evenimentul sau campania ta de Crăciun!
                </p>
                <Link to="/mos-craciun-companii" className="mt-4 inline-block text-sm font-semibold text-red-600 hover:text-red-500">Află mai multe</Link>
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-tr-[2rem]" />
          </div>
          <div className="relative lg:col-span-2">
            <div className="absolute inset-px rounded-lg bg-white lg:rounded-bl-[2rem]" />
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-bl-[calc(2rem+1px)]">
              <img
                alt="Livrare cadouri"
                src="/moscraciunserbare.webp"
                className="h-80 object-cover object-left"
              />
              <div className="p-10">
                <h3 className="text-sm/4 font-semibold text-red-600">Momente Magice</h3>
                <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Serbarea ta, o poveste de Craciun</p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                  Invită-l pe Moș Crăciun la serbarea de la grădiniță sau școală și lasă-l să răspândească farmecul Crăciunului în sufletele tuturor.
                </p>
                <Link to="/mos-craciun-serbare" className="mt-4 inline-block text-sm font-semibold text-red-600 hover:text-red-500">Află mai multe</Link>
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-bl-[2rem]" />
          </div>
          <div className="relative lg:col-span-2">
            <div className="absolute inset-px rounded-lg bg-white" />
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]">
              <img
                alt="Ședințe foto"
                src="/moscraciuninfluenceri.webp"
                className="h-80 object-cover object-center"
              />
              <div className="p-10">
                <h3 className="text-sm/4 font-semibold text-red-600">Conținut de Poveste cu Moș Crăciun</h3>
                <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Fii Creatorul Magiei de Crăciun!</p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                  Hai să facem din Crăciunul acesta o poveste minunată, plină de zâmbete și magie,pentru tine și pentru comunitatea ta!
                </p>
                <span className="mt-4 inline-block text-sm font-semibold text-gray-400 cursor-not-allowed">Serviciu indisponibil</span>
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5" />
          </div>
          <div className="relative lg:col-span-2">
            <div className="absolute inset-px rounded-lg bg-white max-lg:rounded-b-[2rem] lg:rounded-br-[2rem]" />
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-br-[calc(2rem+1px)]">
              <img
                alt="Ateliere pentru copii"
                src="/moscraciuncaritabil.webp"
                className="h-80 object-cover object-center"
              />
              <div className="p-10">
                <h3 className="text-sm/4 font-semibold text-red-600">Împreună pentru un Crăciun mai Bun</h3>
                <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Moș Crăciun, simbolul speranței</p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                  Moș Crăciun vine fără costuri, cu inima deschisă, pentru a fi parte din povestea voastră de Crăciun!
                </p>
                <span className="mt-4 inline-block text-sm font-semibold text-gray-400 cursor-not-allowed">Serviciu indisponibil</span>
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-br-[2rem]" />
          </div>
        </div>
      </div>
    </div>
  )
}