import axios from 'axios';

export const API_URL = process.env.REACT_APP_API_URL || 'https://localhost:5000';

export interface PublicCharacter {
  _id: string;
  name: string;
  mainImage: string;
  gallery: string[];
  characterType: 'Mos Craciun' | 'Mos Nicolae' | 'Craciunita' | 'Personaj';
  isExclusivelyOnline: boolean;
  createdAt?: Date | string;
  updatedAt?: Date | string;
}

// Obținem toate personajele publice (acces public)
export const getPublicCharacters = async (): Promise<PublicCharacter[]> => {
  try {
    const response = await axios.get<PublicCharacter[]>(`${API_URL}/api/publiccharacters`);
    
    // Returnăm personajele așa cum sunt, fără a mai modifica calea imaginilor
    return response.data;
  } catch (error) {
    console.error('Eroare la obținerea personajelor publice', error);
    throw error;
  }
};

// Obținem un personaj public după ID (acces public)
export const getPublicCharacterById = async (id: string): Promise<PublicCharacter> => {
  try {
    if (!/^[0-9a-fA-F]{24}$/.test(id)) {
      throw new Error('ID-ul personajului nu este valid');
    }
    
    const response = await axios.get<PublicCharacter>(`${API_URL}/api/publiccharacters/${id}`);
    
    return response.data;
  } catch (error) {
    console.error('Eroare la obținerea detaliilor personajului public', error);
    throw error;
  }
};

// Adăugăm un personaj public (necesită autentificare)
export const addPublicCharacter = async (characterData: FormData): Promise<PublicCharacter> => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.post<PublicCharacter>(`${API_URL}/api/publiccharacters`, characterData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    
    return response.data;
  } catch (error) {
    console.error('Eroare la adăugarea personajului public', error);
    throw error;
  }
};

// Actualizăm un personaj public (necesită autentificare)
export const updatePublicCharacter = async (id: string, updateData: Partial<PublicCharacter>): Promise<PublicCharacter> => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.put<PublicCharacter>(
      `${API_URL}/api/publiccharacters/${id}`,
      updateData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    
    return response.data;
  } catch (error) {
    console.error('Eroare la actualizarea personajului public', error);
    throw error;
  }
};

// Ștergem un personaj public (necesită autentificare)
export const deletePublicCharacter = async (id: string): Promise<void> => {
  try {
    const token = localStorage.getItem('accessToken');
    await axios.delete(`${API_URL}/api/publiccharacters/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error('Eroare la ștergerea personajului public', error);
    throw error;
  }
};

// Adaugă funcție pentru încărcarea imaginilor
export const addPublicCharacterPhotos = async (characterId: string, photos: FormData): Promise<{ photos: string[] }> => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.post<{ photos: string[] }>(
      `${API_URL}/api/publiccharacters/${characterId}/photos`,
      photos,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Eroare la încărcarea fotografiilor', error);
    throw error;
  }
};

// Adaugă funcție pentru ștergerea unei imagini
export const deletePublicCharacterPhoto = async (characterId: string, photoIndex: number): Promise<string[]> => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.delete<string[]>(
      `${API_URL}/api/publiccharacters/${characterId}/photos/${photoIndex}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Eroare la ștergerea fotografiei', error);
    throw error;
  }
};

// Actualizează imaginea principală
export const updateMainImage = async (characterId: string, imageData: FormData): Promise<PublicCharacter> => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.put<PublicCharacter>(
      `${API_URL}/api/publiccharacters/${characterId}`,
      imageData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Eroare la actualizarea imaginii principale', error);
    throw error;
  }
};