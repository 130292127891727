import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { getCharacterById, Character, API_URL, addCharacterPhotos, deleteCharacterPhoto, updateCharacter, getCharacters, deleteCharacter } from '../../services/characterService';
import { useNotification } from '../components/Notification';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { FaImage, FaTrash } from 'react-icons/fa';
import { useDropzone } from 'react-dropzone';
import { ImSpinner2 } from 'react-icons/im';
import { MdCheckCircle, MdError } from 'react-icons/md';
import ModelDetailTable from '../components/ModelDetailTable';
import ConfirmDialog from '../../shared/ConfirmDialog';

const ModelDetailPage: React.FC = () => {
  const { modelId } = useParams<{ modelId: string }>();
  const [character, setCharacter] = useState<Character | null>(null);
  const [uploadStatus, setUploadStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const [isUploading, setIsUploading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const { addNotification } = useNotification();
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const fetchCharacterDetails = useCallback(async () => {
    if (modelId) {
      try {
        const data = await getCharacterById(modelId);
        setCharacter(data);
      } catch (error) {
        console.error('Eroare la obținerea detaliilor modelului:', error);
        addNotification('error', 'Eroare la încărcarea detaliilor modelului');
      }
    }
  }, [modelId, addNotification]);

  useEffect(() => {
    fetchCharacterDetails();
  }, [fetchCharacterDetails]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleUpload = async (acceptedFiles: File[]) => {
    if (!character) return;

    const formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append('photos', file);
    });

    setIsUploading(true);
    setUploadStatus('loading');
    try {
      const response = await addCharacterPhotos(character._id, formData);
      setCharacter(prevCharacter => ({
        ...prevCharacter!,
        photos: [...prevCharacter!.photos, ...response.photos.map(photo => `${API_URL}/${photo}`)],
      }));
      setUploadStatus('success');
      addNotification('success', 'Imaginile au fost încărcate cu succes');
    } catch (error) {
      console.error('Eroare la încărcarea imaginilor:', error);
      setUploadStatus('error');
      addNotification('error', 'Eroare la încărcarea imaginilor');
    } finally {
      setIsUploading(false);
      setTimeout(() => setUploadStatus('idle'), 3000);
    }
  };

  const handleDeletePhoto = async (photoIndex: number) => {
    if (!character) return;

    try {
      const updatedPhotos = await deleteCharacterPhoto(character._id, photoIndex);
      setCharacter(prevCharacter => ({
        ...prevCharacter!,
        photos: updatedPhotos,
      }));
      addNotification('success', 'Fotografia a fost ștearsă cu succes');
    } catch (error) {
      console.error('Eroare la ștergerea fotografiei:', error);
      addNotification('error', 'Eroare la ștergerea fotografiei');
    }
  };

  const navigateToAdjacentCharacter = async (direction: 'prev' | 'next') => {
    try {
      const characters = await getCharacters();
      const currentIndex = characters.findIndex(c => c._id === character?._id);
      if (currentIndex === -1) return;

      let newIndex;
      if (direction === 'prev') {
        newIndex = currentIndex > 0 ? currentIndex - 1 : characters.length - 1;
      } else {
        newIndex = currentIndex < characters.length - 1 ? currentIndex + 1 : 0;
      }

      const newCharacter = characters[newIndex];
      navigate(`/dashboard/modele/${newCharacter._id}`);
    } catch (error) {
      console.error('Eroare la navigarea între modele:', error);
      addNotification('error', 'Eroare', 'Nu s-a putut naviga la modelul adiacent.');
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
    setShowDropdown(false);
  };

  const handleSave = async () => {
    if (character) {
      try {
        const updatedCharacter = await updateCharacter(character._id, character);
        setCharacter(updatedCharacter);
        setIsEditing(false);
        addNotification('success', 'Succes', 'Datele modelului au fost actualizate.');
      } catch (error) {
        console.error('Eroare la actualizarea modelului:', error);
        addNotification('error', 'Eroare', 'Nu s-au putut actualiza datele modelului.');
      }
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    fetchCharacterDetails();
  };

  const handleDelete = async () => {
    if (character?._id) {
      try {
        await deleteCharacter(character._id);
        addNotification('success', 'Succes', 'Modelul a fost șters cu succes.');
        navigate('/dashboard/modele', { replace: true });
      } catch (error) {
        console.error('Eroare la ștergerea modelului:', error);
        addNotification('error', 'Eroare', 'Nu s-a putut șterge modelul.');
      }
    }
  };

  const handleUpdateDetail = (key: string, value: string | number | string[]) => {
    if (character) {
      if (key === 'languages' && Array.isArray(value)) {
        // Tratăm special cazul pentru limbi
        setCharacter({ ...character, [key]: value });
      } else if (typeof value === 'string' || typeof value === 'number') {
        // Pentru restul câmpurilor, tratăm ca înainte
        setCharacter({ ...character, [key]: value });
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleUpload,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    disabled: isUploading,
  });

  const renderUploadStatus = () => {
    switch (uploadStatus) {
      case 'loading':
        return <ImSpinner2 className="animate-spin text-blue-500" />;
      case 'success':
        return <MdCheckCircle className="text-green-500" />;
      case 'error':
        return <MdError className="text-red-500" />;
      default:
        return <FaImage className="text-gray-400" />;
    }
  };

  if (!character) return <div>Se încarcă...</div>;

  return (
    <div>
      <div className="sm:flex sm:items-center sm:justify-between">
        <div className="sm:flex sm:items-center">
          <Link to="/dashboard/modele" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
            ← Înapoi la lista de modele
          </Link>
        </div>
        <div className="mt-4 flex items-center justify-between sm:mt-0 sm:ml-6 sm:flex-shrink-0">
          <div className="flex items-center space-x-3">
            <button onClick={() => navigateToAdjacentCharacter('prev')} className="inline-flex items-center rounded-full border border-gray-300 bg-white p-1 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button onClick={() => navigateToAdjacentCharacter('next')} className="inline-flex items-center rounded-full border border-gray-300 bg-white p-1 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="relative ml-3" ref={dropdownRef}>
            <button
              type="button"
              onClick={() => setShowDropdown(!showDropdown)}
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Acțiuni
            </button>
            {showDropdown && (
              <div className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <a href="#" className="block px-4 py-2 text-sm text-gray-700" onClick={handleEdit}>Editare</a>
                <a href="#" className="block px-4 py-2 text-sm text-gray-700" onClick={() => setShowDeleteConfirm(true)}>Ștergere</a>
              </div>
            )}
          </div>
        </div>
      </div>

      {isEditing && (
        <div className="mt-4 rounded-md bg-yellow-50 p-4 border border-yellow-200">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">Atenție</h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>Ești în modul de editare. Modificările nu vor fi salvate până când nu apeși butonul "Salvează".</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="mt-6 border-t border-gray-100 pt-6">
        <div className="flex overflow-x-auto space-x-4 mb-4 snap-x snap-mandatory scrollbar-hide">
          {character.photos.map((photo, index) => (
            <div key={index} className="flex-shrink-0 snap-start relative group">
              <img
                src={photo}
                alt={`Poza ${index + 1}`}
                className="h-80 w-80 rounded-lg shadow-md object-cover"
                onError={(e) => {
                  console.error(`Eroare la încărcarea imaginii: ${photo}`);
                  (e.target as HTMLImageElement).src = '/path/to/placeholder-image.jpg';
                }}
              />
              <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <button
                  onClick={() => handleDeletePhoto(index)}
                  className="bg-red-500 text-white p-2 rounded-full hover:bg-red-600 transition-colors duration-300"
                >
                  <FaTrash />
                </button>
              </div>
            </div>
          ))}

          <div
            {...getRootProps()}
            className={`h-80 w-80 flex flex-col justify-center items-center rounded-lg border-2 border-dashed 
              ${isDragActive ? 'border-blue-400 bg-blue-50' : 'border-gray-300'} 
              flex-shrink-0 cursor-pointer transition-colors duration-200 ease-in-out
              hover:border-blue-500 hover:bg-blue-50 snap-start`}
          >
            <input {...getInputProps()} />
            <div className="text-4xl mb-2">
              {renderUploadStatus()}
            </div>
            <p className="text-sm font-medium text-gray-500">
              {isUploading ? 'Se încarcă...' : 'Adaugă imagini'}
            </p>
          </div>
        </div>
      </div>

      <ModelDetailTable 
        character={character}
        isEditing={isEditing}
        onUpdateDetail={handleUpdateDetail}
      />

      {isEditing && (
        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={handleCancel}
            className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Anulează
          </button>
          <button
            onClick={handleSave}
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Salvează
          </button>
        </div>
      )}

      <ConfirmDialog
        open={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        onConfirm={handleDelete}
        title="Confirmare ștergere model"
        description="Sunteți sigur că doriți să ștergeți acest model? Această acțiune nu poate fi anulată."
        confirmText="Șterge"
        cancelText="Anulează"
      />
    </div>
  );
};

export default ModelDetailPage;