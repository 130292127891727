import axios from 'axios';

export const API_URL = process.env.REACT_APP_API_URL || 'https://localhost:5000';

// Interfața pentru șoferi
export interface Driver {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  createdAt?: Date;
  updatedAt?: Date;
}

// Obținerea tuturor șoferilor
export const getDrivers = async (): Promise<Driver[]> => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.get<Driver[]>(`${API_URL}/api/drivers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Eroare la obținerea șoferilor', error);
    throw error;
  }
};

// Adăugarea unui șofer nou
export const addDriver = async (driverData: Partial<Driver>): Promise<Driver> => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.post<Driver>(`${API_URL}/api/drivers`, driverData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Eroare la adăugarea șoferului', error);
    throw error;
  }
};

// Actualizarea unui șofer
export const updateDriver = async (id: string, updateData: Partial<Driver>): Promise<Driver> => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.put<Driver>(`${API_URL}/api/drivers/${id}`, updateData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Eroare la actualizarea șoferului', error);
    throw error;
  }
};

// Ștergerea unui șofer
export const deleteDriver = async (id: string): Promise<void> => {
  try {
    const token = localStorage.getItem('accessToken');
    await axios.delete(`${API_URL}/api/drivers/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error('Eroare la ștergerea șoferului', error);
    throw error;
  }
};

// Obținerea unui șofer după ID
export const getDriverById = async (id: string): Promise<Driver> => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.get<Driver>(`${API_URL}/api/drivers/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Eroare la obținerea șoferului', error.response?.data || error.message);
    } else {
      console.error('Eroare necunoscută', error);
    }
    throw error;
  }
};