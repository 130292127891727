import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import santaClausIcon from '../../assets/images/icons/santa-claus.png';
import mrsClausIcon from '../../assets/images/icons/mrs-claus.png';
import elfIcon from '../../assets/images/icons/elf.png';

const characterIcons: { [key: string]: string } = {
  'Moș Crăciun': santaClausIcon,
  'Crăciuniță': mrsClausIcon,
  'Spiriduș': elfIcon,
};

const orders = [
  { id: '#0034', date: '21 Iul, 23', character: 'Moș Crăciun', totalSpending: '1.395,00 lei', status: 'În așteptare' },
  { id: '#0089', date: '21 Iul, 23', character: 'Crăciuniță', totalSpending: '1.095,00 lei', status: 'În așteptare' },
  { id: '#0256', date: '21 Iul, 23', character: 'Spiriduș', totalSpending: '548,00 lei', status: 'În așteptare' },
  { id: '#9812', date: '21 Iul, 23', character: 'Moș Crăciun', totalSpending: '3.405,00 lei', status: 'În așteptare' },
];

export default function RecentOrders() {
  return (
    <div className="">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">Cele mai recente comenzi</h1>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">ID</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Dată</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Personaj</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Sumă totală</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Acțiuni</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {orders.map((order) => (
                  <tr key={order.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{order.id}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{order.date}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className="flex items-center">
                        <img src={characterIcons[order.character]} alt={order.character} className="h-5 w-5 mr-2" />
                        <span>{order.character}</span>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{order.totalSpending}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                        {order.status}
                      </span>
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}