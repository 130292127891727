import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated, checkAuthStatus } = useAuth();
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      await checkAuthStatus();
      setIsChecking(false);
    };
    checkAuth();
  }, [checkAuthStatus]);

  useEffect(() => {
    console.log('PrivateRoute: isAuthenticated:', isAuthenticated);
  }, [isAuthenticated]);

  if (isChecking) {
    return <div>Loading...</div>; // sau un component de loading
  }

  if (!isAuthenticated) {
    console.log('PrivateRoute: Redirecționare către /login');
    return <Navigate to="/login" />;
  }

  console.log('PrivateRoute: Rendering protected content');
  return children;
};

export default PrivateRoute;