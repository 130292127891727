import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const barData = {
  labels: ['Lun', 'Mar', 'Mie', 'Joi', 'Vin', 'Sâm', 'Dum'],
  datasets: [
    {
      label: 'București',
      data: [50000, 150000, 300000, 50000, 100000, 200000, 200000],
      backgroundColor: 'rgba(99, 102, 241, 0.8)',
    },
    {
      label: 'Ilfov',
      data: [150000, 50000, 450000, 600000, 200000, 150000, 50000],
      backgroundColor: 'rgba(167, 139, 250, 0.8)',
    },
    {
      label: 'Ploiești',
      data: [100000, 200000, 350000, 400000, 150000, 50000, 100000],
      backgroundColor: 'rgba(216, 180, 254, 0.8)',
    },
  ],
};

const lineData = {
  labels: ['Lun', 'Mar', 'Mie', 'Joi', 'Vin', 'Sâm', 'Dum'],
  datasets: [
    {
      label: 'București',
      data: [300000, 350000, 550000, 400000, 450000, 300000, 450000],
      borderColor: 'rgba(59, 130, 246, 1)',
      backgroundColor: 'rgba(59, 130, 246, 0.1)',
      fill: true,
      tension: 0.4,
      borderWidth: 3,
      pointRadius: 4,
      pointBackgroundColor: 'rgba(59, 130, 246, 1)',
      pointBorderColor: 'white',
      pointBorderWidth: 2,
      pointHoverRadius: 6,
    },
  ],
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      mode: 'index' as const,
      intersect: false,
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      titleColor: 'black',
      bodyColor: 'black',
      borderColor: 'rgba(0, 0, 0, 0.1)',
      borderWidth: 1,
      padding: 10,
      bodyFont: {
        weight: 'bold' as const,
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        color: 'rgba(0, 0, 0, 0.05)',
      },
      ticks: {
        callback: function(value: any) {
          return value.toLocaleString('ro-RO') + ' RON';
        },
        stepSize: 250000,
      },
      max: 750000,
    },
  },
};

const LegendaGrafic = ({ items }: { items: { label: string; color: string }[] }) => (
  <div className="flex space-x-4 mb-6">
    {items.map((item, index) => (
      <div key={index} className="flex items-center">
        <div className={`w-3 h-3 rounded-sm mr-2`} style={{ backgroundColor: item.color }}></div>
        <span className="text-sm text-gray-600 font-medium">{item.label}</span>
      </div>
    ))}
  </div>
);

const IndicatorCrestere = ({ value }: { value: number }) => (
  <div className={`flex items-center ${value >= 0 ? 'text-green-500' : 'text-red-500'}`}>
    {value >= 0 ? '↑' : '↓'}
    <span className="text-sm font-semibold ml-1">{Math.abs(value)}% vs. anul trecut</span>
  </div>
);

const Grafice = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      {/* Secțiunea Total Proiectat */}
      <div className="bg-white rounded-lg">
        <div className="p-0">
          <div className="flex justify-between items-start mb-8">
            <div className="flex flex-col gap-y-1">
              <h2 className="text-xl font-medium">Total Proiectat</h2>
              <p className="text-sm text-gray-500">subtitlu pentru aceasta</p>
            </div>
            <div className="flex flex-col items-end gap-y-1">
              <div className="text-2xl font-normal">1.245.000 RON</div>
              <IndicatorCrestere value={-5.6} />
            </div>
          </div>
          <LegendaGrafic items={[
            { label: 'București', color: 'rgba(99, 102, 241, 0.8)' },
            { label: 'Ilfov', color: 'rgba(167, 139, 250, 0.8)' },
            { label: 'Ploiești', color: 'rgba(216, 180, 254, 0.8)' },
          ]} />
        </div>
        <div className="h-64">
          <Bar data={barData} options={options} />
        </div>
      </div>

      {/* Secțiunea Activitate Date */}
      <div className="bg-white rounded-lg">
        <div className="p-0">
          <div className="flex justify-between items-start mb-8">
            <div className="flex flex-col gap-y-2">
              <h2 className="text-xl font-medium">Activitate Date</h2>
              <p className="text-sm text-gray-500">subtitlu pentru aceasta</p>
            </div>
            <div className="flex flex-col items-end gap-y-2">
              <div className="text-2xl font-normal">540.000 RON</div>
              <IndicatorCrestere value={3.3} />
            </div>
          </div>
          <LegendaGrafic items={[
            { label: 'București', color: 'rgba(59, 130, 246, 1)' },
          ]} />
        </div>
        <div className="h-64">
          <Line data={lineData} options={options} />
        </div>
      </div>
    </div>
  );
};

export default Grafice;