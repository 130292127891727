import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Navbar from '../components/PublicNavbar';
import PublicFooter from '../components/PublicFooter';
import WhatsAppFloatingButton from '../components/WhatsAppFloatingButton';

export default function ContactPage() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Contact - Inchiriaza Mos Craciun</title>
        <meta
          name="description"
          content="Contactează-ne pentru a închiria un Moș Crăciun autentic pentru sărbători de neuitat. Aici găsești informațiile necesare pentru rezervare."
        />
        <meta name="keywords" content="contact, inchiriaza mos craciun, sarbatori, mos craciun, inchiriere mos craciun" />
      </Helmet>

      <div className="relative isolate bg-white min-h-screen">
        <Navbar />

        {/* Heading Section */}
        <div className="bg-white py-16 sm:py-24">
          <div className="mx-auto max-w-7xl px-6 lg:px-8 text-center">
            <h2 className="text-base font-semibold leading-7 text-red-600">Contact</h2>
            <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Ia legătura cu noi!</h1>
            <p className="mt-6 text-lg leading-8 text-gray-600 max-w-2xl mx-auto">
              Nu lăsa Magia Crăciunului să treacă neobservată! Contactează-ne acum și adu bucuria și autenticitatea Sărbătorilor, acasa, la gradinita, la scoala sau in afacerea ta.
            </p>
          </div>
        </div>

        {/* Contact Information */}
        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
              <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
                <div>
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900">Contactează-ne</h2>
                  <p className="mt-4 leading-7 text-gray-600">
                    Rezervă-l acum și asigură-te că anul acesta, Moș Crăciun va aduce nu doar cadouri, ci și cea mai frumoasă amintire de Crăciun!
                  </p>
                </div>
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
                  <div className="rounded-2xl bg-gray-50 p-10">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">Madalina Moraru</h3>
                    <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                      <div>
                        <dt className="sr-only">Email</dt>
                        <dd>
                          <a href="mailto:madalina@inchiriazamoscraciun.ro" className="font-semibold text-red-600">
                            madalina@inchiriazamoscraciun.ro
                          </a>
                        </dd>
                      </div>
                      <div className="mt-1">
                        <dt className="sr-only">Telefon</dt>
                        <dd>0748 161 436</dd>
                      </div>
                    </dl>
                  </div>
                  <div className="rounded-2xl bg-gray-50 p-10">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">Florentina Marinescu</h3>
                    <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                      <div>
                        <dt className="sr-only">Email</dt>
                        <dd>
                          <a href="mailto:florentina@inchiriazamoscraciun.ro" className="font-semibold text-red-600">
                            florentina@inchiriazamoscraciun.ro
                          </a>
                        </dd>
                      </div>
                      <div className="mt-1">
                        <dt className="sr-only">Telefon</dt>
                        <dd>0748 161 435</dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* WhatsApp Floating Button */}
        <WhatsAppFloatingButton />

        <PublicFooter />
      </div>
    </HelmetProvider>
  );
}