import React, { useState, useRef } from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';

interface ImageUploadProps {
  onImagesSelected: (files: File[]) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ onImagesSelected }) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setUploading(true);
      setProgress(0);
      setUploadComplete(false);

      // Mutăm apelul onImagesSelected într-un context asincron pentru a evita conflictele de redare
      const processFiles = async () => {
        onImagesSelected(Array.from(files)); // Apelăm onImagesSelected aici, după ce fișierele au fost procesate
        
        // Simulăm progresul de încărcare
        for (let i = 0; i <= 100; i += 10) {
          await new Promise((resolve) => setTimeout(resolve, 500)); // Așteptăm puțin între pași
          setProgress(i);
        }
        
        setUploading(false);
        setUploadComplete(true);
      };

      processFiles();
    }
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className="mt-4">
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        multiple
        accept="image/*"
        className="hidden"
      />
      <button
        type="button"
        onClick={handleClick}
        className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 48 48"
          aria-hidden="true"
        >
          <path
            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span className="mt-2 block text-sm font-semibold text-gray-900">
          Încarcă imagini
        </span>
      </button>
      {uploading && (
        <div className="mt-2">
          <div className="relative pt-1">
            <div className="flex mb-2 items-center justify-between">
              <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-indigo-600 bg-indigo-200">
                  Se încarcă
                </span>
              </div>
              <div className="text-right">
                <span className="text-xs font-semibold inline-block text-indigo-600">
                  {progress}%
                </span>
              </div>
            </div>
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-indigo-200">
              <div
                style={{ width: `${progress}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-indigo-500"
              ></div>
            </div>
          </div>
        </div>
      )}
      {uploadComplete && (
        <div className="mt-2 flex items-center text-green-500">
          <CheckIcon className="h-5 w-5 mr-2" />
          <span>Încărcare completă</span>
        </div>
      )}
    </div>
  );
};

export default ImageUpload;