import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import Navbar from '../components/PublicNavbar';
import PublicFooter from '../components/PublicFooter';

export default function TermsAndConditions() {
  return (
    <div className="min-h-screen bg-white flex flex-col">
      <Navbar />
      <main className="flex-grow">
        <div className="bg-white py-16 sm:py-24">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <p className="text-base font-semibold leading-7 text-red-600">Termeni și Condiții</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Termeni și Condiții inchiriazamoscraciun.ro</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
                Vă rugăm să citiți cu atenție acești termeni și condiții înainte de a utiliza site-ul nostru și serviciile oferite de inchiriazamoscraciun.ro.
              </p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl lg:mx-0 lg:max-w-none">
              <div className="grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-700 lg:max-w-none lg:grid-cols-2">
                <div>
                  <h2 className="text-2xl font-bold tracking-tight text-gray-900">1. Acceptarea Termenilor</h2>
                  <p className="mt-6">
                    Prin accesarea și utilizarea site-ului inchiriazamoscraciun.ro, sunteți de acord să respectați și să fiți obligat de acești termeni și condiții. Dacă nu sunteți de acord cu oricare dintre acești termeni, vă rugăm să nu utilizați site-ul nostru.
                  </p>
                  
                  <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">2. Descrierea Serviciilor</h2>
                  <p className="mt-6">
                    inchiriazamoscraciun.ro oferă servicii de închiriere de costume de Moș Crăciun și organizare de evenimente tematice. Detaliile specifice ale serviciilor sunt descrise pe site-ul nostru.
                  </p>
                  
                  <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">3. Utilizarea Site-ului</h2>
                  <ul role="list" className="mt-8 space-y-8 text-gray-600">
                    <li className="flex gap-x-3">
                      <InformationCircleIcon className="mt-1 h-5 w-5 flex-none text-red-600" aria-hidden="true" />
                      <span>
                        Vă angajați să utilizați site-ul doar în scopuri legale și în conformitate cu acești termeni.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <InformationCircleIcon className="mt-1 h-5 w-5 flex-none text-red-600" aria-hidden="true" />
                      <span>
                        Este interzisă utilizarea site-ului în orice mod care ar putea deteriora, dezactiva sau supraîncărca sistemele noastre.
                      </span>
                    </li>
                  </ul>
                </div>
                <div>
                  <h2 className="text-2xl font-bold tracking-tight text-gray-900">4. Rezervări și Plăți</h2>
                  <p className="mt-6">
                    Detaliile complete despre procesul de rezervare și plată sunt furnizate în momentul efectuării unei rezervări. Toate plățile sunt procesate în conformitate cu politicile noastre de securitate.
                  </p>
                  
                  <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">5. Anulări și Rambursări</h2>
                  <p className="mt-6">
                    Politica noastră de anulare și rambursare este detaliată în secțiunea specifică de pe site. Vă rugăm să consultați această secțiune înainte de a face o rezervare.
                  </p>
                  
                  <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">6. Limitarea Răspunderii</h2>
                  <p className="mt-6">
                    inchiriazamoscraciun.ro nu va fi răspunzător pentru niciun fel de daune directe, indirecte, incidentale sau consecvente rezultate din utilizarea sau imposibilitatea de a utiliza serviciile noastre.
                  </p>
                  
                  <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">7. Drepturi de Proprietate Intelectuală</h2>
                  <p className="mt-6">
                    Imaginile personajelor prezentate pe site sunt create de către agenția noastră și sunt protejate de drepturile de autor. Utilizarea neautorizată a acestor imagini este strict interzisă.
                  </p>
                  <p className="mt-4">
                    Pentru restul conținutului site-ului, inclusiv texte, grafice și alte imagini, vă rugăm să respectați drepturile de autor ale creatorilor originali. Nu revendicăm drepturi de proprietate asupra acestor elemente, cu excepția cazurilor în care este specificat altfel.
                  </p>
                </div>
              </div>
              <div className="mt-16 max-w-2xl">
                <h2 className="text-2xl font-bold tracking-tight text-gray-900">8. Utilizarea Conținutului Site-ului</h2>
                <p className="mt-6">
                  Sunteți de acord să nu reproduceți, distribuiți, modificați sau să creați lucrări derivate bazate pe conținutul site-ului nostru, cu excepția cazului în care aveți permisiunea explicită pentru a face acest lucru. Acest lucru se aplică în special imaginilor personajelor noastre.
                </p>
                <p className="mt-4">
                  Pentru orice utilizare a conținutului site-ului în scopuri comerciale sau non-personale, vă rugăm să obțineți în prealabil acordul nostru scris.
                </p>

                <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">9. Modificări ale Termenilor și Condițiilor</h2>
                <p className="mt-6">
                  Ne rezervăm dreptul de a modifica acești termeni și condiții în orice moment. Orice modificări vor fi postate pe această pagină și vor intra în vigoare imediat. Este responsabilitatea dumneavoastră să verificați periodic acești termeni pentru a fi la curent cu eventualele actualizări.
                </p>
                <p className="mt-6">
                  Pentru orice întrebări sau clarificări legate de acești termeni și condiții, vă rugăm să ne contactați la adresa de email: contact@inchiriazamoscraciun.ro.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <PublicFooter />
    </div>
  );
}