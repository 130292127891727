import React, { useEffect, useState } from 'react';
import { ChevronRightIcon, ChevronUpDownIcon, CheckIcon } from '@heroicons/react/20/solid';
import { UserPlusIcon } from '@heroicons/react/24/outline';
import { getCustomers, addCustomer, Customer } from '../../services/customerService';
import { useNavigate } from 'react-router-dom';
import { Listbox } from '@headlessui/react';
import ReusableDialog from '../../shared/ReusableDialog';
import { useNotification, NotificationProvider } from '../components/Notification';

const customerTypes = [
  { id: 'persoana fizica', name: 'Persoană fizică' },
  { id: 'persoana juridica', name: 'Persoană juridică' },
  { id: 'gradinita/scoala', name: 'Grădiniță/Școală' },
  { id: 'loc de joaca', name: 'Loc de joacă' },
];

const CustomerPageContent: React.FC = () => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedCustomerType, setSelectedCustomerType] = useState(customerTypes[0]);
  const [newCustomer, setNewCustomer] = useState<Partial<Customer>>({
    name: '',
    phone: '',
    email: '',
    address: '',
    zona: '',
    mentiuni: ''
  });
  const navigate = useNavigate();
  const { addNotification } = useNotification();

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      const data = await getCustomers();
      setCustomers(data);
      setLoading(false);
    } catch (err) {
      setError('Eroare la obținerea clienților');
      setLoading(false);
      addNotification('error', 'Eroare la obținerea clienților', 'Nu s-au putut încărca datele clienților.');
    }
  };

  const formatWhatsAppLink = (phone: string) => {
    const cleanPhone = phone.replace(/[^\d+]/g, '');
    return cleanPhone.startsWith('+') ? `https://wa.me/${cleanPhone.slice(1)}` : `https://wa.me/40${cleanPhone}`;
  };

  const isValidPhoneNumber = (phone: string) => {
    return phone.replace(/\D/g, '').length >= 10;
  };

  const handleRowClick = (customer: Customer) => {
    if (customer._id) {
      const slugName = customer.name
        ? customer.name.toLowerCase().replace(/\s+/g, '-')
        : 'client-nespecificat';
      navigate(`/dashboard/clienti/${slugName}-${customer._id}`);
    } else {
      console.error('Customer ID is undefined:', customer);
      addNotification('error', 'Eroare', 'Nu se poate accesa detaliile clientului.');
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setNewCustomer(prev => ({ ...prev, [name]: value }));
  };

  const handleAddCustomer = async () => {
    try {
      await addCustomer({ ...newCustomer, customerType: selectedCustomerType.id } as Customer);
      setIsDialogOpen(false);
      fetchCustomers();
      handleResetForm();
      addNotification('success', 'Client adăugat cu succes', 'Noul client a fost adăugat în baza de date.');
    } catch (error) {
      console.error('Eroare la adăugarea clientului:', error);
      addNotification('error', 'Eroare la adăugarea clientului', 'Nu s-a putut adăuga noul client. Vă rugăm să încercați din nou.');
    }
  };

  const handleResetForm = () => {
    setNewCustomer({
      name: '',
      phone: '',
      email: '',
      address: '',
      zona: '',
      mentiuni: ''
    });
    setSelectedCustomerType(customerTypes[0]);
  };

  if (loading) return <div>Se încarcă...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="p-0">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">Lista clienților</h1>
          <p className="mt-2 text-sm text-gray-700">
            O listă completă cu toți clienții noștri, inclusiv detalii despre tipul de client, informații de contact și adresă.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            onClick={() => setIsDialogOpen(true)}
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Adaugă client
          </button>
        </div>
      </div>

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Nume</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Tip client</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Telefon</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Adresa</th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Acțiuni</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {customers.map((customer, index) => (
                  <tr key={`${customer._id}-${index}`} onClick={() => handleRowClick(customer)} className="hover:bg-gray-50 cursor-pointer">
                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{customer.name}</td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{customer.customerType}</td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <div className="flex items-center">
                        <span>{customer.phone}</span>
                        {isValidPhoneNumber(customer.phone) && (
                          <a
                            href={formatWhatsAppLink(customer.phone)}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => e.stopPropagation()}
                            className="ml-2 text-green-600 hover:text-green-800"
                            title={`Deschide WhatsApp pentru ${customer.phone}`}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="h-5 w-5 fill-current">
                              <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/>
                            </svg>
                          </a>
                        )}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{customer.email}</td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{customer.address}</td>
                    <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ReusableDialog
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          handleResetForm();
        }}
        icon={<UserPlusIcon className="h-6 w-6 text-green-600" aria-hidden="true" />}
        title="Adaugă un nou client"
        primaryButton={{
          onClick: handleAddCustomer,
          className: "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2",
          children: "Adaugă"
        }}
        secondaryButton={{
          onClick: () => setIsDialogOpen(false),
          className: "mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0",
          children: "Anulează"
        }}
      >
        <p className="mt-2 mb-6 text-sm text-gray-500 text-pretty">
          Completați informațiile despre noul client pentru a-l adăuga în baza de date.
        </p>
        <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <Listbox value={selectedCustomerType} onChange={setSelectedCustomerType}>
              <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900 text-left">Tip client</Listbox.Label>
              <div className="relative mt-2">
                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  <span className="block truncate">{selectedCustomerType.name}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>
                <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {customerTypes.map((type) => (
                    <Listbox.Option
                      key={type.id}
                      value={type}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-3 pr-9 ${
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                        }`
                      }
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>
                            {type.name}
                          </span>
                          {selected ? (
                            <span className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                              active ? 'text-white' : 'text-indigo-600'
                            }`}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </div>
            </Listbox>
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900 text-left">
              Nume
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="name"
                id="name"
                value={newCustomer.name}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900 text-left">
              Telefon
            </label>
            <div className="mt-2">
              <input
                type="tel"
                name="phone"
                id="phone"
                value={newCustomer.phone}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 text-left">
              Email
            </label>
            <div className="mt-2">
              <input
                type="email"
                name="email"
                id="email"
                value={newCustomer.email}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-6 grid grid-cols-2 gap-6">
            <div>
              <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900 text-left">
                Adresă
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="address"
                  id="address"
                  value={newCustomer.address}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <label htmlFor="zona" className="block text-sm font-medium leading-6 text-gray-900 text-left">
                Zona
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="zona"
                  id="zona"
                  value={newCustomer.zona}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div className="sm:col-span-6">
            <label htmlFor="mentiuni" className="block text-sm font-medium leading-6 text-gray-900 text-left">
              Mențiuni
            </label>
            <div className="mt-2">
              <textarea
                name="mentiuni"
                id="mentiuni"
                rows={3}
                value={newCustomer.mentiuni}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>
      </ReusableDialog>
    </div>
  );
};

const CustomerPage: React.FC = () => {
  return (
    <NotificationProvider>
      <CustomerPageContent />
    </NotificationProvider>
  );
};

export default CustomerPage;
