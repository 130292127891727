import React from 'react';
import { Order } from '../../services/orderService';

interface PrintableOrderTableProps {
  orders: Order[];
  hideTotalAmount: boolean;
  generatedBy: string;
}

const PrintableOrderTable: React.FC<PrintableOrderTableProps> = ({ orders, hideTotalAmount, generatedBy }) => {
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ro-RO', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const formatTime = (timeString: string) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };

  const getCurrentDateTime = () => {
    return new Date().toLocaleString('ro-RO', {
      dateStyle: 'full',
      timeStyle: 'long',
      timeZone: 'Europe/Bucharest'
    });
  };

  return (
    <div className="hidden print:block w-full p-4">
      <div className="flex items-center justify-between mb-8">
        <img 
          src="https://inchiriazamoscraciun.ro/logoinchiriazamoscraciun.png" 
          alt="Logo Moș Crăciun de Închiriat" 
          className="h-16"
        />
        <div className="text-right">
          <div className="text-sm text-gray-500 mt-2">
            <p>Document generat: {getCurrentDateTime()}</p>
            <p>Generat de: {generatedBy}</p>
          </div>
        </div>
      </div>

      <div className="space-y-4">
        {orders.map((order) => (
          <div key={order._id} className="border border-gray-300 rounded">
            <table className="w-full text-xs border-collapse">
              <thead>
                <tr className="bg-red-50">
                  <th className="p-2 text-left border border-gray-400 w-1/5">Client</th>
                  <th className="p-2 text-left border border-gray-400 w-1/6">Data/Ora</th>
                  <th className="p-2 text-left border border-gray-400 w-1/6">Model/Șofer</th>
                  <th className="p-2 text-left border border-gray-400 w-1/5">Detalii Financiare</th>
                  <th className="p-2 text-left border border-gray-400 w-1/5">Locație</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-2 border border-gray-300 align-top">
                    <div className="font-semibold">{order.client?.name || 'N/A'}</div>
                    <div>Tel: {order.client?.phone || 'N/A'}</div>
                    {order.client?.email && <div>Email: {order.client.email}</div>}
                    <div className="text-gray-600">Tip: {order.client?.customerType || 'N/A'}</div>
                  </td>
                  <td className="p-2 border border-gray-300 align-top">
                    <div>{formatDate(order.period.startDate)}</div>
                    <div className="text-gray-600">
                      {formatTime(order.period.startTime)} - {formatTime(order.period.endTime)}
                    </div>
                  </td>
                  <td className="p-2 border border-gray-300 align-top">
                    <div className="font-semibold">
                      {order.character.firstName} {order.character.lastName}
                    </div>
                    <div>Tip: {order.character.characterType}</div>
                    {order.driver && (
                      <div className="text-gray-600">
                        Șofer: {order.driver.firstName} {order.driver.lastName}
                        {order.driver.phone && ` (${order.driver.phone})`}
                      </div>
                    )}
                    {order.extraCharacters.length > 0 && (
                      <div className="mt-1">
                        <div>Modele Extra:</div>
                        {order.extraCharacters.map((extra, index) => (
                          <div key={index} className="text-gray-600">
                            - {extra.character.firstName} {extra.character.lastName}
                          </div>
                        ))}
                      </div>
                    )}
                  </td>
                  <td className="p-2 border border-gray-300 align-top">
                    {!hideTotalAmount && (
                      <div className="font-semibold">Total: {order.billingInfo.totalAmount} RON</div>
                    )}
                    <div>Model: {order.billingInfo.modelFee} RON</div>
                    {order.billingInfo.driverFee > 0 && (
                      <div>Șofer: {order.billingInfo.driverFee} RON</div>
                    )}
                    {order.extraCharacters.map((extra, index) => (
                      <div key={index}>
                        Extra {index + 1}: {extra.modelFee} RON
                      </div>
                    ))}
                  </td>
                  <td className="p-2 border border-gray-300 align-top">
                    <div>{order.address || 'N/A'}</div>
                    {order.addressDetails && (
                      <div>Detalii: {order.addressDetails}</div>
                    )}
                    {order.zone && <div>Zona: {order.zone}</div>}
                  </td>
                </tr>
              </tbody>
            </table>

            {/* Panou doar pentru note */}
            {order.notes && (
              <div className="bg-yellow-50 px-4 py-2 border-t border-gray-200">
                <div className="flex items-center gap-2">
                  <span className="font-medium">Mențiuni:</span>
                  <span className="text-gray-700">{order.notes}</span>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="mt-8 text-center text-xs text-gray-500">
        <p>www.inchiriazamoscraciun.ro | contact@inchiriazamoscraciun.ro | +40 749 318 211</p>
        <p>Website-ul inchiriazamoscraciun.ro - Pagina {window.location.pathname}</p>
      </div>
    </div>
  );
};

export default PrintableOrderTable;