import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, MagnifyingGlassIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import santaClausIcon from '../../assets/images/icons/santa-claus.png';
import mrsClausIcon from '../../assets/images/icons/mrs-claus.png';
import elfIcon from '../../assets/images/icons/elf.png';
import { getCharacters, addCharacter, Character, API_URL } from '../../services/characterService';
import AddCharacterDialog from '../components/AddCharacterDialog';
import { useNotification, NotificationProvider } from '../components/Notification';

const characterIcons: { [key: string]: string } = {
  'Moș Crăciun': santaClausIcon,
  'Moș Nicolae': santaClausIcon,
  'Crăciuniță': mrsClausIcon,
  'Spiriduș': elfIcon,
};

const characterTypes = [
  { id: '', name: 'Toate tipurile' },
  { id: 'Moș Crăciun', name: 'Moș Crăciun' },
  { id: 'Moș Nicolae', name: 'Moș Nicolae' },
  { id: 'Crăciuniță', name: 'Crăciuniță' },
  { id: 'Spiriduș', name: 'Spiriduș' },
];

const ModelPageContent: React.FC = () => {
  const [models, setModels] = useState<Character[]>([]);
  const [filteredModels, setFilteredModels] = useState<Character[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;
  const { addNotification } = useNotification();
  const navigate = useNavigate();
  const [filterCriteria, setFilterCriteria] = useState({
    characterType: '',
    searchTerm: '',
  });

  useEffect(() => {
    fetchCharacters();
  }, []);

  useEffect(() => {
    const filtered = applyFilters();
    setFilteredModels(filtered);
  }, [models, filterCriteria]);

  const fetchCharacters = async () => {
    try {
      const data = await getCharacters();
      setModels(data);
      setFilteredModels(data);
    } catch (error) {
      addNotification('error', 'Eroare la obținerea personajelor', 'Nu s-au putut încărca modelele.');
    }
  };

  const applyFilters = () => {
    return models.filter(model => {
      const matchesCharacterType = !filterCriteria.characterType || model.characterType === filterCriteria.characterType;
      const matchesSearchTerm = model.firstName.toLowerCase().includes(filterCriteria.searchTerm.toLowerCase()) ||
                                model.lastName.toLowerCase().includes(filterCriteria.searchTerm.toLowerCase());
      return matchesCharacterType && matchesSearchTerm;
    });
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilterCriteria(prev => ({ ...prev, [name]: value }));
  };

  const handleDialogOpen = () => setIsDialogOpen(true);
  const handleDialogClose = () => setIsDialogOpen(false);

  const handleAddCharacter = async (characterData: FormData) => {
    try {
      const newCharacter = await addCharacter(characterData);
      setModels((prevModels) => [...prevModels, newCharacter]);
      addNotification('success', 'Caracter adăugat cu succes!');
      setIsDialogOpen(false);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'A apărut o eroare necunoscută.';
      addNotification('error', 'Eroare la adăugarea caracterului', errorMessage);
    }
  };

  const calculateAge = (birthDate: Date | string): number => {
    const birthDateObj = new Date(birthDate);
    const today = new Date();
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
      age--;
    }
    return age;
  };

  const getCharacterImage = (model: Character) => {
    if (model.photos && model.photos.length > 0) {
      const photoUrl = model.photos[0].startsWith('http') 
        ? model.photos[0] 
        : `${API_URL}${model.photos[0].startsWith('/') ? '' : '/'}${model.photos[0]}`;
      return <img className="h-12 w-12 rounded-full object-cover" src={photoUrl} alt={`${model.firstName} ${model.lastName}`} />;
    } else {
      const initials = `${model.firstName.charAt(0)}${model.lastName.charAt(0)}`;
      const characterColors: { [key: string]: string } = {
        'Moș Crăciun': '#ff0000',
        'Crăciuniță': '#ff6666',
        'Spiriduș': '#00cc00',
      };
      const color = characterColors[model.characterType] || '#3498db';
      const svgAvatar = `
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
          <rect width="100" height="100" fill="${color}" />
          <text x="50" y="50" font-family="Arial, sans-serif" font-size="40" fill="white" text-anchor="middle" dominant-baseline="central">
            ${initials.toUpperCase()}
          </text>
        </svg>
      `;
      const svgUrl = `data:image/svg+xml;base64,${btoa(svgAvatar)}`;
      return <img className="h-12 w-12 rounded-full" src={svgUrl} alt={`${model.firstName} ${model.lastName}`} />;
    }
  };

  const handleRowClick = (model: Character) => {
    if (model._id) {
      navigate(`/dashboard/modele/${model._id}`);
    } else {
      console.error('Model ID is undefined:', model);
      addNotification('error', 'Eroare', 'Nu se poate accesa detaliile modelului.');
    }
  };

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div className="">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">Modele</h1>
          <p className="mt-2 text-sm text-gray-700">
            O listă cu toate modelele disponibile, inclusiv numele, tipul de caracter, statusul și alte detalii.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            onClick={handleDialogOpen}
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Adaugă model
          </button>
        </div>
      </div>

      {/* Filtre */}
      <div className="mt-6 mb-6 flex flex-col md:flex-row md:items-center md:space-x-4 space-y-4 md:space-y-0">
        <div className="w-full md:w-1/4">
          <label htmlFor="searchTerm" className="block text-sm font-medium leading-6 text-gray-900 mb-1">
            Căutare
          </label>
          <div className="relative rounded-md">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="text"
              name="searchTerm"
              id="searchTerm"
              value={filterCriteria.searchTerm}
              onChange={handleFilterChange}
              className="block w-full rounded-md border-0 py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Caută modele..."
            />
          </div>
        </div>
        <div className="w-full md:w-1/4">
          <Listbox
            value={characterTypes.find(type => type.id === filterCriteria.characterType) || characterTypes[0]}
            onChange={(selectedType) => handleFilterChange({ target: { name: 'characterType', value: selectedType.id } } as React.ChangeEvent<HTMLSelectElement>)}
          >
            <div className="relative">
              <Listbox.Label className="block text-sm font-medium mb-1 leading-6 text-gray-900">Tip Caracter</Listbox.Label>
              <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <span className="block truncate">
                  {characterTypes.find(type => type.id === filterCriteria.characterType)?.name || 'Toate tipurile'}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </ListboxButton>
              <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {characterTypes.map((type) => (
                  <ListboxOption
                    key={type.id}
                    value={type}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-3 pr-9 ${
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                      }`
                    }
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>
                          {type.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                              active ? 'text-white' : 'text-indigo-600'
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </div>
          </Listbox>
        </div>
      </div>

      <div className="mt-8 flow-root">
        <div className="-mx-4 px-4 sm:px-6 lg:px-8 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Nume</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Telefon</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Tip Caracter</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Vârstă</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Limbi</th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Acțiuni</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {filteredModels.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((model, index) => (
                  <tr
                    key={`${model._id || 'no-id'}-${index}`}
                    onClick={() => handleRowClick(model)}
                    className="hover:bg-gray-50 cursor-pointer"
                  >
                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                      <div className="flex items-center">
                        <div className="h-12 w-12 flex-shrink-0">
                          {getCharacterImage(model)}
                        </div>
                        <div className="ml-4">
                          <div className="font-medium text-gray-900">{model.firstName} {model.lastName}</div>
                          <div className="mt-1 text-gray-500">{model.email}</div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{model.phone1}</td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <div className="flex items-center">
                        <img src={characterIcons[model.characterType] || elfIcon} alt={model.characterType} className="h-5 w-5 mr-2" />
                        <span>{model.characterType}</span>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <span
                        className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${
                          model.profileStatus === 'active'
                            ? 'bg-green-50 text-green-700 ring-1 ring-inset ring-green-600/20'
                            : 'bg-red-50 text-red-700 ring-1 ring-inset ring-red-600/20'
                        }`}
                      >
                        {model.profileStatus === 'active' ? 'Activ' : 'Inactiv'}
                      </span>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{calculateAge(model.birthDate)} ani</td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{model.languages.join(', ')}</td>
                    <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <ChevronRightIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-700" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="mt-4 flex items-center justify-between">
        <div className="flex items-center">
          <span className="text-sm text-gray-700">
            Afișare {(currentPage - 1) * itemsPerPage + 1} - {Math.min(currentPage * itemsPerPage, filteredModels.length)} din {filteredModels.length} rezultate
          </span>
        </div>
        <div>
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              Anterior
              </button>
            {Array.from({ length: Math.ceil(filteredModels.length / itemsPerPage) }).map((_, index) => (
              <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${
                  currentPage === index + 1 ? 'text-indigo-600 bg-indigo-50' : 'text-gray-700 hover:bg-gray-50'
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === Math.ceil(filteredModels.length / itemsPerPage)}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              Următorul
            </button>
          </nav>
        </div>
      </div>
      <AddCharacterDialog
        isOpen={isDialogOpen}
        onClose={handleDialogClose}
        onAddCharacter={handleAddCharacter}
      />
    </div>
  );
};

const ModelPage: React.FC = () => {
  return (
    <NotificationProvider>
      <ModelPageContent />
    </NotificationProvider>
  );
};

export default ModelPage;