import React, { useState, useEffect, useMemo } from 'react';
import { 
  CalendarIcon,
  MapPinIcon,
  CurrencyDollarIcon,
  BanknotesIcon,
  InformationCircleIcon
} from '@heroicons/react/24/outline';
import { 
  getCharacterStats, 
  getCharacters,
  getCharacterDetailsAndOrders,
  Character,
  CharacterStats 
} from '../../services/characterService';
import { formatInTimeZone } from 'date-fns-tz';
import { ro } from 'date-fns/locale';
import CharacterStatsDisplay from '../components/CharacterStatsDisplay';
import ModernPagination from '../components/ModernPagination';
import EarningsHeader from '../components/EarningsHeader';
import { InfoIcon } from 'lucide-react';

interface EarningsPageProps {
  initialCharacterId?: string;
}

// Interfețe pentru DetailedCharacterStats
interface DetailedOrder {
  orderId: string;
  period: {
    startDate: Date;
    endDate: Date;
    startTime: string;
    endTime: string;
  };
  client: {
    name?: string;
    phone?: string;
    email?: string;
    type?: string;
  };
  location: {
    address: string;
    addressDetails?: string;
    zone?: string;
  };
  financials: {
    orderTotal: number;
    characterEarnings: number;
    driverEarnings?: number;
  };
  role: 'Main' | 'Extra';
  status: string;
}

interface DetailedCharacterStats {
  totalOrders: number;
  totalEarnings: number;
  earningsAsMain: number;
  earningsAsExtra: number;
  detailedOrders: DetailedOrder[];
}

const formatRomanianNumber = (value: number) => {
  const withDecimals = value.toFixed(2);
  const [integerPart, decimalPart] = withDecimals.split('.');
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return `${formattedInteger},${decimalPart}`;
};

const EarningsPage: React.FC<EarningsPageProps> = ({ initialCharacterId }) => {
  const [characters, setCharacters] = useState<Character[]>([]);
  const [selectedCharacter, setSelectedCharacter] = useState<Character | null>(null);
  const [detailedStats, setDetailedStats] = useState<DetailedCharacterStats | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterDates, setFilterDates] = useState({ startDate: '', endDate: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 10;

  // Adaptăm DetailedCharacterStats la CharacterStats pentru EarningsHeader
  const adaptedStats = useMemo<CharacterStats | null>(() => {
    if (!detailedStats) return null;
    
    return {
      totalOrders: detailedStats.totalOrders,
      totalEarnings: detailedStats.totalEarnings,
      earningsAsMain: detailedStats.earningsAsMain,
      earningsAsExtra: detailedStats.earningsAsExtra,
      orders: detailedStats.detailedOrders.map(order => ({
        orderId: order.orderId,
        date: order.period.startDate.toISOString(),
        totalAmount: order.financials.orderTotal,
        characterEarnings: order.financials.characterEarnings,
        role: order.role,
        address: order.location.address,
        addressDetails: order.location.addressDetails,
        zone: order.location.zone,
        period: {
          startDate: order.period.startDate.toISOString(),
          endDate: order.period.endDate.toISOString(),
          startTime: order.period.startTime,
          endTime: order.period.endTime
        },
        client: {
          name: order.client.name || '',
          phone: order.client.phone || ''
        },
        billingInfo: {
          totalAmount: order.financials.orderTotal,
          modelFee: order.financials.characterEarnings
        }
      }))
    };
  }, [detailedStats]);

  // Calculăm statisticile pentru CharacterStatsDisplay
  const calculatedStats = useMemo(() => {
    if (!detailedStats) return null;
    
    return {
      totalOrders: detailedStats.totalOrders,
      totalAmount: detailedStats.detailedOrders.reduce((sum: number, order: DetailedOrder) => 
        sum + order.financials.orderTotal, 0),
      characterEarnings: detailedStats.totalEarnings
    };
  }, [detailedStats]);

  // Calculăm ordinele pentru pagina curentă
  const currentOrders = useMemo(() => {
    if (!detailedStats) return [];
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    return detailedStats.detailedOrders
      .map((order: DetailedOrder) => ({
        orderId: order.orderId,
        date: order.period.startDate,
        address: order.location.address,
        totalAmount: order.financials.orderTotal,
        characterEarnings: order.financials.characterEarnings
      }))
      .slice(indexOfFirstOrder, indexOfLastOrder);
  }, [detailedStats, currentPage, ordersPerPage]);

  const totalPages = useMemo(() => {
    return Math.ceil((detailedStats?.detailedOrders.length || 0) / ordersPerPage);
  }, [detailedStats, ordersPerPage]);

  useEffect(() => {
    const fetchCharacters = async () => {
      try {
        setLoading(true);
        const fetchedCharacters = await getCharacters();
        const sortedCharacters = fetchedCharacters.sort((a, b) =>
          `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`, 'ro')
        );
        setCharacters(sortedCharacters);

        if (initialCharacterId) {
          const initialCharacter = sortedCharacters.find((char) => char._id === initialCharacterId);
          if (initialCharacter) setSelectedCharacter(initialCharacter);
        } else if (sortedCharacters.length > 0) {
          setSelectedCharacter(sortedCharacters[0]);
        }
      } catch (err) {
        setError('Eroare la încărcarea personajelor');
      } finally {
        setLoading(false);
      }
    };

    fetchCharacters();
  }, [initialCharacterId]);

  useEffect(() => {
    const fetchStats = async () => {
      if (!selectedCharacter) return;
      try {
        setLoading(true);
        const response = await getCharacterDetailsAndOrders(selectedCharacter._id);
        setDetailedStats(response);
        setCurrentPage(1);
      } catch (err) {
        setError('Eroare la încărcarea statisticilor');
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [selectedCharacter, filterDates]);

  if (loading) return (
    <div className="flex items-center justify-center p-8">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
    </div>
  );
  
  if (error) return <div className="p-4 text-red-600 bg-red-50 rounded-md">{error}</div>;

  return (
    <div className="p-0">

      <div className="bg-orange-50 border-l-4 border-orange-500 text-orange-700 p-4 mb-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon className="h-6 w-6 text-orange-500" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <p className="text-sm">
              <strong>BUG:</strong> Tabelul are un bug care face ca exportul să nu funcționeze. Se va rezolva în noaptea dintre 11 și 12!
            </p>
          </div>
        </div>
      </div>

      <EarningsHeader
        searchTerm={searchTerm}
        onSearchChange={setSearchTerm}
        selectedCharacter={selectedCharacter}
        onCharacterChange={setSelectedCharacter}
        characters={characters}
        filterDates={filterDates}
        onDateChange={(type, date) => setFilterDates(prev => ({ ...prev, [type]: date }))}
        stats={adaptedStats}
        setError={setError}
      />

      {detailedStats && calculatedStats && (
        <CharacterStatsDisplay stats={calculatedStats} />
      )}

      {detailedStats && detailedStats.detailedOrders.length > 0 ? (
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Data
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Adresă
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                      Total Comandă
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                      Câștig Model
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {currentOrders.map((order) => (
                    <tr key={order.orderId} className="hover:bg-gray-50">
                      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm text-gray-900 sm:pl-0">
                        <div className="flex items-center">
                          <CalendarIcon className="h-5 w-5 text-gray-400 mr-2" />
                          <span>
                            {formatInTimeZone(order.date, 'Europe/Bucharest', 'dd MMMM yyyy', {
                              locale: ro,
                            })}
                          </span>
                        </div>
                      </td>
                      <td className="px-3 py-5 text-sm text-gray-500">
                        <div className="flex items-center">
                          <MapPinIcon className="h-5 w-5 text-gray-400 mr-2" />
                          <span>{order.address || 'Adresă nespecificată'}</span>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-right font-medium text-gray-900">
                        <div className="flex items-center justify-end">
                          <BanknotesIcon className="h-5 w-5 text-gray-400 mr-2" />
                          <span>{formatRomanianNumber(order.totalAmount)} RON</span>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-right font-medium">
                        <div className="flex items-center justify-end">
                          <CurrencyDollarIcon className="h-5 w-5 text-green-500 mr-2" />
                          <span className="text-green-600">{formatRomanianNumber(order.characterEarnings)} RON</span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="mt-4 flex justify-end">
            <ModernPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
            />
          </div>
        </div>
      ) : (
        <div className="mt-8 text-center">
          <div className="rounded-lg border-2 border-dashed border-gray-300 p-12">
            <CurrencyDollarIcon className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">Nu există comenzi</h3>
            <p className="mt-1 text-sm text-gray-500">
              Nu au fost găsite comenzi pentru perioada selectată.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default EarningsPage;