import axios from 'axios';

export const API_URL = process.env.REACT_APP_API_URL || 'https://localhost:5000';

export interface Expense {
  _id?: string;
  amount: number;
  provider?: string;
  details?: string;
  date?: Date;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ProfitStats {
  totalRevenue: number;
  totalDriverFees: number;
  totalModelFees: number;
  totalExpenses: number;
  profit: number;
}

export const addExpense = async (expenseData: Expense): Promise<Expense> => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.post<Expense>(`${API_URL}/api/expenses`, expenseData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Eroare la adăugarea cheltuielii', error);
    throw error;
  }
};

export const getExpenses = async (): Promise<Expense[]> => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.get<Expense[]>(`${API_URL}/api/expenses`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Eroare la obținerea cheltuielilor', error);
    throw error;
  }
};

export const getExpenseById = async (id: string): Promise<Expense> => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.get<Expense>(`${API_URL}/api/expenses/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Eroare la obținerea cheltuielii', error);
    throw error;
  }
};

export const updateExpense = async (id: string, expenseData: Partial<Expense>): Promise<Expense> => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.put<Expense>(`${API_URL}/api/expenses/${id}`, expenseData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Eroare la actualizarea cheltuielii', error);
    throw error;
  }
};

export const deleteExpense = async (id: string): Promise<void> => {
  try {
    const token = localStorage.getItem('accessToken');
    await axios.delete(`${API_URL}/api/expenses/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error('Eroare la ștergerea cheltuielii', error);
    throw error;
  }
};

export const calculateProfit = async (): Promise<ProfitStats> => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.get<ProfitStats>(`${API_URL}/api/expenses/profit`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Eroare la calcularea profitului', error);
    throw error;
  }
};