import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { getOrderById, deleteOrder, updateOrder, Order, ExtraCharacter } from '../../services/orderService';
import { ChevronLeftIcon, ChevronRightIcon, ChevronUpDownIcon, CheckIcon } from '@heroicons/react/20/solid';
import { Listbox } from '@headlessui/react';
import ConfirmDialog from '../../shared/ConfirmDialog';
import OrderDetailsSection from '../components/orders/OrderDetailsSection';
import BillingSection from '../components/orders/BillingSection';
import { Customer, getCustomerById } from '../../services/customerService';
import { Character } from '../../services/characterService';
import { BillingInfo } from '../../types/order';
import { useNotification } from '../components/Notification';

interface ExtendedOrder extends Omit<Order, 'client' | 'billingInfo'> {
  client: Partial<Customer> | null;
  billingInfo: BillingInfo | null;
}

const customerTypes = [
  { id: 'persoana fizica', name: 'Persoană fizică' },
  { id: 'persoana juridica', name: 'Persoană juridică' },
  { id: 'gradinita/scoala', name: 'Grădiniță/Școală' },
  { id: 'loc de joaca', name: 'Loc de joacă' },
];

const OrderView: React.FC = () => {
  const { orderSlug } = useParams<{ orderSlug: string }>();
  const location = useLocation();
  const [order, setOrder] = useState<ExtendedOrder | null>(null);
  const [loading, setLoading] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedOrder, setEditedOrder] = useState<ExtendedOrder | null>(null);
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { addNotification } = useNotification();

  useEffect(() => {
    const fetchOrderAndCustomer = async () => {
      if (orderSlug) {
        const idMatch = orderSlug.match(/-([a-f0-9]+)$/);
        const orderId = idMatch ? idMatch[1] : null;

        if (orderId) {
          try {
            const fetchedOrder = await getOrderById(orderId);
            let clientData: Partial<Customer> | null = null;
            if (fetchedOrder.client) {
              if (typeof fetchedOrder.client === 'string') {
                clientData = await getCustomerById(fetchedOrder.client);
              } else {
                clientData = fetchedOrder.client;
              }
            }

            const extendedOrder: ExtendedOrder = {
              ...fetchedOrder,
              client: clientData,
              billingInfo: fetchedOrder.billingInfo || null,
            };

            if (JSON.stringify(order) !== JSON.stringify(extendedOrder)) {
              setOrder(extendedOrder);
              setEditedOrder(extendedOrder);
              
              const searchParams = new URLSearchParams(location.search);
              if (searchParams.get('edit') === 'true') {
                setIsEditing(true);
              }
            }
          } catch (error) {
            console.error('Error fetching order details:', error);
            addNotification('error', 'Eroare', 'Nu s-au putut încărca detaliile comenzii.');
            navigate('/dashboard/comenzi', { replace: true });
          }
        }
      }
      setLoading(false);
    };

    fetchOrderAndCustomer();
  }, [orderSlug, navigate, location, addNotification]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDelete = async () => {
    if (!order?._id) return;

    try {
      await deleteOrder(order._id);
      addNotification('success', 'Succes', 'Comanda a fost ștearsă cu succes.');
      navigate('/dashboard/comenzi', { replace: true });
    } catch (error) {
      console.error('Eroare la ștergerea comenzii:', error);
      addNotification('error', 'Eroare', 'Nu s-a putut șterge comanda.');
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
    setShowDropdown(false);
  };

  const handleSave = async () => {
    if (!order?._id || !editedOrder) return;
  
    try {
      const orderToUpdate: any = {
        ...editedOrder,
        character: typeof editedOrder.character === 'string' 
          ? editedOrder.character
          : editedOrder.character._id,
  
        client: {
          ...editedOrder.client,
          _id: typeof editedOrder.client === 'string'
            ? editedOrder.client
            : editedOrder.client?._id,
        },
  
        driver: typeof editedOrder.driver === 'string'
          ? editedOrder.driver
          : editedOrder.driver?._id,
  
        extraCharacters: editedOrder.extraCharacters?.map(char => ({
          character: typeof char.character === 'string' 
            ? char.character
            : char.character._id,
          modelFee: char.modelFee,
        })),
        
        billingInfo: editedOrder.billingInfo,
        period: editedOrder.period,
        address: editedOrder.address,
        zone: editedOrder.zone,
      };
  
      if (!editedOrder.documents) {
        delete orderToUpdate.documents;
      }
  
      const updatedOrder = await updateOrder(order._id, orderToUpdate);
      setOrder(updatedOrder);
      setIsEditing(false);
      addNotification('success', 'Succes', 'Comanda a fost actualizată cu succes.');
      navigate('/dashboard/comenzi');
    } catch (error) {
      console.error('Eroare la actualizarea comenzii:', error);
      addNotification('error', 'Eroare', 'Nu s-a putut actualiza comanda.');
    }
  };

  const handleCancel = () => {
    setEditedOrder(order);
    setIsEditing(false);
  };

  const updateOrderData = (data: Partial<Order>) => {
    setEditedOrder(prevState => {
      if (!prevState) return null;

      if (JSON.stringify(prevState) !== JSON.stringify({ ...prevState, ...data })) {
        return { ...prevState, ...data } as ExtendedOrder;
      }

      return prevState;
    });
  };

  const updateClientData = (data: Partial<Customer>) => {
    setEditedOrder(prevState => {
      if (!prevState) return null;
      return {
        ...prevState,
        client: { ...prevState.client, ...data },
      };
    });
  };

  if (loading) return <div>Se încarcă...</div>;
  if (!order) return <div>Comanda nu a fost găsită.</div>;

  return (
    <div>
      <div className="sm:flex sm:items-center sm:justify-between">
        <div className="sm:flex sm:items-center">
          <Link to="/dashboard/comenzi" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
            ← Înapoi la lista de comenzi
          </Link>
        </div>
        <div className="mt-4 flex items-center justify-between sm:mt-0 sm:ml-6 sm:flex-shrink-0">
          <div className="flex items-center space-x-3">
            <button
              onClick={() => {/* Navigare la comanda anterioară */}}
              className="inline-flex items-center rounded-full border border-gray-300 bg-white p-1 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              onClick={() => {/* Navigare la comanda următoare */}}
              className="inline-flex items-center rounded-full border border-gray-300 bg-white p-1 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="relative ml-3" ref={dropdownRef}>
            <button
              type="button"
              onClick={() => setShowDropdown(!showDropdown)}
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Acțiuni
            </button>
            {showDropdown && (
              <div className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <a href="#" className="block px-4 py-2 text-sm text-gray-700" onClick={handleEdit}>Editare</a>
                <a href="#" className="block px-4 py-2 text-sm text-gray-700" onClick={() => setShowDeleteConfirm(true)}>Ștergere</a>
              </div>
            )}
          </div>
        </div>
      </div>

      {isEditing && (
        <div className="mt-4 rounded-md bg-yellow-50 p-4 border border-yellow-200">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">Atenție</h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>Ești în modul de editare. Modificările nu vor fi salvate până când nu apeși butonul "Salvează".</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="mt-6">
        <OrderDetailsSection
          updateOrderData={updateOrderData}
          setOrderAddress={(address: string) => updateOrderData({ address })}
          setOrderZone={(zone: string) => updateOrderData({ zone })}
          initialData={editedOrder as Partial<Order>}
          extraCharacters={editedOrder?.extraCharacters || []}
          onAddExtraCharacter={() => {
              const newCharacter: ExtraCharacter = {
                  character: {
                      _id: '',
                      firstName: '',
                      lastName: '',
                      characterType: '',
                      email: '',
                      phone1: '',
                      cnp: '',
                      address: '',
                      iban: '',
                      contractNumber: '',
                      eyeColor: '',
                      experience: 0,
                      birthDate: '',
                      languages: [],
                      profileStatus: 'active',
                      photos: [],
                      createdAt: '',
                      updatedAt: ''
                  },
                  modelFee: 0,
              };
              updateOrderData({ extraCharacters: [...(editedOrder?.extraCharacters || []), newCharacter] });
          }}
          onRemoveExtraCharacter={(index: number) => {
              const updatedExtraCharacters = editedOrder?.extraCharacters?.filter((_, i) => i !== index);
              updateOrderData({ extraCharacters: updatedExtraCharacters });
          }}
        />

        <div className="mt-10">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">Informații facturare</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Completați detaliile de facturare pentru această comandă.</p>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="clientPhone" className="block text-sm font-medium text-gray-700">
                Telefon Client
              </label>
              <input
                type="tel"
                name="clientPhone"
                id="clientPhone"
                value={editedOrder?.client?.phone || ''}
                onChange={(e) => updateClientData({ phone: e.target.value })}
                className="mt-1 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="clientName" className="block text-sm font-medium text-gray-700">
                Nume Client
              </label>
              <input
                type="text"
                name="clientName"
                id="clientName"
                value={editedOrder?.client?.name || ''}
                onChange={(e) => updateClientData({ name: e.target.value })}
                className="mt-1 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="clientEmail" className="block text-sm font-medium text-gray-700">
                Email Client
              </label>
              <input
                type="email"
                name="clientEmail"
                id="clientEmail"
                value={editedOrder?.client?.email || ''}
                onChange={(e) => updateClientData({ email: e.target.value })}
                className="mt-1 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="clientAddress" className="block text-sm font-medium text-gray-700">
                Adresă Client
              </label>
              <input
                type="text"
                name="clientAddress"
                id="clientAddress"
                value={editedOrder?.client?.address || ''}
                onChange={(e) => updateClientData({ address: e.target.value })}
                className="mt-1 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="clientZone" className="block text-sm font-medium text-gray-700">
                Zonă Client
              </label>
              <input
                type="text"
                name="clientZone"
                id="clientZone"
                value={editedOrder?.client?.zona || ''}
                onChange={(e) => updateClientData({ zona: e.target.value })}
                className="mt-1 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="clientType" className="block text-sm font-medium text-gray-700">
                Tip Client
              </label>
              <Listbox
                value={customerTypes.find(type => type.id === editedOrder?.client?.customerType) || customerTypes[0]}
                onChange={(selectedType) => updateClientData({ customerType: selectedType.id as Customer['customerType'] })}
              >
                <div className="mt-1 relative">
                  <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <span className="block truncate">
                      {customerTypes.find(type => type.id === editedOrder?.client?.customerType)?.name || 'Selectați tipul clientului'}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>
                  <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    {customerTypes.map((type) => (
                      <Listbox.Option
                        key={type.id}
                        value={type}
                        className={({ active }) =>
                          `${active ? 'text-white bg-indigo-600' : 'text-gray-900'} cursor-default select-none relative py-2 pl-3 pr-9`
                        }
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={`${selected ? 'font-semibold' : 'font-normal'} block truncate`}>
                              {type.name}
                            </span>
                            {selected && (
                              <span className={`${active ? 'text-white' : 'text-indigo-600'} absolute inset-y-0 right-0 flex items-center pr-4`}>
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            )}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </div>
              </Listbox>
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="clientNotes" className="block text-sm font-medium text-gray-700">
                Mențiuni Client
              </label>
              <textarea
                id="clientNotes"
                name="clientNotes"
                rows={3}
                value={editedOrder?.client?.mentiuni || ''}
                onChange={(e) => updateClientData({ mentiuni: e.target.value })}
                className="mt-1 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>

        <BillingSection
          updateOrderData={updateOrderData}
          extraCharacters={editedOrder?.extraCharacters || []}
          initialData={editedOrder?.billingInfo ?? undefined}
        />

        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={handleCancel}
            className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Anulează
          </button>
          <button
            onClick={handleSave}
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Salvează
          </button>
        </div>
      </div>

      <ConfirmDialog
        open={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        onConfirm={handleDelete}
        title="Confirmare ștergere comandă"
        description="Sunteți sigur că doriți să ștergeți această comandă? Această acțiune nu poate fi anulată."
        confirmText="Șterge"
        cancelText="Anulează"
      />
    </div>
  );
};

export default OrderView;