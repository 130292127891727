import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import ro from 'date-fns/locale/ro';
import { CalendarIcon } from '@heroicons/react/20/solid';
import './CustomDatePicker.css'; // Importăm fișierul CSS personalizat

registerLocale('ro', ro);

interface CustomDatePickerProps {
  selectedDate: string;
  onChange: (date: string) => void;
  label: string;
  name: string;
  placeholder: string;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({ selectedDate, onChange, label, name, placeholder }) => {
  const parseDate = (dateString: string): Date | null => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? null : date;
  };

  const formatDate = (date: Date): string => {
    return date.toISOString();
  };

  return (
    <div className="custom-date-picker-container w-full">
      <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <div className="relative w-full">
        <DatePicker
          selected={parseDate(selectedDate)}
          onChange={(date: Date) => onChange(date ? formatDate(date) : '')}
          locale="ro"
          dateFormat="dd.MM.yyyy"
          name={name}
          placeholderText={placeholder}
          className="custom-datepicker block w-full rounded-md border border-gray-300 py-1.5 pl-3 pr-10 text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
      </div>
    </div>
  );
};

export default CustomDatePicker;