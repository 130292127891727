import React, { useState, useEffect, useCallback } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { HiPause, HiPlay, HiChevronLeft, HiChevronRight, HiX } from 'react-icons/hi';
import { useSwipeable } from 'react-swipeable';

interface Character {
  _id: string;
  name: string;
  mainImage: string;
  gallery: string[];
}

interface CharacterCarouselProps {
  characters: Character[];
  initialCharacterIndex: number;
  onClose: () => void;
}

const CharacterCarousel: React.FC<CharacterCarouselProps> = ({ characters, initialCharacterIndex, onClose }) => {
  const [currentCharacterIndex, setCurrentCharacterIndex] = useState(initialCharacterIndex);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [deviceType, setDeviceType] = useState<'desktop' | 'tablet' | 'mobile'>('desktop');

  useEffect(() => {
    const checkDeviceType = () => {
      const width = window.innerWidth;
      if (width <= 640) setDeviceType('mobile');
      else if (width <= 1024) setDeviceType('tablet');
      else setDeviceType('desktop');
    };
    checkDeviceType();
    window.addEventListener('resize', checkDeviceType);
    
    document.body.classList.add('carousel-open');
    if (deviceType !== 'desktop') {
      document.body.style.backgroundColor = 'black';
    }

    return () => {
      window.removeEventListener('resize', checkDeviceType);
      document.body.classList.remove('carousel-open');
      document.body.style.backgroundColor = '';
    };
  }, [deviceType]);

  const character = characters[currentCharacterIndex];
  const images = [character.mainImage, ...character.gallery];

  const goToNext = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    setProgress(0);
  }, [images.length]);

  const goToPrev = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    setProgress(0);
  }, [images.length]);

  const goToNextCharacter = () => {
    setCurrentCharacterIndex((prevIndex) => (prevIndex + 1) % characters.length);
    setCurrentIndex(0);
    setProgress(0);
  };

  const goToPrevCharacter = () => {
    setCurrentCharacterIndex((prevIndex) => (prevIndex - 1 + characters.length) % characters.length);
    setCurrentIndex(0);
    setProgress(0);
  };

  const togglePlayPause = () => {
    setIsPlaying((prevState) => !prevState);
  };

  useEffect(() => {
    if (!isPlaying) return;

    const progressInterval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          goToNext();
          return 0;
        }
        return prev + 1;
      });
    }, 30);

    return () => clearInterval(progressInterval);
  }, [isPlaying, goToNext]);

  const getVisibleImages = () => {
    if (deviceType !== 'desktop') {
      return [{ src: images[currentIndex], index: currentIndex }];
    }
    const visibleIndices = [
      (currentIndex - 1 + images.length) % images.length,
      currentIndex,
      (currentIndex + 1) % images.length
    ];
    return visibleIndices.map(index => ({ src: images[index], index }));
  };

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const prevCharacter = characters[(currentCharacterIndex - 1 + characters.length) % characters.length];
  const nextCharacter = characters[(currentCharacterIndex + 1) % characters.length];

  const handlers = useSwipeable({
    onSwipedLeft: () => deviceType !== 'desktop' && goToNext(),
    onSwipedRight: () => deviceType !== 'desktop' && goToPrev(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  if (deviceType === 'desktop') {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={handleOutsideClick}>
        <div className="relative w-full h-full flex items-center justify-center p-4 md:p-20" {...handlers}>
          <button
            onClick={onClose}
            className="absolute top-4 right-4 md:top-6 md:right-6 bg-black/50 hover:bg-black/70 text-white rounded-full p-2 transition-colors z-20"
          >
            <HiX className="h-6 w-6" />
          </button>

          <div className="w-full max-w-6xl h-[80vh] relative overflow-hidden rounded-[20px]">
            <div className="flex h-full transition-transform duration-500 ease-in-out">
              {getVisibleImages().map((image, idx) => {
                const isActive = idx === 1;
                return (
                  <div
                    key={image.index}
                    onClick={() => isActive ? null : (idx === 0 ? goToPrev() : goToNext())}
                    className={`cursor-pointer w-1/3 flex-shrink-0 flex items-center justify-center transition-all duration-500 ease-in-out ${
                      isActive ? 'transform scale-100 z-10' : 'transform scale-95'
                    }`}
                  >
                    <div className="relative w-full h-full">
                      <div className="absolute inset-0 overflow-hidden rounded-[20px]">
                        <div className="absolute inset-x-0 top-0 h-20 bg-gradient-to-b from-black/50 to-transparent"></div>
                        {isActive && (
                          <div className="absolute top-6 left-1/2 transform -translate-x-1/2 w-11/12 flex items-center justify-between">
                            <div className="flex-grow h-[2px] bg-gray-300 rounded-full overflow-hidden">
                              <div
                                className="h-full bg-red-600"
                                style={{ width: `${progress}%` }}
                              />
                            </div>
                            <button onClick={togglePlayPause} className="text-white ml-4 text-2xl">
                              {isPlaying ? <HiPause className="h-6 w-6" /> : <HiPlay className="h-6 w-6" />}
                            </button>
                          </div>
                        )}
                        {isActive && (
                          <p className="absolute left-6 top-12 text-sm text-white font-semibold">{character.name}</p>
                        )}
                        <img
                          src={image.src}
                          alt={`Carousel ${image.index}`}
                          className="w-full h-full object-cover rounded-[20px]"
                        />
                        {isActive && (
                          <div className="absolute bottom-0 left-0 w-full">
                            <a
                              href="https://wa.me/40748161436"
                              className="flex justify-center items-center w-full bg-red-600 text-white text-base font-semibold py-4 shadow-sm rounded-b-[20px]"
                            >
                              <FaWhatsapp className="h-5 w-5 mr-2" />
                              Rezervă acum
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="absolute left-8 top-1/2 transform -translate-y-1/2 flex items-center">
            <button
              onClick={goToPrevCharacter}
              className="bg-black/50 hover:bg-black/70 text-white rounded-full p-2 transition-colors"
            >
              <HiChevronLeft className="h-6 w-6" />
            </button>
            <span className="ml-2 bg-black/50 text-white px-2 py-1 rounded text-sm">
              {prevCharacter.name}
            </span>
          </div>
          <div className="absolute right-8 top-1/2 transform -translate-y-1/2 flex items-center">
            <span className="mr-2 bg-black/50 text-white px-2 py-1 rounded text-sm">
              {nextCharacter.name}
            </span>
            <button
              onClick={goToNextCharacter}
              className="bg-black/50 hover:bg-black/70 text-white rounded-full p-2 transition-colors"
            >
              <HiChevronRight className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="fixed inset-0 bg-black flex flex-col z-50" onClick={handleOutsideClick}>
        <div className="bg-black w-full p-4 flex flex-col">
          <div className="flex items-center justify-between mb-2">
            <button
              onClick={onClose}
              className="text-white"
            >
              <HiX className="h-6 w-6" />
            </button>
            <div className="flex-grow mx-4 h-2 bg-gray-300 rounded-full overflow-hidden">
              <div
                className="h-full bg-red-600"
                style={{ width: `${progress}%` }}
              />
            </div>
            <button onClick={togglePlayPause} className="text-white">
              {isPlaying ? <HiPause className="h-6 w-6" /> : <HiPlay className="h-6 w-6" />}
            </button>
          </div>
          <p className="text-sm text-white font-semibold">{character.name}</p>
        </div>

        <div className="relative flex-grow" {...handlers}>
          {getVisibleImages().map((image) => (
            <div key={image.index} className="absolute inset-0">
              <img
                src={image.src}
                alt={`Carousel ${image.index}`}
                className="w-full h-full object-contain"
              />
            </div>
          ))}
        </div>

        <div className="bg-black w-full p-4 space-y-4">
          <a
            href="https://wa.me/40748161436"
            className="flex justify-center items-center w-full bg-red-600 text-white text-base font-semibold py-4 rounded-lg"
          >
            <FaWhatsapp className="h-5 w-5 mr-2" />
            Rezervă acum
          </a>
          <div className="flex justify-between items-center">
            <button
              onClick={goToPrevCharacter}
              className="text-white flex items-center"
            >
              <HiChevronLeft className="h-6 w-6 mr-2" />
              <span className="text-sm">{prevCharacter.name}</span>
            </button>
            <button
              onClick={goToNextCharacter}
              className="text-white flex items-center"
            >
              <span className="text-sm">{nextCharacter.name}</span>
              <HiChevronRight className="h-6 w-6 ml-2" />
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default CharacterCarousel;