import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import Navbar from '../components/PublicNavbar';
import PublicFooter from '../components/PublicFooter';

export default function PrivacyPolicy() {
  return (
    <div className="min-h-screen bg-white flex flex-col">
      <Navbar />
      <main className="flex-grow">
        <div className="bg-white py-16 sm:py-24">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <p className="text-base font-semibold leading-7 text-red-600">Politica de Confidențialitate</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Politica de Confidențialitate inchiriazamoscraciun.ro</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
                Această politică de confidențialitate explică modul în care inchiriazamoscraciun.ro colectează, utilizează și protejează informațiile personale ale utilizatorilor noștri.
              </p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl lg:mx-0 lg:max-w-none">
              <div className="grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-700 lg:max-w-none lg:grid-cols-2">
                <div>
                  <h2 className="text-2xl font-bold tracking-tight text-gray-900">Colectarea Datelor</h2>
                  <p className="mt-6">
                    Colectăm date personale prin intermediul formularului de contact de pe site-ul nostru. Aceste date sunt utilizate exclusiv pentru a procesa rezervările și a răspunde solicitărilor dumneavoastră.
                  </p>
                  <ul role="list" className="mt-8 space-y-8 text-gray-600">
                    <li className="flex gap-x-3">
                      <InformationCircleIcon className="mt-1 h-5 w-5 flex-none text-red-600" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-gray-900">Date colectate:</strong> Nume, adresă de email, număr de telefon și alte informații furnizate voluntar în mesajul dumneavoastră.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <InformationCircleIcon className="mt-1 h-5 w-5 flex-none text-red-600" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-gray-900">Scop:</strong> Procesarea rezervărilor pentru Moș Crăciun și comunicarea legată de aceste servicii.
                      </span>
                    </li>
                  </ul>
                  
                  <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Utilizarea Datelor</h2>
                  <p className="mt-6">
                    Datele colectate sunt utilizate strict pentru scopurile menționate și nu sunt partajate cu terțe părți, cu excepția cazurilor în care este necesar pentru furnizarea serviciilor noastre sau când suntem obligați legal să facem acest lucru.
                  </p>
                </div>
                <div>
                  <h2 className="text-2xl font-bold tracking-tight text-gray-900">Drepturile Dumneavoastră</h2>
                  <p className="mt-6">
                    În conformitate cu GDPR, aveți următoarele drepturi în ceea ce privește datele dumneavoastră personale:
                  </p>
                  <ul role="list" className="mt-8 space-y-8 text-gray-600">
                    <li className="flex gap-x-3">
                      <InformationCircleIcon className="mt-1 h-5 w-5 flex-none text-red-600" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-gray-900">Dreptul de acces:</strong> Puteți solicita o copie a datelor personale pe care le deținem despre dumneavoastră.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <InformationCircleIcon className="mt-1 h-5 w-5 flex-none text-red-600" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-gray-900">Dreptul la rectificare:</strong> Puteți cere corectarea oricăror date incorecte sau incomplete.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <InformationCircleIcon className="mt-1 h-5 w-5 flex-none text-red-600" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-gray-900">Dreptul la ștergere:</strong> Puteți solicita ștergerea datelor dumneavoastră personale în anumite circumstanțe.
                      </span>
                    </li>
                  </ul>
                  
                  <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Securitatea Datelor</h2>
                  <p className="mt-6">
                    Luăm măsuri tehnice și organizatorice adecvate pentru a proteja datele dumneavoastră personale împotriva pierderii, accesului neautorizat sau divulgării accidentale.
                  </p>
                </div>
              </div>
              <div className="mt-16 max-w-2xl">
                <h2 className="text-2xl font-bold tracking-tight text-gray-900">Modificări ale Politicii de Confidențialitate</h2>
                <p className="mt-6">
                  Ne rezervăm dreptul de a modifica această politică de confidențialitate în orice moment. Orice modificări vor fi postate pe această pagină și vor intra în vigoare imediat. Vă încurajăm să revizuiți periodic această politică pentru a fi la curent cu modul în care protejăm informațiile dumneavoastră.
                </p>
                <p className="mt-6">
                  Pentru orice întrebări sau preocupări legate de politica noastră de confidențialitate, vă rugăm să ne contactați la adresa de email: contact@inchiriazamoscraciun.ro.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <PublicFooter />
    </div>
  );
}