import apiClient from '../utils/axiosSetup';

export const API_URL = process.env.REACT_APP_API_URL || 'https://localhost:5000';

// Tipul pentru utilizatori
export interface User {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: 'Super Admin' | 'Admin' | 'Moderator';
  createdAt?: Date | string;
  updatedAt?: Date | string;
}

// Helper pentru logarea erorilor
const logError = (message: string, error: any) => {
  console.error(message, error);
  if (error.response) {
    console.error('Response data:', error.response.data);
    console.error('Response status:', error.response.status);
  }
};

// Obține lista de utilizatori
export const getUsers = async (): Promise<User[]> => {
  try {
    console.log('Încercare de obținere a listei de utilizatori');
    const response = await apiClient.get<User[]>('/api/users');
    console.log('Lista de utilizatori obținută cu succes');
    return response.data;
  } catch (error) {
    logError('Eroare la obținerea utilizatorilor', error);
    throw error;
  }
};

// Adaugă un utilizator nou
export const addUser = async (userData: Partial<User>): Promise<User> => {
  try {
    const response = await apiClient.post<User>(`${API_URL}/api/users/add`, userData);
    return response.data;
  } catch (error) {
    console.error('Eroare la adăugarea utilizatorului', error);
    throw error;
  }
};

// Obține un utilizator după ID
export const getUserById = async (id: string): Promise<User> => {
  try {
    console.log(`Încercare de obținere a utilizatorului cu ID-ul: ${id}`);
    const response = await apiClient.get<User>(`/api/users/${id}`);
    console.log('Detalii utilizator obținute cu succes');
    return response.data;
  } catch (error) {
    logError(`Eroare la obținerea detaliilor utilizatorului cu ID-ul: ${id}`, error);
    throw error;
  }
};

// Actualizează un utilizator
export const updateUser = async (id: string, updateData: Partial<User>): Promise<User> => {
  try {
    console.log(`Încercare de actualizare a utilizatorului cu ID-ul: ${id}`);
    const response = await apiClient.put<User>(`/api/users/edit/${id}`, updateData);
    console.log('Utilizator actualizat cu succes');
    return response.data;
  } catch (error) {
    logError(`Eroare la actualizarea utilizatorului cu ID-ul: ${id}`, error);
    throw error;
  }
};

// Șterge un utilizator
export const deleteUser = async (id: string): Promise<void> => {
  try {
    console.log(`Încercare de ștergere a utilizatorului cu ID-ul: ${id}`);
    await apiClient.delete(`/api/users/${id}`);
    console.log('Utilizator șters cu succes');
  } catch (error) {
    logError(`Eroare la ștergerea utilizatorului cu ID-ul: ${id}`, error);
    throw error;
  }
};

// Obține profilul utilizatorului curent
export const getCurrentUserProfile = async (): Promise<User> => {
  try {
    console.log('Încercare de obținere a profilului utilizatorului curent');
    const response = await apiClient.get<User>('/api/users/profile');
    console.log('Profil utilizator obținut cu succes');
    return response.data;
  } catch (error) {
    logError('Eroare la obținerea profilului utilizatorului curent', error);
    throw error;
  }
};

// Actualizează profilul utilizatorului curent
export const updateCurrentUserProfile = async (updateData: Partial<User>): Promise<User> => {
  try {
    console.log('Încercare de actualizare a profilului utilizatorului curent');
    const response = await apiClient.put<User>('/api/users/profile', updateData);
    console.log('Profil utilizator actualizat cu succes');
    return response.data;
  } catch (error) {
    logError('Eroare la actualizarea profilului utilizatorului curent', error);
    throw error;
  }
};

// Logout pentru utilizatorul curent
export const logout = async (): Promise<void> => {
  try {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      console.warn('Utilizatorul nu este logat');
      return;
    }

    console.log('Încercare de logout pentru utilizatorul:', userId);
    await apiClient.post('/api/auth/logout', { userId });
    console.log('Logout efectuat cu succes');
  } catch (error) {
    logError('Eroare la cererea de logout către server', error);
  } finally {
    console.log('Curățare date de autentificare din localStorage');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userId');
  }
};