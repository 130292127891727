import React, { useState, useEffect, useCallback } from 'react';
import { Customer, getCustomers } from '../../../services/customerService';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Listbox } from '@headlessui/react';

interface CustomerSectionProps {
  updateOrderData: (data: { client: string | null; newCustomer?: Partial<Customer> }) => void;
  orderAddress: string;
  orderZone: string;
  initialData?: { 
    client: string | null; 
    newCustomer?: Partial<Customer>;
    customerSource?: 'new' | 'existing'; 
  };
}

const customerCategories = [
  { id: 'persoana fizica', name: 'Persoană fizică' },
  { id: 'persoana juridica', name: 'Persoană juridică' },
  { id: 'gradinita/scoala', name: 'Grădiniță/Școală' },
  { id: 'loc de joaca', name: 'Loc de joacă' },
] as const;

type CustomerCategory = typeof customerCategories[number]['id'];

const CustomerSection: React.FC<CustomerSectionProps> = ({ updateOrderData, orderAddress, orderZone, initialData }) => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [customerSource, setCustomerSource] = useState<'new' | 'existing'>(initialData?.customerSource || 'new');
  const [selectedCustomer, setSelectedCustomer] = useState<string>(initialData?.client || '');
  const [newCustomer, setNewCustomer] = useState<Partial<Customer>>(initialData?.newCustomer || {
    customerType: 'persoana fizica',
    name: '',
    phone: '',
    email: '',
    address: '',
    zona: '',
    mentiuni: '',
  });

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const fetchedCustomers = await getCustomers();
        setCustomers(fetchedCustomers);
      } catch (error) {
        console.error('Eroare la obținerea clienților:', error);
      }
    };

    fetchCustomers();
  }, []);

  const updateCustomerInfo = useCallback(() => {
    if (customerSource === 'existing' && selectedCustomer) {
      console.log('Updating with existing customer:', selectedCustomer);
      updateOrderData({ client: selectedCustomer, newCustomer: undefined });
    } else if (customerSource === 'new' && newCustomer.phone) {
      console.log('Updating with new customer:', newCustomer);
      updateOrderData({ client: null, newCustomer });
    } else {
      console.log('No valid customer selected or added');
      updateOrderData({ client: null, newCustomer: undefined });
    }
  }, [customerSource, selectedCustomer, newCustomer, updateOrderData]);

  useEffect(() => {
    updateCustomerInfo();
  }, [updateCustomerInfo]);

  useEffect(() => {
    if (customerSource === 'new' && newCustomer.customerType === 'persoana fizica') {
      setNewCustomer(prev => ({
        ...prev,
        address: orderAddress,
        zona: orderZone
      }));
    }
  }, [customerSource, newCustomer.customerType, orderAddress, orderZone]);

  const handleNewCustomerChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setNewCustomer(prev => ({ ...prev, [name]: value }));
  };

  const handleCustomerSourceChange = (value: string) => {
    if (value === 'new' || value === 'existing') {
      setCustomerSource(value);
      if (value === 'existing') {
        setSelectedCustomer('');
      } else {
        setNewCustomer({
          customerType: 'persoana fizica',
          name: '',
          phone: '',
          email: '',
          address: orderAddress,
          zona: orderZone,
          mentiuni: '',
        });
      }
      updateCustomerInfo();
    }
  };

  const handleCustomerTypeChange = (value: string) => {
    if (customerCategories.some(cat => cat.id === value)) {
      setNewCustomer(prev => {
        const updatedCustomer = { ...prev, customerType: value as CustomerCategory };
        if (value === 'persoana fizica') {
          updatedCustomer.address = orderAddress;
          updatedCustomer.zona = orderZone;
        } else {
          updatedCustomer.address = '';
          updatedCustomer.zona = '';
        }
        return updatedCustomer;
      });
      updateCustomerInfo();
    }
  };

  const renderListbox = (
    label: string,
    name: string,
    value: string,
    options: readonly { id: string; name: string }[] | { id: string; name: string }[],
    onChange: (value: string) => void
  ) => (
    <div className="sm:col-span-1">
      <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className="mt-2">
        <Listbox value={value} onChange={onChange}>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate">{options.find(opt => opt.id === value)?.name || 'Selectează'}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>
            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map((option) => (
                <Listbox.Option
                  key={option.id}
                  value={option.id}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-3 pr-9 ${
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                    }`
                  }
                >
                  {({ selected, active }) => (
                    <>
                      <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>
                        {option.name}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        </Listbox>
      </div>
    </div>
  );

  return (
    <div className="space-y-6 sm:space-y-5 border-t border-gray-200 pt-6">
      <div>
        <h3 className="text-lg font-medium leading-6 text-gray-900">Informații client</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Selectați un client existent sau adăugați un client nou (doar numărul de telefon este obligatoriu).
        </p>
      </div>

      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
        {renderListbox(
          'Sursă client',
          'customerSource',
          customerSource,
          [
            { id: 'new', name: 'Client nou' },
            { id: 'existing', name: 'Client existent' },
          ],
          handleCustomerSourceChange
        )}

        {customerSource === 'existing' && (
          renderListbox(
            'Selectează client',
            'existingCustomer',
            selectedCustomer,
            customers.map(customer => ({ id: customer._id, name: `${customer.phone} - ${customer.name || 'Fără nume'}` })),
            (value) => {
              setSelectedCustomer(value);
              updateCustomerInfo();
            }
          )
        )}

        {customerSource === 'new' && (
          <>
            {renderListbox(
              'Categorie client',
              'newCustomerType',
              newCustomer.customerType || '',
              customerCategories,
              handleCustomerTypeChange
            )}

            <div className="sm:col-span-1">
              <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                Telefon *
              </label>
              <div className="mt-2">
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  value={newCustomer.phone}
                  onChange={(e) => {
                    handleNewCustomerChange(e);
                    updateCustomerInfo();
                  }}
                  required
                  className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-1">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Nume
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={newCustomer.name}
                  onChange={(e) => {
                    handleNewCustomerChange(e);
                    updateCustomerInfo();
                  }}
                  className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-1">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email
              </label>
              <div className="mt-2">
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={newCustomer.email}
                  onChange={(e) => {
                    handleNewCustomerChange(e);
                    updateCustomerInfo();
                  }}
                  className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-1">
              <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900">
                Adresă
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="address"
                  id="address"
                  value={newCustomer.address}
                  onChange={(e) => {
                    handleNewCustomerChange(e);
                    updateCustomerInfo();
                  }}
                  className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-1">
              <label htmlFor="zona" className="block text-sm font-medium leading-6 text-gray-900">
                Zona
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="zona"
                  id="zona"
                  value={newCustomer.zona}
                  onChange={(e) => {
                    handleNewCustomerChange(e);
                    updateCustomerInfo();
                  }}
                  className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="mentiuni" className="block text-sm font-medium leading-6 text-gray-900">
                Mențiuni
              </label>
              <div className="mt-2">
                <textarea
                  name="mentiuni"
                  id="mentiuni"
                  rows={3}
                  value={newCustomer.mentiuni}
                  onChange={(e) => {
                    handleNewCustomerChange(e);
                    updateCustomerInfo();
                  }}
                  className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CustomerSection;