import React, { useEffect, useState, useRef } from 'react';
import {
  ChevronRightIcon,
  UserIcon,
  PhoneIcon,
  CalendarIcon,
  ClockIcon,
  MapPinIcon,
  CurrencyDollarIcon,
  UserCircleIcon,
  BuildingOfficeIcon,
  TruckIcon,
  ChatBubbleLeftEllipsisIcon,
  SparklesIcon,
  PrinterIcon,
  CheckIcon,
  ChevronDownIcon,
  ArrowsUpDownIcon,
  DocumentArrowDownIcon,
  DocumentTextIcon,
  AdjustmentsHorizontalIcon
} from '@heroicons/react/20/solid';
import { getOrders, Order } from '../../services/orderService';
import { getCustomers, Customer } from '../../services/customerService';
import { getCharacters, Character } from '../../services/characterService';
import { getCurrentUserProfile } from '../../services/userService';
import { useNavigate, Link } from 'react-router-dom';
import { useNotification, NotificationProvider } from '../components/Notification';
import PrintableOrderTable from '../components/PrintableOrderTable';
import OrderFilters from '../components/orderFilters';
import OrderDrawer from '../components/orderDrawer';
import ModernPagination from '../components/ModernPagination';
import ReactToPrint from 'react-to-print';
import { Listbox } from '@headlessui/react';
import { jsPDF } from 'jspdf';
import { parseISO, format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { ro } from 'date-fns/locale';
import { useAuth } from '../../hooks/useAuth';

const itemsPerPageOptions = [25, 50, 75, 100];

interface FilterCriteria {
  character: string;
  clientType: string;
  searchTerm: string;
  startDate: string;
  endDate: string;
}

const exportPDF = (type: 'phone' | 'email', data: string[]) => {
  const doc = new jsPDF();
  const title = type === 'phone' ? 'Numere de Telefon' : 'Email-uri';

  doc.setFontSize(16);
  doc.text(title, 10, 10);

  doc.setFontSize(12);
  doc.text(data.join('\n'), 10, 20);
  doc.save(`${title}.pdf`);
};

const OrderPageContent: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [userProfile, setUserProfile] = useState<{firstName: string} | null>(null);
  const [filteredOrders, setFilteredOrders] = useState<Order[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [characters, setCharacters] = useState<Character[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hideTotalAmount, setHideTotalAmount] = useState(false);
  const [sortKey, setSortKey] = useState<'date' | 'status' | 'totalAmount'>('date');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [showFilters, setShowFilters] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { canAddOrders } = useAuth();
  const [filterCriteria, setFilterCriteria] = useState<FilterCriteria>({
    character: '',
    clientType: 'Toate tipurile de clienți',
    searchTerm: '',
    startDate: '',
    endDate: '',
  });

  const navigate = useNavigate();
  const { addNotification } = useNotification();
  const printRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profile = await getCurrentUserProfile();
        setUserProfile(profile);
      } catch (error) {
        console.error('Eroare la obținerea profilului utilizatorului:', error);
      }
    };
  
    fetchUserProfile();
  }, []);

  useEffect(() => {
    fetchOrders();
    fetchCustomersAndCharacters();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [orders, filterCriteria]);

  // Funcție helper pentru a converti timpul în minute pentru comparație ușoară
  const timeToMinutes = (timeString: string) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
  };

  // Funcție helper pentru a compara două comenzi după dată și oră
  const compareOrdersByDateTime = (a: Order, b: Order) => {
    // Mai întâi comparăm datele
    const dateComparison = new Date(a.period.startDate).getTime() - new Date(b.period.startDate).getTime();
    
    // Dacă datele sunt diferite, returnăm comparația datelor
    if (dateComparison !== 0) {
      return dateComparison;
    }
    
    // Dacă datele sunt egale, comparăm orele
    const timeA = timeToMinutes(a.period.startTime);
    const timeB = timeToMinutes(b.period.startTime);
    return timeA - timeB;
  };

  const fetchOrders = async () => {
    try {
      const data = await getOrders();
      const sortedOrders = data.sort((a, b) => {
        const comparison = compareOrdersByDateTime(a, b);
        return comparison; 
      });
      
      setOrders(sortedOrders);
      setFilteredOrders(sortedOrders);
      setLoading(false);
    } catch (err) {
      console.error('Eroare la obținerea comenzilor:', err);
      setError('Eroare la obținerea comenzilor');
      setLoading(false);
      addNotification('error', 'Eroare la obținerea comenzilor', 'Nu s-au putut încărca datele comenzilor.');
    }
  };

  const fetchCustomersAndCharacters = async () => {
    try {
      const customersData = await getCustomers();
      setCustomers(customersData);

      const charactersData = await getCharacters();
      setCharacters(charactersData);
    } catch (err) {
      console.error('Eroare la obținerea datelor pentru filtre:', err);
      addNotification('error', 'Eroare', 'Nu s-au putut încărca datele pentru clienți și personaje.');
    }
  };

  const applyFilters = () => {
    const filtered = orders.filter(order => {
      const characterId = order.character._id || order.character;
      const selectedCharacterId = filterCriteria.character;

      // Check if the selected character matches the main character or any extra character
      const matchesCharacter = !selectedCharacterId || 
        characterId.toString() === selectedCharacterId ||
        order.extraCharacters.some(extra => {
          const extraCharId = extra.character._id || extra.character;
          return extraCharId.toString() === selectedCharacterId;
        });

      const matchesClientType = filterCriteria.clientType === 'Toate tipurile de clienți' ||
        order.client.customerType === filterCriteria.clientType;

        const searchTermLower = filterCriteria.searchTerm.toLowerCase();
        const matchesSearch = !filterCriteria.searchTerm ||
          order.client?.name?.toLowerCase().includes(searchTermLower) ||
          order.client?.phone?.includes(searchTermLower) ||
          (order.notes && order.notes.toLowerCase().includes(searchTermLower)) ||
          (order.address && order.address.toLowerCase().includes(searchTermLower)) ||
          (order.zone && order.zone.toLowerCase().includes(searchTermLower)) ||
          (order.character.firstName + ' ' + order.character.lastName).toLowerCase().includes(searchTermLower) ||
          order.extraCharacters.some(extra => 
            (extra.character.firstName + ' ' + extra.character.lastName).toLowerCase().includes(searchTermLower)
          ) ||
          (order.driver && (order.driver.firstName + ' ' + order.driver.lastName).toLowerCase().includes(searchTermLower));

      const matchesStartDate = !filterCriteria.startDate || 
        new Date(order.period.startDate) >= new Date(filterCriteria.startDate);

      const matchesEndDate = !filterCriteria.endDate || 
        new Date(order.period.endDate) <= new Date(filterCriteria.endDate);

      const matches = matchesCharacter && matchesClientType && matchesSearch && matchesStartDate && matchesEndDate;
  
      return matches;
    });

    const sortedFiltered = filtered.sort((a, b) => 
      new Date(a.period.startDate).getTime() - new Date(b.period.startDate).getTime()
    );

    setFilteredOrders(filtered);
  };

  const handleSort = (key: 'date') => {
    if (sortKey === key) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortDirection('asc');
    }
  
    sortOrders();
  };  
  
  const sortOrders = () => {
    const sortedOrders = [...filteredOrders].sort((a, b) => {
      if (sortKey === 'date') {
        // Creează date complete cu dată și oră pentru comparație
        const dateA = new Date(a.period.startDate);
        const dateB = new Date(b.period.startDate);
        
        // Adaugă orele și minutele la date
        const [hoursA, minutesA] = a.period.startTime.split(':');
        const [hoursB, minutesB] = b.period.startTime.split(':');
        
        dateA.setHours(parseInt(hoursA), parseInt(minutesA));
        dateB.setHours(parseInt(hoursB), parseInt(minutesB));
  
        const comparison = dateA.getTime() - dateB.getTime();
        return sortDirection === 'asc' ? comparison : -comparison;
      }
      return 0;
    });
  
    setFilteredOrders(sortedOrders);
  };

  const handleRowClick = (order: Order) => {
    setSelectedOrder(order);
    setIsDrawerOpen(true);
  };

  const timeZone = 'Europe/Bucharest';
  const formatDate = (dateString: string) => {
    return formatInTimeZone(new Date(dateString), timeZone, 'EEEE, d MMM yyyy', { locale: ro });
  };

  const formatTime = (timeString: string) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };

  const handleItemsPerPageChange = (value: number) => {
    setItemsPerPage(value);
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredOrders.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const renderIconWithLabel = (Icon: React.ElementType, label: string, value: string | number | undefined, isClientName = false) => (
    <div className="flex items-center space-x-2">
      <Icon className="h-4 w-4 text-gray-400" />
      <span className="text-xs text-gray-500">{label}:</span>
      {isClientName && (value === 'N/A' || !value) ? (
        <span className="font-medium text-red-600">Client Incomplet</span>
      ) : (
        <span className="font-medium">{value}</span>
      )}
    </div>
  );

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'active':
        return 'text-green-600';
      case 'in asteptare':
        return 'text-blue-600';
      case 'cancelled':
        return 'text-red-600';
      default:
        return 'text-gray-600';
    }
  };

  // This function applies a background color based on the content of the notes
  const highlightIfInvoice = (notes: string) => {
    if (notes.toLowerCase().includes('factura') || notes.toLowerCase().includes('facturi')) {
      return 'bg-red-100';
    }
    return 'bg-yellow-100'; // Galben implicit
  };

  const exportPhones = () => {
    const phoneNumbers = filteredOrders.map(order => order.client?.phone || 'N/A').filter(phone => phone !== 'N/A');
    exportPDF('phone', phoneNumbers);
  };

  const exportEmails = () => {
    const emails = filteredOrders.map(order => order.client?.email || 'N/A').filter(email => email !== 'N/A');
    exportPDF('email', emails);
  };

  if (loading) return <div>Se încarcă...</div>;
  if (error) return <div>{error}</div>;

  const handleOrderUpdate = (updatedOrder: Order) => {
    setOrders((currentOrders) =>
      currentOrders.map((order) =>
        order._id === updatedOrder._id ? { ...order, status: updatedOrder.status } : order
      )
    );
  
    setFilteredOrders((currentFiltered) =>
      currentFiltered.map((order) =>
        order._id === updatedOrder._id ? { ...order, status: updatedOrder.status } : order
      )
    );
  
    // Actualizăm selectedOrder doar dacă există un _id și status
    if (selectedOrder) {
      setSelectedOrder((prevOrder) => ({
        ...prevOrder,
        status: updatedOrder.status,
      } as Order)); // Adăugăm as Order pentru a indica că tipul este sigur Order
    }
  };  

  return (
    <div className="p-0">
      <div className="sm:flex sm:items-center sm:justify-between mb-6">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Lista comenzilor</h1>
          <p className="mt-2 text-sm text-gray-700">
            O listă completă cu toate comenzile plasate, inclusiv detalii despre client, perioadă și facturare.
          </p>
        </div>
        <div className="mt-4 sm:mt-0">
          <Link
            to="/dashboard/comenzi/comanda-noua"
            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 mt-4 sm:mt-0"
          >
            Comandă nouă
          </Link>
        </div>
      </div>

      <div className="md:hidden mb-4">
        <button
          onClick={() => setShowFilters(!showFilters)}
          className="flex items-center space-x-2 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
        >
          <AdjustmentsHorizontalIcon className="h-5 w-5 text-gray-400" />
          <span>{showFilters ? 'Ascunde filtrele' : 'Afișează filtrele'}</span>
        </button>
      </div>

      {/* Filtre */}
      <div className={`md:block ${showFilters ? 'block' : 'hidden'}`}>
        <OrderFilters
          characters={[
            { id: '', name: 'Toate personajele' },
            ...characters.map((character) => ({
              id: character._id,
              name: `${character.firstName} ${character.lastName}`,
            })),
          ]}
          clientTypes={['Toate tipurile de clienți', ...new Set(customers.map((customer) => customer.customerType || '').filter(Boolean))]}
          filterCriteria={filterCriteria}
          setFilterCriteria={setFilterCriteria}
        />
      </div>

      <div className="flex flex-col md:flex-row md:justify-between items-start md:items-center mb-4 space-y-4 md:space-y-0">
        <div className="flex flex-row flex-wrap lg:flex-nowrap lg:items-center gap-4">
          {/* Butoane pentru export telefoane și email-uri */}
          <button
            onClick={exportPhones}
            className="flex items-center justify-center space-x-2 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-green-600 bg-white hover:bg-gray-50 flex-grow lg:flex-grow-0"
          >
            <DocumentArrowDownIcon className="h-5 w-5 text-green-600" />
            <span>Export Numere</span>
          </button>
          <button
            onClick={exportEmails}
            className="flex items-center justify-center space-x-2 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-blue-600 bg-white hover:bg-gray-50 flex-grow lg:flex-grow-0"
          >
            <DocumentTextIcon className="h-5 w-5 text-blue-600" />
            <span>Export Emailuri</span>
          </button>

          {/* Buton pentru printare */}
          <ReactToPrint
            trigger={() => (
              <button className="flex items-center justify-center space-x-2 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 flex-grow lg:flex-grow-0">
                <PrinterIcon className="h-5 w-5 text-gray-400" />
                <span>Printează lista</span>
              </button>
            )}
            content={() => printRef.current}
          />
          <div className="flex items-center space-x-2 flex-grow lg:flex-grow-0">
            <input
              type="checkbox"
              id="hideTotalAmount"
              checked={hideTotalAmount}
              onChange={() => setHideTotalAmount(!hideTotalAmount)}
              className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <label htmlFor="hideTotalAmount" className="text-sm text-gray-700">
              Ascunde suma totală
            </label>
          </div>
        </div>

        {/* Dropdown pentru numărul de comenzi per pagină */}
        <Listbox value={itemsPerPage} onChange={handleItemsPerPageChange}>
          <div className="relative">
            <div className="inline-flex divide-x divide-indigo-700 rounded-md shadow-sm">
              <div className="inline-flex items-center gap-x-1.5 rounded-l-md bg-indigo-600 px-3 py-2 text-white shadow-sm">
                <CheckIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                <p className="text-sm font-semibold">Afișează {itemsPerPage}</p>
              </div>
              <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md bg-indigo-600 p-2 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 focus:ring-offset-gray-50">
                <ChevronDownIcon aria-hidden="true" className="h-5 w-5 text-white" />
              </Listbox.Button>
            </div>

            <Listbox.Options className="absolute right-0 z-10 mt-2 w-48 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {itemsPerPageOptions.map((option) => (
                <Listbox.Option
                  key={option}
                  value={option}
                  className={({ active }) =>
                    `cursor-pointer select-none p-4 text-sm text-gray-900 ${active ? 'bg-indigo-600 text-white' : 'bg-white text-gray-900'}`
                  }
                >
                  {({ selected }) => (
                    <div className="flex justify-between">
                      <p className={`font-normal ${selected ? 'font-semibold' : ''}`}>Afișează {option}</p>
                      {selected && <CheckIcon className="h-5 w-5" aria-hidden="true" />}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        </Listbox>
      </div>

      {currentItems.length === 0 ? (
        <div className="text-center mt-8">
          <p className="text-sm text-gray-500">Momentan nu există comenzi active sau în așteptare!</p>
        </div>
      ) : (
        <div className="mt-8 flow-root" ref={printRef}>
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 print:hidden">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Client
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                      onClick={() => handleSort('date')}
                    >
                      Data și Ora
                      {sortKey === 'date' && (
                        <ArrowsUpDownIcon className={`h-5 w-5 inline ml-1 ${sortDirection === 'asc' ? 'rotate-180' : ''}`} />
                      )}
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Locație
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Detalii Plată
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Model
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Acțiuni</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {currentItems.map((order) => (
                    <React.Fragment key={order._id}>
                      <tr className={`hover:bg-gray-50 cursor-pointer`} onClick={() => handleRowClick(order)}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-0">
                          <div className="flex flex-col space-y-1">
                            {renderIconWithLabel(UserIcon, 'Client', order.client?.name || 'N/A', true)}
                            {renderIconWithLabel(PhoneIcon, 'Telefon', order.client?.phone || 'N/A')}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="flex flex-col space-y-1">
                            {renderIconWithLabel(CalendarIcon, 'Data', formatDate(order.period.startDate))}
                            {renderIconWithLabel(ClockIcon, 'Ora', `${formatTime(order.period.startTime)} - ${formatTime(order.period.endTime)}`)}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="flex flex-col space-y-1">
                            {renderIconWithLabel(MapPinIcon, 'Adresă', order.address || 'Nespecificat')}
                            {order.addressDetails && renderIconWithLabel(MapPinIcon, 'Detalii', order.addressDetails)}
                            {renderIconWithLabel(BuildingOfficeIcon, 'Zonă', order.zone || 'Nespecificat')}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="flex flex-col space-y-1">
                            {!hideTotalAmount && renderIconWithLabel(CurrencyDollarIcon, 'Total', `${order.billingInfo.totalAmount} RON`)}
                            {renderIconWithLabel(UserCircleIcon, 'Model', `${order.billingInfo.modelFee} RON`)}
                            {order.billingInfo.driverFee > 0 && renderIconWithLabel(TruckIcon, 'Șofer', `${order.billingInfo.driverFee} RON`)}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="flex flex-col space-y-1">
                            {renderIconWithLabel(UserCircleIcon, 'Model', `${order.character.firstName} ${order.character.lastName}`)}
                            {renderIconWithLabel(SparklesIcon, 'Tip', order.character.characterType)}
                          </div>
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                        </td>
                      </tr>
                      <tr className={`hover:bg-gray-100 ${highlightIfInvoice(order.notes ?? '')}`} onClick={() => handleRowClick(order)}>
                        <td colSpan={6} className="px-4 py-2 text-sm text-gray-500">
                          <div className="flex items-center space-x-4">
                            <span className={`font-medium ${getStatusColor(order.status)}`}>Status: {order.status}</span>
                            <div className="flex items-center space-x-2">
                              <TruckIcon className="h-4 w-4 text-gray-400" />
                              <span className="text-xs text-gray-500">Șofer:</span>
                              {order.driver ? (
                                <span className="font-medium">
                                  {order.driver.firstName} {order.driver.lastName}
                                  {order.driver.phone && (
                                    <span style={{ color: 'purple' }}> ({order.driver.phone})</span>
                                  )}
                                </span>
                              ) : (
                                <span className="font-medium">Fără șofer</span>
                              )}
                            </div>
                            {order.extraCharacters && order.extraCharacters.length > 0 && (
                              <div className="flex items-center space-x-2">
                                <UserCircleIcon className="h-4 w-4 text-gray-400" />
                                <span className="text-xs text-gray-500">Modele extra:</span>
                                {order.extraCharacters.map((extra, index) => (
                                  <span key={index} className="font-medium text-red-800">
                                    {extra.character.firstName} {extra.character.lastName} - {extra.character.characterType} ({extra.modelFee} RON)
                                    {index < order.extraCharacters.length - 1 ? ', ' : ''}
                                  </span>
                                ))}
                              </div>
                            )}
                            {order.notes && renderIconWithLabel(ChatBubbleLeftEllipsisIcon, 'Mențiuni', order.notes)}
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
            <PrintableOrderTable 
              orders={currentItems}
              hideTotalAmount={hideTotalAmount}
              generatedBy={userProfile?.firstName || 'System'}
            />
        </div>
      )}

      {/* Pagination */}
      <div className="mt-4 flex items-center justify-between">
        <div className="flex items-center">
          <span className="text-sm text-gray-700">
            Afișare {indexOfFirstItem + 1} - {Math.min(indexOfLastItem, filteredOrders.length)} din {filteredOrders.length} rezultate
          </span>
        </div>
        <ModernPagination
          currentPage={currentPage}
          totalPages={Math.ceil(filteredOrders.length / itemsPerPage)}
          onPageChange={paginate}
        />
      </div>
      <OrderDrawer 
        open={isDrawerOpen} 
        setOpen={setIsDrawerOpen} 
        order={selectedOrder} 
        onOrderUpdate={handleOrderUpdate}
      />
    </div>
  );
};

const OrderPage: React.FC = () => {
  return (
    <NotificationProvider>
      <OrderPageContent />
    </NotificationProvider>
  );
};

export default OrderPage;