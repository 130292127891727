import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { getDriverById, Driver, updateDriver, deleteDriver, getDrivers } from '../../services/driverService';
import { useNotification } from '../components/Notification';
import { ChevronLeftIcon, ChevronRightIcon, ClipboardIcon } from '@heroicons/react/20/solid';
import ConfirmDialog from '../../shared/ConfirmDialog'; 

const DriverDetailPage: React.FC = () => {
  const { driverSlug } = useParams<{ driverSlug: string }>();
  const [driver, setDriver] = useState<Driver | null>(null);
  const [editedDriver, setEditedDriver] = useState<Driver | null>(null);
  const [loading, setLoading] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false); // pentru dialogul de confirmare
  const { addNotification } = useNotification();
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchDriver = async () => {
      if (driverSlug) {
        const idMatch = driverSlug.match(/-([a-f0-9]+)$/);
        const driverId = idMatch ? idMatch[1] : null;

        if (driverId) {
          try {
            const fetchedDriver = await getDriverById(driverId);
            setDriver(fetchedDriver);
            setEditedDriver(fetchedDriver);
          } catch (error) {
            console.error('Eroare la obținerea detaliilor șoferului:', error);
            navigate('/dashboard/soferi', { replace: true });
          }
        } else {
          console.error('ID-ul șoferului nu a putut fi extras din URL');
          navigate('/dashboard/soferi', { replace: true });
        }
      }
      setLoading(false);
    };

    fetchDriver();
  }, [driverSlug, navigate]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCopyId = useCallback(() => {
    if (driver?._id) {
      navigator.clipboard.writeText(driver._id)
        .then(() => {
          addNotification('success', 'Copiat', 'ID-ul șoferului a fost copiat în clipboard.');
        })
        .catch(err => {
          console.error('Eroare la copierea ID-ului:', err);
        });
    }
  }, [driver, addNotification]);

  const handleEdit = () => {
    setIsEditing(true);
    setShowDropdown(false);
  };

  const handleSave = useCallback(async () => {
    if (editedDriver && driver?._id) {
      try {
        await updateDriver(driver._id, editedDriver);
        setDriver(editedDriver);
        setIsEditing(false);
        addNotification('success', 'Succes', 'Datele șoferului au fost actualizate cu succes.');
      } catch (error) {
        console.error('Eroare la actualizarea șoferului:', error);
        addNotification('error', 'Eroare', 'Nu s-au putut actualiza datele șoferului. Vă rugăm să încercați din nou.');
      }
    }
  }, [driver, editedDriver, addNotification]);

  const handleCancel = () => {
    setIsEditing(false);
    setEditedDriver(driver);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedDriver(prev => prev ? { ...prev, [name]: value } : null);
  };

  const navigateToAdjacentDriver = async (direction: 'prev' | 'next') => {
    try {
      const drivers = await getDrivers();
      const currentIndex = drivers.findIndex(d => d._id === driver?._id);
      if (currentIndex === -1) return;

      let newIndex;
      if (direction === 'prev') {
        newIndex = currentIndex > 0 ? currentIndex - 1 : drivers.length - 1;
      } else {
        newIndex = currentIndex < drivers.length - 1 ? currentIndex + 1 : 0;
      }

      const newDriver = drivers[newIndex];
      const newSlug = `${newDriver.firstName.toLowerCase()}-${newDriver.lastName.toLowerCase()}-${newDriver._id}`;
      navigate(`/dashboard/soferi/${newSlug}`);
    } catch (error) {
      console.error('Eroare la navigarea între șoferi:', error);
    }
  };

  const handleDeleteDriver = async () => {
    if (driver && driver._id) {
      try {
        await deleteDriver(driver._id);
        addNotification('success', 'Șofer șters', 'Șoferul a fost șters cu succes.');
        navigate('/dashboard/soferi', { replace: true });
      } catch (error) {
        console.error('Eroare la ștergerea șoferului:', error);
        addNotification('error', 'Eroare', 'Nu s-a putut șterge șoferul. Vă rugăm să încercați din nou.');
      }
    }
  };

  const renderField = (label: string, value: string, name: string) => (
    <div className="sm:col-span-1">
      <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">
        {label}
      </label>
      <div className="mt-2">
        {isEditing ? (
          <input
            type="text"
            id={name}
            name={name}
            value={value}
            onChange={handleInputChange}
            className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        ) : (
          <p className="mt-1 text-sm text-gray-900 py-1.5">{value}</p>
        )}
      </div>
    </div>
  );

  if (loading) return <div>Se încarcă...</div>;
  if (!driver || !editedDriver) return <div>Șoferul nu a fost găsit.</div>;

  return (
    <div>
      <div className="sm:flex sm:items-center sm:justify-between">
        <div className="sm:flex sm:items-center">
          <Link to="/dashboard/soferi" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
            ← Înapoi la lista de șoferi
          </Link>
        </div>
        <div className="mt-4 flex items-center justify-between sm:mt-0 sm:ml-6 sm:flex-shrink-0">
          <div className="flex items-center space-x-3">
            <button onClick={() => navigateToAdjacentDriver('prev')} className="inline-flex items-center rounded-full border border-gray-300 bg-white p-1 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button onClick={() => navigateToAdjacentDriver('next')} className="inline-flex items-center rounded-full border border-gray-300 bg-white p-1 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="relative ml-3" ref={dropdownRef}>
            <button
              type="button"
              onClick={() => setShowDropdown(!showDropdown)}
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Acțiuni
            </button>
            {showDropdown && (
              <div className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <a href="#" className="block px-4 py-2 text-sm text-gray-700" onClick={handleEdit}>Editare</a>
                <a href="#" className="block px-4 py-2 text-sm text-gray-700" onClick={() => setIsConfirmDialogOpen(true)}>Ștergere</a>
              </div>
            )}
          </div>
        </div>
      </div>

      {isEditing && (
        <div className="mt-4 rounded-md bg-yellow-50 p-4 border border-yellow-200 shadow-sm">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">Atenție</h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>Ești în modul de editare. Modificările nu vor fi salvate până când nu apeși butonul "Salvează".</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="mt-6 border-t border-gray-100 pt-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">ID Șofer</dt>
            <dd className="mt-1 flex items-center text-sm text-gray-900">
              <span>{driver._id}</span>
            </dd>
          </div>
          {renderField('Prenume', editedDriver.firstName, 'firstName')}
          {renderField('Nume', editedDriver.lastName, 'lastName')}
          {renderField('Email', editedDriver.email, 'email')}
          {renderField('Telefon', editedDriver.phone, 'phone')}
        </dl>
      </div>

      {isEditing && (
        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={handleCancel}
            className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Anulează
          </button>
          <button
            onClick={handleSave}
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Salvează
          </button>
        </div>
      )}

      <div className="mt-8 border-t border-gray-200 pt-6">
        <h2 className="text-base font-semibold leading-7 text-gray-900">Curse recente</h2>
        <div className="mt-4 rounded-md bg-gray-50 p-4 border border-gray-200">
          <p className="text-sm text-gray-500">Aici va fi afișat tabelul cu cursele recente ale șoferului.</p>
        </div>
      </div>

      <ConfirmDialog
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        onConfirm={handleDeleteDriver}
        title="Confirmare Ștergere"
        description="Ești sigur că vrei să ștergi acest șofer? Această acțiune nu poate fi anulată."
        confirmText="Șterge"
        cancelText="Anulează"
      />
    </div>
  );
};

export default DriverDetailPage;