import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

type UserRole = 'Super Admin' | 'Admin' | 'Moderator';

interface User {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
}

interface UseAuth {
  isAuthenticated: boolean;
  userType: 'admin' | 'character' | null;
  user: User | null;
  login: (email: string, password: string) => Promise<void>;
  characterLogin: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  checkAuthStatus: () => void;
  refreshAccessToken: () => Promise<string>;
  hasRole: (roles: UserRole[]) => boolean;
  canEditUsers: () => boolean;
  canDeleteUsers: () => boolean;
  canAddUsers: () => boolean;
  canAddOrders: () => boolean;
  canEditOrders: () => boolean;
  canManageOrders: () => boolean;
  canCancelOrders: () => boolean;
  isSuperAdmin: () => boolean;
}

export const useAuth = (): UseAuth => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userType, setUserType] = useState<'admin' | 'character' | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();

  const fetchUserProfile = async () => {
    try {
      const token = localStorage.getItem('accessToken');
      if (!token) return null;

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/profile`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      setUser(response.data);
      return response.data;
    } catch (error) {
      console.error('Eroare la obținerea profilului:', error);
      return null;
    }
  };

  const checkAuthStatus = useCallback(async () => {
    const token = localStorage.getItem('accessToken');
    const type = localStorage.getItem('userType') as 'admin' | 'character' | null;
    
    setIsAuthenticated(!!token);
    setUserType(type);

    if (token && type === 'admin') {
      await fetchUserProfile();
    }
  }, []);

  useEffect(() => {
    checkAuthStatus();

    const handleStorageChange = () => {
      checkAuthStatus();
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [checkAuthStatus]);

  const login = async (email: string, password: string) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, { email, password });
      const { accessToken, refreshToken } = response.data;
      
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('userType', 'admin');

      await checkAuthStatus();
      navigate('/dashboard');
    } catch (error) {
      console.error('useAuth: Eroare la autentificare:', error);
      throw error;
    }
  };

  const characterLogin = async (email: string, password: string) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/character/login`, { email, password });
      const { accessToken, refreshToken } = response.data;
      
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('userType', 'character');

      await checkAuthStatus();
      navigate('/panou/comenzile-mele');
    } catch (error) {
      console.error('useAuth: Eroare la autentificare caracter:', error);
      throw error;
    }
  };

  const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    const type = localStorage.getItem('userType');
    
    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    try {
      const endpoint = type === 'character' ? '/api/auth/character/refresh' : '/api/auth/refresh';
      const response = await axios.post(`${process.env.REACT_APP_API_URL}${endpoint}`, { refreshToken });
      const { accessToken: newAccessToken, refreshToken: newRefreshToken } = response.data;
      
      localStorage.setItem('accessToken', newAccessToken);
      localStorage.setItem('refreshToken', newRefreshToken);
      
      return newAccessToken;
    } catch (error) {
      console.error('Eroare la reînnoirea token-ului:', error);
      await logout();
      throw error;
    }
  };

  const logout = async () => {
    try {
      const type = localStorage.getItem('userType');
      const endpoint = type === 'character' ? '/api/auth/character/logout' : '/api/auth/logout';
      
      await axios.post(`${process.env.REACT_APP_API_URL}${endpoint}`);
    } catch (error) {
      console.error('Eroare la logout:', error);
    } finally {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('userType');
      setUser(null);
      checkAuthStatus();
      navigate('/login');
    }
  };

  const hasRole = (roles: UserRole[]) => {
    return user ? roles.includes(user.role) : false;
  };

  const canEditUsers = () => {
    return hasRole(['Super Admin', 'Admin']);
  };

  const canDeleteUsers = () => {
    return hasRole(['Super Admin', 'Admin']);
  };

  const canAddUsers = () => {
    return hasRole(['Super Admin', 'Admin']);
  };

  const canAddOrders = () => {
    return hasRole(['Super Admin', 'Admin']);
  };

  const canEditOrders = () => {
    return hasRole(['Super Admin', 'Admin']);
  }

  const canCancelOrders = () => {
    return hasRole(['Super Admin', 'Admin']);
  }

  const canManageOrders = () => {
    return hasRole(['Super Admin', 'Admin', 'Moderator']);
  };

  const isSuperAdmin = () => {
    return hasRole(['Super Admin']);
  };

  return {
    isAuthenticated,
    userType,
    user,
    login,
    characterLogin,
    logout,
    checkAuthStatus,
    refreshAccessToken,
    hasRole,
    canEditUsers,
    canDeleteUsers,
    canAddUsers,
    canEditOrders,
    canAddOrders,
    canCancelOrders,
    canManageOrders,
    isSuperAdmin
  };
};