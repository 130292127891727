import React from 'react';
import { Character } from '../../services/characterService';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

interface ModelDetailTableProps {
  character: Character;
  isEditing: boolean;
  onUpdateDetail: (key: string, value: string | number | string[]) => void;
}

const statusOptions = [
  { id: 'active', name: 'Activ' },
  { id: 'inactive', name: 'Inactiv' },
];

const ModelDetailTable: React.FC<ModelDetailTableProps> = ({ character, isEditing, onUpdateDetail }) => {
  const renderField = (label: string, value: string | number, name: string) => (
    <div className="sm:col-span-1">
      <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900 mb-2">
        {label}
      </label>
      <div className="mt-2">
        {isEditing ? (
          <input
            type="text"
            id={name}
            name={name}
            value={value}
            onChange={(e) => onUpdateDetail(name, e.target.value)}
            className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        ) : (
          <p className="mt-1 text-sm text-gray-900 py-1.5">{value || 'Nespecificat'}</p>
        )}
      </div>
    </div>
  );

  const renderStatusField = () => (
    <div className="sm:col-span-1">
      <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">
        Status Profil
      </label>
      <div className="mt-2 relative">
        {isEditing ? (
          <Listbox value={statusOptions.find(option => option.id === character.profileStatus)} onChange={(selected) => onUpdateDetail('profileStatus', selected.id)}>
            <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate">{statusOptions.find(option => option.id === character.profileStatus)?.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
              </span>
            </ListboxButton>
            <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {statusOptions.map((option) => (
                <ListboxOption
                  key={option.id}
                  value={option}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-3 pr-9 ${
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                    }`
                  }
                >
                  {({ selected, active }) => (
                    <>
                      <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>
                        {option.name}
                      </span>
                      {selected ? (
                        <span className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                          active ? 'text-white' : 'text-indigo-600'
                        }`}>
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Listbox>
        ) : (
          <p className="mt-1 text-sm text-gray-900 py-1.5">
            {statusOptions.find(option => option.id === character.profileStatus)?.name || 'Nespecificat'}
          </p>
        )}
      </div>
    </div>
  );

  const formatDate = (date: Date | string) => {
    const d = new Date(date);
    return `${d.getDate().toString().padStart(2, '0')}.${(d.getMonth() + 1).toString().padStart(2, '0')}.${d.getFullYear()}`;
  };

  const calculateAge = (birthDate: Date | string) => {
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const m = today.getMonth() - birthDateObj.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDateObj.getDate())) {
      age--;
    }
    return age;
  };

  const renderBirthDateField = () => (
    <div className="sm:col-span-1">
      <label htmlFor="birthDate" className="block text-sm font-medium leading-6 text-gray-900 mb-2">
        Data Nașterii
      </label>
      <div className="mt-2">
        {isEditing ? (
          <input
            type="date"
            id="birthDate"
            name="birthDate"
            value={new Date(character.birthDate).toISOString().split('T')[0]}
            onChange={(e) => onUpdateDetail('birthDate', e.target.value)}
            className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        ) : (
          <p className="mt-1 text-sm text-gray-900 py-1.5">
            {`${formatDate(character.birthDate)} (${calculateAge(character.birthDate)} ani)`}
          </p>
        )}
      </div>
    </div>
  );

  const renderLanguagesField = () => {
    const languagesValue = Array.isArray(character.languages) 
      ? character.languages.join(', ')
      : character.languages || '';

    return (
      <div className="sm:col-span-1">
        <label htmlFor="languages" className="block text-sm font-medium leading-6 text-gray-900 mb-2">
          Limbi Vorbite
        </label>
        <div className="mt-2">
          {isEditing ? (
            <input
              type="text"
              id="languages"
              name="languages"
              value={languagesValue}
              onChange={(e) => {
                const newValue = e.target.value;
                onUpdateDetail('languages', newValue.split(',').map(lang => lang.trim()));
              }}
              className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          ) : (
            <p className="mt-1 text-sm text-gray-900 py-1.5">{languagesValue || 'Nespecificat'}</p>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="mt-6 border-t border-gray-100 pt-6">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        {renderField('Nume', character.lastName, 'lastName')}
        {renderField('Prenume', character.firstName, 'firstName')}
        {renderField('Telefon 1', character.phone1, 'phone1')}
        {renderField('Telefon 2', character.phone2 || '', 'phone2')}
        {renderField('CNP', character.cnp, 'cnp')}
        {renderField('Adresă', character.address, 'address')}
        {renderField('IBAN', character.iban, 'iban')}
        {renderField('Număr Contract', character.contractNumber, 'contractNumber')}
        {renderField('Culoare Ochi', character.eyeColor, 'eyeColor')}
        {renderField('Experiență (ani)', character.experience, 'experience')}
        {renderBirthDateField()}
        {renderLanguagesField()}
        {renderStatusField()}
        {renderField('Tip Caracter', character.characterType, 'characterType')}
      </dl>
    </div>
  );
};

export default ModelDetailTable;