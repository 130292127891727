import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

const CampaignHero = () => {
  useEffect(() => {
    const svgElement = document.querySelector('#campaign-svg path') as SVGPathElement | null;
    
    if (svgElement) {
      const totalLength = svgElement.getTotalLength();
      svgElement.style.strokeDasharray = totalLength.toString();
      svgElement.style.strokeDashoffset = totalLength.toString();

      const animateSVG = () => {
        svgElement.style.transition = 'stroke-dashoffset 2s ease-in-out';
        svgElement.style.strokeDashoffset = '0';
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setTimeout(animateSVG, 500);
            observer.disconnect();
          }
        });
      }, { threshold: 0.1 });

      observer.observe(svgElement);

      return () => observer.disconnect();
    }
  }, []);

  return (
    <div className="relative bg-white">
      <div className="relative z-10 mx-auto max-w-[90rem] px-4 sm:px-6 lg:px-8">
        <div className="relative pt-16 pb-16 text-center lg:pt-20">
          {/* Santa on Sled Animation - Left */}
          <motion.div
            className="absolute -left-8 top-40 hidden w-36 lg:block lg:w-48" // Modified from -left-20 to -left-8
            initial={{ x: -200, y: 50, rotate: -5 }}
            animate={{ 
              x: 0,
              y: [0, -20, 0],
              rotate: 0
            }}
            transition={{
              x: { type: "spring", stiffness: 50, damping: 20, duration: 2 },
              y: { repeat: Infinity, duration: 4, ease: "easeInOut" }
            }}
            whileHover={{ scale: 1.05 }}
          >
            <img
              src="/santa standing on sled.webp"
              alt="Santa on Sled"
              className="w-full h-auto"
            />
          </motion.div>

          {/* Santa with Christmas Tree - Right */}
          <motion.div
            className="absolute -right-8 top-40 hidden w-36 lg:block lg:w-48" // Modified from -right-20 to -right-8
            initial={{ x: 200, opacity: 0 }}
            animate={{ 
              x: 0,
              opacity: 1,
              y: [0, -15, 0]
            }}
            transition={{
              x: { type: "spring", stiffness: 50, damping: 20, duration: 2 },
              opacity: { duration: 1 },
              y: { repeat: Infinity, duration: 4, ease: "easeInOut", delay: 1 }
            }}
            whileHover={{ scale: 1.05 }}
          >
            <img
              src="/santa with Christmas tree.webp"
              alt="Santa with Christmas Tree"
              className="w-full h-auto"
            />
          </motion.div>

          <h1 className="mx-auto max-w-5xl font-display text-4xl font-medium tracking-tight text-slate-900 sm:text-6xl md:text-7xl">
            Pentru toți cei care{' '}
            <span className="relative whitespace-nowrap text-[#036039]">
              <svg
                id="campaign-svg"
                aria-hidden="true"
                viewBox="0 0 418 42"
                className="absolute left-0 top-2/3 h-[0.58em] w-full fill-none stroke-[#036039] stroke-2"
                preserveAspectRatio="none"
              >
                <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
              </svg>
              <span className="relative">așteaptă să simtă</span>
            </span>{' '}
            Crăciunul{' '}
            <motion.span 
              className="inline-block"
              animate={{ rotate: [0, 10, 0] }}
              transition={{ 
                repeat: Infinity,
                duration: 2,
                ease: "easeInOut"
              }}
            >
              ✨
            </motion.span>
          </h1>
          
          <p className="mx-auto mt-6 max-w-3xl text-lg tracking-tight text-slate-700">
            Anul acesta, Moș Crăciun poate aduce mai mult decât bucurie {' '}
            <span className="text-[#c41e3a] font-semibold">10% din profitul fiecărei comenzi</span>{' '}
            va merge către familii pentru care Crăciunul e doar un vis de speranță. 
            Ajută-ne să aducem căldură, acolo unde e cel mai dorită. Fii alături de noi în magia care schimbă vieți.
          </p>

          <div className="mt-12 flex justify-center items-center gap-x-6">
            <a
              className="group inline-flex items-center justify-center rounded-full py-3 px-6 text-base font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-[#036039] text-white hover:bg-[#004c2a] hover:text-slate-100 active:bg-[#03552e] active:text-slate-300 focus-visible:outline-[#036039] transition-all duration-200 ease-in-out transform hover:scale-105"
              href="/contact"
            >
              Hai să facem echipă
            </a>
            <a
              className="inline-flex items-center text-base font-semibold text-slate-700 hover:text-[#036039] transition-colors duration-200"
              href="/detalii"
            >
              Află mai multe detalii
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignHero;