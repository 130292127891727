import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { getCustomerById, Customer, updateCustomer, deleteCustomer, getCustomers } from '../../services/customerService';
import { useNotification } from '../components/Notification';
import { ChevronLeftIcon, ChevronRightIcon, ClipboardIcon, CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Listbox } from '@headlessui/react';
import ConfirmDialog from '../../shared/ConfirmDialog';

const customerTypes = [
  { id: 'persoana fizica', name: 'Persoană fizică' },
  { id: 'persoana juridica', name: 'Persoană juridică' },
  { id: 'gradinita/scoala', name: 'Grădiniță/Școală' },
  { id: 'loc de joaca', name: 'Loc de joacă' },
] as const;

const CustomerDetailPage: React.FC = () => {
  const { clientSlug } = useParams<{ clientSlug: string }>();
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [editedCustomer, setEditedCustomer] = useState<Customer | null>(null);
  const [loading, setLoading] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const { addNotification } = useNotification();
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchCustomer = async () => {
      if (clientSlug) {
        const idMatch = clientSlug.match(/-([a-f0-9]+)$/);
        const customerId = idMatch ? idMatch[1] : null;
        
        if (customerId) {
          try {
            const fetchedCustomer = await getCustomerById(customerId);
            setCustomer(fetchedCustomer);
            setEditedCustomer(fetchedCustomer);
          } catch (error) {
            console.error('Eroare la obținerea detaliilor clientului:', error);
            addNotification('error', 'Eroare', 'Nu s-au putut încărca detaliile clientului.');
            navigate('/dashboard/clienti', { replace: true });
          }
        } else {
          console.error('ID-ul clientului nu a putut fi extras din URL');
          addNotification('error', 'Eroare', 'ID-ul clientului este invalid.');
          navigate('/dashboard/clienti', { replace: true });
        }
      }
      setLoading(false);
    };

    fetchCustomer();
  }, [clientSlug, addNotification, navigate]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCopyId = () => {
    if (customer?._id) {
      navigator.clipboard.writeText(customer._id)
        .then(() => {
          addNotification('success', 'Copiat', 'ID-ul clientului a fost copiat în clipboard.');
        })
        .catch(err => {
          console.error('Eroare la copierea ID-ului:', err);
          addNotification('error', 'Eroare', 'Nu s-a putut copia ID-ul clientului.');
        });
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
    setShowDropdown(false);
  };

  const handleSave = async () => {
    if (editedCustomer && customer?._id) {
      try {
        await updateCustomer(customer._id, editedCustomer);
        setCustomer(editedCustomer);
        setIsEditing(false);
        addNotification('success', 'Succes', 'Datele clientului au fost actualizate.');
      } catch (error) {
        console.error('Eroare la actualizarea clientului:', error);
        addNotification('error', 'Eroare', 'Nu s-au putut actualiza datele clientului.');
      }
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedCustomer(customer);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setEditedCustomer(prev => prev ? { ...prev, [name]: value } : null);
  };

  const handleCustomerTypeChange = (selectedType: typeof customerTypes[number]) => {
    setEditedCustomer(prev => prev ? { ...prev, customerType: selectedType.id } : null);
  };

  const handleDelete = async () => {
    if (customer?._id) {
      try {
        await deleteCustomer(customer._id);
        addNotification('success', 'Succes', 'Clientul a fost șters cu succes.');
        navigate('/dashboard/clienti', { replace: true });
      } catch (error) {
        console.error('Eroare la ștergerea clientului:', error);
        addNotification('error', 'Eroare', 'Nu s-a putut șterge clientul.');
      }
    }
  };

  const navigateToAdjacentCustomer = async (direction: 'prev' | 'next') => {
    try {
      const customers = await getCustomers();
      const currentIndex = customers.findIndex(c => c._id === customer?._id);
      if (currentIndex === -1) return;

      let newIndex;
      if (direction === 'prev') {
        newIndex = currentIndex > 0 ? currentIndex - 1 : customers.length - 1;
      } else {
        newIndex = currentIndex < customers.length - 1 ? currentIndex + 1 : 0;
      }

      const newCustomer = customers[newIndex];
      const newSlug = `${newCustomer.name?.toLowerCase().replace(/\s+/g, '-') || 'client'}-${newCustomer._id}`;
      navigate(`/dashboard/clienti/${newSlug}`);
    } catch (error) {
      console.error('Eroare la navigarea între clienți:', error);
      addNotification('error', 'Eroare', 'Nu s-a putut naviga la clientul adiacent.');
    }
  };

  const renderField = (label: string, value: string | undefined, name: string) => (
    <div className="sm:col-span-1">
      <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900 mb-2">
        {label}
      </label>
      <div className="mt-2">
        {isEditing ? (
          <input
            type="text"
            id={name}
            name={name}
            value={value || ''}
            onChange={handleInputChange}
            className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        ) : (
          <p className="mt-1 text-sm text-gray-900 py-1.5">{value || 'N/A'}</p>
        )}
      </div>
    </div>
  );

  if (loading) return <div>Se încarcă...</div>;
  if (!customer || !editedCustomer) return <div>Clientul nu a fost găsit.</div>;

  return (
    <div>
      <div className="sm:flex sm:items-center sm:justify-between">
        <div className="sm:flex sm:items-center">
          <Link to="/dashboard/clienti" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
            ← Înapoi la lista de clienți
          </Link>
        </div>
        <div className="mt-4 flex items-center justify-between sm:mt-0 sm:ml-6 sm:flex-shrink-0">
          <div className="flex items-center space-x-3">
            <button onClick={() => navigateToAdjacentCustomer('prev')} className="inline-flex items-center rounded-full border border-gray-300 bg-white p-1 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button onClick={() => navigateToAdjacentCustomer('next')} className="inline-flex items-center rounded-full border border-gray-300 bg-white p-1 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="relative ml-3" ref={dropdownRef}>
            <button
              type="button"
              onClick={() => setShowDropdown(!showDropdown)}
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Acțiuni
            </button>
            {showDropdown && (
              <div className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <a href="#" className="block px-4 py-2 text-sm text-gray-700" onClick={handleEdit}>Editare</a>
                <a href="#" className="block px-4 py-2 text-sm text-gray-700" onClick={() => setShowDeleteConfirm(true)}>Ștergere</a>
              </div>
            )}
          </div>
        </div>
      </div>

      {isEditing && (
        <div className="mt-4 rounded-md bg-yellow-50 p-4 border border-yellow-200">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">Atenție</h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>Ești în modul de editare. Modificările nu vor fi salvate până când nu apeși butonul "Salvează".</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="mt-6 border-t border-gray-100 pt-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">ID Client</dt>
            <dd className="mt-1 flex items-center text-sm text-gray-900">
              <span>{customer._id}</span>
            </dd>
          </div>
          {renderField('Nume', editedCustomer.name, 'name')}
          <div className="sm:col-span-1">
            <label htmlFor="customerType" className="block text-sm font-medium leading-6 text-gray-900 mb-2">
              Tip client
            </label>
            <div className="mt-2">
              {isEditing ? (
                <Listbox value={customerTypes.find(type => type.id === editedCustomer.customerType)} onChange={handleCustomerTypeChange}>
                  <div className="relative">
                    <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                      <span className="block truncate">{customerTypes.find(type => type.id === editedCustomer.customerType)?.name}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </span>
                    </Listbox.Button>
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {customerTypes.map((type) => (
                        <Listbox.Option
                          key={type.id}
                          value={type}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-3 pr-9 ${
                              active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                            }`
                          }
                        >
                          {({ selected, active }) => (
                            <>
                              <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>
                                {type.name}
                              </span>
                              {selected ? (
                                <span
                                  className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                                    active ? 'text-white' : 'text-indigo-600'
                                  }`}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </div>
                </Listbox>
              ) : (
                <p className="mt-1 text-sm text-gray-900 py-1.5">{customerTypes.find(type => type.id === customer.customerType)?.name}</p>
              )}
            </div>
          </div>
          {renderField('Email', editedCustomer.email, 'email')}
          {renderField('Telefon', editedCustomer.phone, 'phone')}
          {renderField('Adresă', editedCustomer.address, 'address')}
          {renderField('Zona', editedCustomer.zona || '', 'zona')}
          <div className="sm:col-span-2">
            <label htmlFor="mentiuni" className="block text-sm font-medium leading-6 text-gray-900 mb-2">
              Mențiuni
            </label>
            <div className="mt-2">
              {isEditing ? (
                <textarea
                  id="mentiuni"
                  name="mentiuni"
                  rows={4}
                  value={editedCustomer.mentiuni || ''}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Adăugați mențiuni aici..."
                />
              ) : (
                <div className="mt-1 text-sm text-gray-900 bg-gray-50 rounded-md p-3 border border-gray-200">
                  {customer.mentiuni || 'Nu există mențiuni pentru acest client.'}
                </div>
              )}
            </div>
          </div>
        </dl>
      </div>

      {isEditing && (
        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={handleCancel}
            className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Anulează
          </button>
          <button
            onClick={handleSave}
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Salvează
          </button>
        </div>
      )}

      <div className="mt-8 border-t border-gray-200 pt-6">
        <h2 className="text-base font-semibold leading-7 text-gray-900">Comenzi recente</h2>
        <div className="mt-4 rounded-md bg-gray-50 p-4 border border-gray-200">
          <p className="text-sm text-gray-500">Aici va fi afișat tabelul cu comenzile recente ale clientului.</p>
        </div>
      </div>

      <ConfirmDialog
        open={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        onConfirm={handleDelete}
        title="Confirmare ștergere client"
        description="Sunteți sigur că doriți să ștergeți acest client? Această acțiune nu poate fi anulată."
        confirmText="Șterge"
        cancelText="Anulează"
      />
    </div>
  );
};

export default CustomerDetailPage;