import React from 'react';

const faqs = [
  {
    id: 1,
    question: "Cum sprijină această campanie familiile în nevoie?",
    answer: "Pentru fiecare rezervare de Moș Crăciun, donăm 10% din profit către organizații care oferă ajutor familiilor vulnerabile. Astfel, fiecare vizită a Moșului contribuie la bucuria celor care așteaptă un Crăciun mai bun."
  },
  {
    id: 2,
    question: "De ce am ales să donăm 10% din profit?",
    answer: "Credem că magia sărbătorilor trebuie să ajungă la toți. Prin această donație, dorim să oferim speranță și sprijin celor mai puțin norocoși, transformând fiecare eveniment într-un moment de bine pentru comunitate."
  },
  {
    id: 3,
    question: "Cum pot fi sigur că donațiile ajung la cei care au nevoie?",
    answer: "Colaborăm cu organizații de încredere și transparente, iar fiecare donație este gestionată cu responsabilitate. Vom oferi rapoarte despre impactul campaniei și modul în care fondurile sunt folosite."
  },
  {
    id: 4,
    question: "Pot contribui mai mult decât prin rezervarea de Moș Crăciun?",
    answer: "Da! Dacă dorești să sprijini suplimentar, ne poți contacta, iar donațiile suplimentare vor fi direcționate direct către partenerii noștri pentru a maximiza impactul în viețile celor care au cea mai mare nevoie de ajutor."
  }
];

const CampaignFAQ = () => {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-4xl font-display tracking-tight text-gray-900 sm:text-5xl">
            Întrebări Frecvente
          </h2>
          <p className="mt-6 text-base/7 text-gray-600">
            Ai alte întrebări? Contactează-ne prin{' '}
            <a href="/contact" className="font-semibold text-[#c41e3a] hover:text-[#a01830]">
              formular
            </a>{' '}
            și îți vom răspunde cât de curând posibil.
          </p>
        </div>
        <div className="mt-20">
          <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10">
            {faqs.map((faq) => (
              <div key={faq.id}>
                <dt className="text-base/7 font-semibold text-gray-900">{faq.question}</dt>
                <dd className="mt-2 text-base/7 text-gray-600">{faq.answer}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default CampaignFAQ;