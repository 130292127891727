import React, { useState, useEffect, useRef } from 'react';
import '../styles/cards.css';
import CharacterCarousel from './CharacterCarousel';
import { getPublicCharacters, PublicCharacter } from '../../services/publicCharacterService';

interface CharacterCardProps {
  character: PublicCharacter;
  onClick: () => void;
}

const CharacterCard: React.FC<CharacterCardProps> = ({ character, onClick }) => {
  const getBackgroundImage = (imagePath: string) => {
    if (!imagePath) return 'none';
    const encodedPath = encodeURI(imagePath);
    return `url("${encodedPath}")`;
  };

  return (
    <div
      className="w-64 h-80 flex-shrink-0 overflow-hidden relative cursor-pointer character-card"
      onClick={onClick}
    >
      <div
        className="w-full h-full rounded-dribbble bg-cover bg-top"
        style={{
          backgroundImage: getBackgroundImage(character.mainImage),
        }}
      />
      <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-60 rounded-dribbble"></div>
      <div className="absolute bottom-0 left-0 right-0 p-4">
        <h3 className="text-white font-semibold text-lg line-clamp-2">{character.name}</h3>
      </div>
      {!character.mainImage && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-200 rounded-dribbble">
          <span className="text-gray-500 text-sm">No Image</span>
        </div>
      )}
    </div>
  );
};

const CharacterCards: React.FC = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedCharacterIndex, setSelectedCharacterIndex] = useState<number | null>(null);
  const [characters, setCharacters] = useState<PublicCharacter[]>([]);
  const [isMarqueeEnabled, setIsMarqueeEnabled] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchCharacters = async () => {
      try {
        const fetchedCharacters = await getPublicCharacters();
        setCharacters(fetchedCharacters);
      } catch (error) {
        console.error('Eroare la încărcarea personajelor:', error);
      }
    };

    fetchCharacters();
  }, []);

  useEffect(() => {
    const checkMarquee = () => {
      if (containerRef.current) {
        const { scrollWidth, clientWidth } = containerRef.current;
        setIsMarqueeEnabled(scrollWidth > clientWidth);
      }
    };

    checkMarquee();
    window.addEventListener('resize', checkMarquee);
    return () => window.removeEventListener('resize', checkMarquee);
  }, [characters]);

  useEffect(() => {
    document.body.style.overflow = showOverlay ? 'hidden' : '';
    return () => {
      document.body.style.overflow = '';
    };
  }, [showOverlay]);

  const handleCardClick = (index: number) => {
    if (characters[index].mainImage) {
      setSelectedCharacterIndex(index);
      setShowOverlay(true);
    }
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false);
    setSelectedCharacterIndex(null);
  };

  return (
    <>
      <div className="w-full overflow-x-auto character-cards-container" ref={containerRef}>
        <div className={`inline-flex space-x-6 py-4 ${isMarqueeEnabled ? 'animate-marquee-horizontal' : ''}`}>
          {characters.map((character, index) => (
            <CharacterCard
              key={`${character._id}-${index}`}
              character={character}
              onClick={() => handleCardClick(index)}
            />
          ))}
        </div>
      </div>
      {showOverlay && selectedCharacterIndex !== null && (
        <div className="fixed inset-0 z-50">
          <CharacterCarousel
            characters={characters}
            initialCharacterIndex={selectedCharacterIndex}
            onClose={handleCloseOverlay}
          />
        </div>
      )}
    </>
  );
};

export default CharacterCards;