import React from 'react';

export default function Example() {
  return (
    <div className="bg-white py-16 sm:pb-24 sm:pt-0 md:py-24 md:pt-0">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="mt-2 max-w-4xl text-pretty text-4xl font-medium tracking-tight text-gray-950 sm:text-5xl mx-auto">
            Experiență autentică de Crăciun
          </p>
          <p className="mt-6 text-lg text-gray-600 mx-auto max-w-2xl">
            Descoperă cum aducem magia sărbătorilor în viața ta prin intermediul celui mai autentic Moș Crăciun.
          </p>
        </div>
        
        <div className="mt-10 sm:mt-16">
          <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
            <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
              <div className="lg:px-0 lg:pr-4 lg:pt-4">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                  <h2 className="text-base font-semibold leading-7 text-red-600">Tradiție și Autenticitate</h2>
                  <p className="mt-2 text-3xl font-bold text-pretty tracking-tight text-gray-900 sm:text-4xl">Moș Crăciun autentic, direct de la Polul Nord</p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    Cu o experiență de peste 15 ani în selectarea actorilor și personajelor pentru reclame, filme și producții artistice (agentiadecasting.ro), am dus acest know-how într-un nou univers: sărbătorile de iarnă.
                  </p>
                  <p className="mt-4 text-lg leading-8 text-gray-600">
                    Moș Crăciunul nostru nu este doar un simplu actor – este un ambasador autentic al Crăciunului, parcă sosit direct din Casa lui Moș Crăciun de la Polul Nord, cu barba naturală îngrijită perfect și un zâmbet cald care înseninează fiecare vizită.
                  </p>
                  <p className="mt-4 text-lg leading-8 text-gray-600">
                    Fiecare detaliu, de la îmbrăcămintea impecabilă până la modul în care interacționează cu cei mici, este atent pregătit pentru a oferi o experiență de neuitat.
                  </p>
                </div>
              </div>
              <div className="sm:px-6 lg:px-0">
                <div className="relative overflow-hidden rounded-lg">
                  <img
                    alt="Moș Crăciun autentic"
                    src="/about_us_santa.png"
                    className="w-full h-auto object-cover rounded-lg shadow-lg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}