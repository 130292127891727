import axios from 'axios';

export const API_URL = process.env.REACT_APP_API_URL || 'https://localhost:5000';

export interface Customer {
  _id: string;
  customerType?: 'persoana fizica' | 'persoana juridica' | 'gradinita/scoala' | 'loc de joaca';
  name?: string;
  phone: string;
  email?: string;
  address?: string;
  zona?: string;
  mentiuni?: string;
  createdAt?: Date | string;
  updatedAt?: Date | string;
}

const getAuthHeaders = () => {
  const token = localStorage.getItem('accessToken');
  return {
    Authorization: `Bearer ${token}`,
  };
};

export const getCustomers = async (): Promise<Customer[]> => {
  try {
    const response = await axios.get<Customer[]>(`${API_URL}/api/customers`, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error('Eroare la obținerea clienților', error);
    throw error;
  }
};

export const addCustomer = async (customerData: Partial<Customer>): Promise<Customer> => {
  try {
    const response = await axios.post<Customer>(`${API_URL}/api/customers`, customerData, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error('Eroare la adăugarea clientului', error);
    throw error;
  }
};

export const getCustomerById = async (id: string): Promise<Customer> => {
  try {
    const response = await axios.get<Customer>(`${API_URL}/api/customers/${id}`, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error('Eroare la obținerea detaliilor clientului', error);
    throw error;
  }
};

export const updateCustomer = async (id: string, updateData: Partial<Customer>): Promise<Customer> => {
  try {
    const response = await axios.put<Customer>(`${API_URL}/api/customers/${id}`, updateData, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error('Eroare la actualizarea clientului', error);
    throw error;
  }
};

export const deleteCustomer = async (id: string): Promise<void> => {
  try {
    await axios.delete(`${API_URL}/api/customers/${id}`, {
      headers: getAuthHeaders(),
    });
  } catch (error) {
    console.error('Eroare la ștergerea clientului', error);
    throw error;
  }
};

export const checkCustomerExists = async (phone: string): Promise<Customer | null> => {
  try {
    const response = await axios.get<Customer>(`${API_URL}/api/customers/check`, {
      params: { phone },
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      return null;
    }
    console.error('Eroare la verificarea existenței clientului', error);
    throw error;
  }
};