import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { jwtDecode } from 'jwt-decode';

const API_URL = process.env.REACT_APP_API_URL;
const REFRESH_ENDPOINT = '/api/auth/refresh';

interface AxiosRequestConfigCustom extends AxiosRequestConfig {
  _retry?: boolean;
}

interface DecodedToken {
  exp: number;
}

const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

let isRefreshing = false;
let failedQueue: Array<{
  resolve: (value?: unknown) => void;
  reject: (reason?: any) => void;
}> = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

// Function to check if token needs refresh
const shouldRefreshToken = () => {
  const token = localStorage.getItem('accessToken');
  if (!token) return false;

  try {
    const decodedToken = jwtDecode<DecodedToken>(token);
    const expiryTime = decodedToken.exp * 1000;
    // Refresh if token expires in less than 1 minute
    return Date.now() >= expiryTime - 60000;
  } catch {
    return false;
  }
};

apiClient.interceptors.request.use(
  async (config) => {
    if (shouldRefreshToken() && !config.url?.includes(REFRESH_ENDPOINT)) {
      try {
        const refreshToken = localStorage.getItem('refreshToken');
        const type = localStorage.getItem('userType');
        const endpoint = type === 'character' ? '/api/auth/character/refresh' : '/api/auth/refresh';
        
        const response = await axios.post(`${API_URL}${endpoint}`, { refreshToken });
        const { accessToken: newAccessToken, refreshToken: newRefreshToken } = response.data;
        
        localStorage.setItem('accessToken', newAccessToken);
        localStorage.setItem('refreshToken', newRefreshToken);
        
        config.headers.Authorization = `Bearer ${newAccessToken}`;
      } catch (error) {
        console.error('Error refreshing token:', error);
      }
    } else {
      const token = localStorage.getItem('accessToken');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as AxiosRequestConfigCustom;
    
    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            if (originalRequest.headers) {
              originalRequest.headers.Authorization = `Bearer ${token}`;
            }
            return apiClient(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const refreshToken = localStorage.getItem('refreshToken');
        const type = localStorage.getItem('userType');
        const endpoint = type === 'character' ? '/api/auth/character/refresh' : '/api/auth/refresh';
        
        const response = await axios.post(`${API_URL}${endpoint}`, { refreshToken });
        const { accessToken, refreshToken: newRefreshToken } = response.data;
        
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', newRefreshToken);
        
        if (originalRequest.headers) {
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        }
        
        processQueue(null, accessToken);
        return apiClient(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError, null);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userType');
        window.location.href = '/login';
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }
    
    return Promise.reject(error);
  }
);

export default apiClient;