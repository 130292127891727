import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { 
  PublicCharacter, 
  getPublicCharacterById, 
  updatePublicCharacter,
  deletePublicCharacter,
  addPublicCharacterPhotos,
  deletePublicCharacterPhoto,
  getPublicCharacters
} from '../../services/publicCharacterService';
import { useNotification } from '../components/Notification';
import { 
  ChevronLeftIcon, 
  ChevronRightIcon, 
  ChevronUpDownIcon,
  CheckIcon 
} from '@heroicons/react/20/solid';
import { FaImage, FaTrash } from 'react-icons/fa';
import { useDropzone } from 'react-dropzone';
import { ImSpinner2 } from 'react-icons/im';
import { MdCheckCircle, MdError } from 'react-icons/md';
import { Switch, Listbox } from '@headlessui/react';
import ConfirmDialog from '../../shared/ConfirmDialog';

const characterTypes = [
  { id: 'Mos Craciun', name: 'Moș Crăciun' },
  { id: 'Mos Nicolae', name: 'Moș Nicolae' },
  { id: 'Craciunita', name: 'Crăciuniță' },
  { id: 'Personaj', name: 'Personaj' },
];

const PublicCharacterDetailPage: React.FC = () => {
  const { characterId } = useParams<{ characterId: string }>();
  const [character, setCharacter] = useState<PublicCharacter | null>(null);
  const [uploadStatus, setUploadStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const [showDropdown, setShowDropdown] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const { addNotification } = useNotification();
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const fetchCharacterDetails = useCallback(async () => {
    if (!characterId) return;

    try {
      const data = await getPublicCharacterById(characterId);
      setCharacter(data);
    } catch (error) {
      console.error('Eroare la obținerea detaliilor personajului:', error);
      addNotification('error', 'Eroare la încărcarea detaliilor personajului');
    }
  }, [characterId, addNotification]);

  useEffect(() => {
    fetchCharacterDetails();
  }, [fetchCharacterDetails]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleUpload = async (acceptedFiles: File[]) => {
    if (!character) return;

    const formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append('photos', file);
    });

    setUploadStatus('loading');
    try {
      const response = await addPublicCharacterPhotos(character._id, formData);
      setCharacter(prev => prev ? {
        ...prev,
        gallery: [...prev.gallery, ...response.photos]
      } : null);
      setUploadStatus('success');
      addNotification('success', 'Imaginile au fost încărcate cu succes');
    } catch (error) {
      console.error('Eroare la încărcarea imaginilor:', error);
      setUploadStatus('error');
      addNotification('error', 'Eroare la încărcarea imaginilor');
    } finally {
      setTimeout(() => setUploadStatus('idle'), 3000);
    }
  };

  const handleDeletePhoto = async (photoIndex: number) => {
    if (!character) return;

    try {
      const updatedPhotos = await deletePublicCharacterPhoto(character._id, photoIndex);
      setCharacter(prev => prev ? { ...prev, gallery: updatedPhotos } : null);
      addNotification('success', 'Fotografia a fost ștearsă cu succes');
    } catch (error) {
      console.error('Eroare la ștergerea fotografiei:', error);
      addNotification('error', 'Eroare la ștergerea fotografiei');
    }
  };

  const navigateToAdjacentCharacter = async (direction: 'prev' | 'next') => {
    try {
      const characters = await getPublicCharacters();
      const currentIndex = characters.findIndex(c => c._id === character?._id);
      if (currentIndex === -1) return;

      let newIndex;
      if (direction === 'prev') {
        newIndex = currentIndex > 0 ? currentIndex - 1 : characters.length - 1;
      } else {
        newIndex = currentIndex < characters.length - 1 ? currentIndex + 1 : 0;
      }

      navigate(`/dashboard/public/personaje/${characters[newIndex]._id}`);
    } catch (error) {
      console.error('Eroare la navigarea între personaje:', error);
      addNotification('error', 'Nu s-a putut naviga la personajul adiacent');
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
    setShowDropdown(false);
  };

  const handleSave = async () => {
    if (!character) return;

    try {
      const updatedCharacter = await updatePublicCharacter(character._id, character);
      setCharacter(updatedCharacter);
      setIsEditing(false);
      addNotification('success', 'Datele personajului au fost actualizate cu succes');
    } catch (error) {
      console.error('Eroare la actualizarea personajului:', error);
      addNotification('error', 'Nu s-au putut actualiza datele personajului');
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    fetchCharacterDetails();
  };

  const handleDelete = async () => {
    if (!character?._id) return;

    try {
      await deletePublicCharacter(character._id);
      addNotification('success', 'Personajul a fost șters cu succes');
      navigate('/dashboard/public/personaje', { replace: true });
    } catch (error) {
      console.error('Eroare la ștergerea personajului:', error);
      addNotification('error', 'Nu s-a putut șterge personajul');
    }
  };

  const handleUpdateField = async (field: keyof PublicCharacter, value: any) => {
    if (character) {
      try {
        const updatedCharacter = await updatePublicCharacter(character._id, { [field]: value });
        setCharacter(updatedCharacter);
        addNotification('success', 'Datele personajului au fost actualizate cu succes');
      } catch (error) {
        console.error('Eroare la actualizarea personajului:', error);
        addNotification('error', 'Nu s-au putut actualiza datele personajului');
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleUpload,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    disabled: !isEditing
  });

  const renderUploadStatus = () => {
    switch (uploadStatus) {
      case 'loading':
        return <ImSpinner2 className="animate-spin text-blue-500" />;
      case 'success':
        return <MdCheckCircle className="text-green-500" />;
      case 'error':
        return <MdError className="text-red-500" />;
      default:
        return <FaImage className="text-gray-400" />;
    }
  };

  if (!character) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <ImSpinner2 className="h-8 w-8 animate-spin text-indigo-600" />
      </div>
    );
  }

  return (
    <div className="max-w-8xl mx-auto">
      {/* Header */}
      <div className="sm:flex sm:items-center sm:justify-between">
        <div className="sm:flex sm:items-center">
          <Link
            to="/dashboard/public/personaje"
            className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
          >
            ← Înapoi la lista de personaje
          </Link>
        </div>
        <div className="mt-4 flex items-center justify-between sm:mt-0 sm:ml-6 sm:flex-shrink-0">
          <div className="flex items-center space-x-3">
            <button
              onClick={() => navigateToAdjacentCharacter('prev')}
              className="inline-flex items-center rounded-full border border-gray-300 bg-white p-1 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              onClick={() => navigateToAdjacentCharacter('next')}
              className="inline-flex items-center rounded-full border border-gray-300 bg-white p-1 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="relative ml-3" ref={dropdownRef}>
            <button
              type="button"
              onClick={() => setShowDropdown(!showDropdown)}
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Acțiuni
              <ChevronUpDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            </button>
            {showDropdown && (
              <div className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <button
                  onClick={handleEdit}
                  className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-50"
                >
                  Editare
                </button>
                <button
                  onClick={() => setShowDeleteConfirm(true)}
                  className="block w-full px-4 py-2 text-left text-sm text-red-700 hover:bg-red-50"
                >
                  Ștergere
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {isEditing && (
        <div className="mt-4 rounded-md bg-yellow-50 p-4 border border-yellow-200">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">Mod Editare</h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>Modificările vor fi salvate doar după ce apeși butonul "Salvează".</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Detalii personaj */}
      <div className="mt-6 space-y-8">
        {/* Imagine Principală */}
        <div className="border-t border-gray-200 pt-6">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Imagine Principală</h2>
          <div className="mt-4">
            {character.mainImage ? (
              <div className="relative group w-full max-w-md">
                <img
                  src={character.mainImage}
                  alt="Imagine principală"
                  className="w-full h-64 object-cover rounded-lg shadow-sm"
                />
                {isEditing && (
                  <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity">
                    <button
                      onClick={() => handleUpdateField('mainImage', '')}
                      className="bg-red-500 text-white p-2 rounded-full hover:bg-red-600 transition-colors"
                    >
                      <FaTrash className="h-4 w-4" />
                    </button>
                  </div>
                )}
              </div>
            ) : isEditing && (
              <div
                {...getRootProps()}
                className="w-full max-w-md h-64 flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded-lg hover:border-indigo-500 cursor-pointer transition-colors"
              >
                <input {...getInputProps()} />
                <div className="text-4xl mb-2">
                  {renderUploadStatus()}
                </div>
                <p className="text-sm font-medium text-gray-500">
                  Adaugă imagine principală
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Galerie Foto */}
        <div className="border-t border-gray-200 pt-6">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Galerie Foto</h2>
          <div className="mt-4 flex overflow-x-auto space-x-4 pb-4">
            {character.gallery.map((photo, index) => (
              <div key={index} className="flex-shrink-0 relative group">
                <img
                  src={photo}
                  alt={` Fotografie ${index + 1}`}
                  className="h-80 w-80 object-cover rounded-lg shadow-sm"
                />
                {isEditing && (
                  <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity">
                    <button
                      onClick={() => handleDeletePhoto(index)}
                      className="bg-red-500 text-white p-2 rounded-full hover:bg-red-600 transition-colors"
                    >
                      <FaTrash className="h-4 w-4" />
                    </button>
                  </div>
                )}
              </div>
            ))}
            {isEditing && (
              <div
                {...getRootProps()}
                className="flex-shrink-0 h-80 w-80 flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded-lg hover:border-indigo-500 cursor-pointer transition-colors"
              >
                <input {...getInputProps()} />
                <div className="text-4xl mb-2">
                  {renderUploadStatus()}
                </div>
                <p className="text-sm font-medium text-gray-500">
                  Adaugă fotografii
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Detalii */}
        <div className="border-t border-gray-200 pt-6">
          <h2 className="text-base font-semibold leading-7 text-gray-900 mb-4">Detalii Personaj</h2>
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <label htmlFor="name" className="block text-sm/6 font-medium text-gray-900">
                Nume
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="name"
                  value={character.name}
                  onChange={(e) => handleUpdateField('name', e.target.value)}
                  disabled={!isEditing}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm/6"
                />
              </div>
            </div>

            <div className="sm:col-span-1">
              <label className="block text-sm/6 font-medium text-gray-900">
                Tip Personaj
              </label>
              <div className="mt-2">
                <Listbox
                  value={characterTypes.find(t => t.id === character.characterType)}
                  onChange={(value) => handleUpdateField('characterType', value.id)}
                  disabled={!isEditing}
                >
                  <div className="relative">
                    <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm/6">
                      <span className="block truncate">
                        {characterTypes.find(t => t.id === character.characterType)?.name}
                      </span>
                      {isEditing && (
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                      )}
                    </Listbox.Button>
                    {isEditing && (
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {characterTypes.map((type) => (
                          <Listbox.Option
                            key={type.id}
                            value={type}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-3 pr-9 ${
                                active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                              }`
                            }
                          >
                            {({ selected, active }) => (
                              <>
                                <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>
                                  {type.name}
                                </span>
                                {selected && (
                                  <span
                                    className={`absolute inset-y-0 right-0 flex items-center pr-3 ${
                                      active ? 'text-white' : 'text-indigo-600'
                                    }`}
                                  >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                )}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    )}
                  </div>
                </Listbox>
              </div>
            </div>

            <div className="sm:col-span-2">
              <Switch.Group>
                <div className="flex items-center justify-between">
                  <Switch.Label className="text-sm/6 font-medium text-gray-900">
                    Exclusiv Online
                  </Switch.Label>
                  <Switch
                    checked={character.isExclusivelyOnline}
                    onChange={(checked) => handleUpdateField('isExclusivelyOnline', checked)}
                    disabled={!isEditing}
                    className={`${
                      character.isExclusivelyOnline ? 'bg-indigo-600' : 'bg-gray-200'
                    } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50`}
                  >
                    <span
                      className={`${
                        character.isExclusivelyOnline ? 'translate-x-5' : 'translate-x-0'
                      } pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>
                </div>
              </Switch.Group>
            </div>
          </div>
        </div>
      </div>

      {/* Butoane de acțiune */}
      {isEditing && (
        <div className="mt-6 flex items-center justify-end gap-x-6 border-t border-gray-200 pt-6">
          <button
            type="button"
            onClick={handleCancel}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Anulează
          </button>
          <button
            type="button"
            onClick={handleSave}
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Salvează
          </button>
        </div>
      )}

      {/* Dialog de confirmare ștergere */}
      <ConfirmDialog
        open={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        onConfirm={handleDelete}
        title="Confirmare ștergere"
        description="Ești sigur că vrei să ștergi acest personaj? Această acțiune nu poate fi anulată."
        confirmText="Șterge"
        cancelText="Anulează"
      />
    </div>
  );
};

export default PublicCharacterDetailPage;