import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/PublicNavbar';
import PublicFooter from '../components/PublicFooter';
import WhatsAppFloatingButton from '../components/WhatsAppFloatingButton';
import CampaignHero from '../components/CampaignHero';
import CampaignAbout from '../components/CampaignAbout';
import CampaignFAQ from '../components/CampaignFAQ';
import ContactForm from '../components/ContactForm';
import clsx from 'clsx';

function Photos() {
  let rotations = ['rotate-2', '-rotate-2', 'rotate-2', 'rotate-2', '-rotate-2']

  const images = [
    {
      src: "https://images.pexels.com/photos/3150031/pexels-photo-3150031.jpeg?auto=compress&cs=tinysrgb&w=800",
      alt: "Moș Crăciun cu cadouri și decor festiv"
    },
    {
      src: "https://images.pexels.com/photos/3303613/pexels-photo-3303613.jpeg?auto=compress&cs=tinysrgb&w=800",
      alt: "Familie bucurându-se de atmosfera de Crăciun"
    },
    {
      src: "https://images.pexels.com/photos/701025/pexels-photo-701025.jpeg?auto=compress&cs=tinysrgb&w=800",
      alt: "Copii deschizând cadouri de Crăciun"
    },
    {
      src: "https://images.pexels.com/photos/701020/pexels-photo-701020.jpeg?auto=compress&cs=tinysrgb&w=800",
      alt: "Decorațiuni festive de Crăciun"
    },
    {
      src: "https://images.pexels.com/photos/28998264/pexels-photo-28998264/free-photo-of-young-girl-enjoying-christmas-toy-train-set-indoors.jpeg?auto=compress&cs=tinysrgb&w=800",
      alt: "Fetiță bucurându-se de trenulețul de Crăciun"
    }
  ].slice(0, 5);

  return (
    <div className="mt-16 sm:mt-20">
      <div className="-my-4 flex justify-center gap-5 overflow-hidden py-4 sm:gap-8">
        {images.map((image, imageIndex) => (
          <div
            key={image.src}
            className={clsx(
              'relative aspect-[9/10] w-44 flex-none overflow-hidden rounded-xl bg-zinc-100 sm:w-72 sm:rounded-2xl dark:bg-zinc-800',
              rotations[imageIndex % rotations.length],
            )}
          >
            <img
              src={image.src}
              alt={image.alt}
              sizes="(min-width: 640px) 18rem, 11rem"
              className="absolute inset-0 h-full w-full object-cover"
              loading="lazy"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

const DaruimImpreuna = () => {
  const pageTitle = "Dăruim Împreună | Campanie de Crăciun pentru Familii în Nevoie";
  const pageDescription = "Alătură-te inițiativei noastre de a aduce magia Crăciunului în casele familiilor nevoiașe. Donăm 10% din profitul fiecărei vizite pentru a face sărbătorile mai luminoase pentru toți.";
  const canonicalUrl = "https://www.inchiriazamoscraciun.ro/daruim-impreuna";

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": pageTitle,
    "description": pageDescription,
    "url": canonicalUrl,
    "mainEntity": {
      "@type": "Organization",
      "name": "Închiriază Moș Crăciun",
      "description": "Serviciu de vizite ale lui Moș Crăciun și campanie caritabilă pentru familii în nevoie",
      "url": "https://www.inchiriazamoscraciun.ro",
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "0748161436",
        "contactType": "customer service"
      },
      "address": {
        "@type": "PostalAddress",
        "addressCountry": "RO"
      }
    }
  };

  return (
    <div className="min-h-screen bg-white">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
        
        {/* Open Graph */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content="https://www.inchiriazamoscraciun.ro/og-image.jpg" />
        <meta property="og:locale" content="ro_RO" />
        <meta property="og:site_name" content="Închiriază Moș Crăciun" />

        {/* Keywords */}
        <meta name="keywords" content="Moș Crăciun la domiciliu, donații, caritate, familii nevoiașe, Crăciun, campanie caritabilă, moș crăciun închiriere, vizită moș crăciun, sărbători, bucuresti" />

        {/* Additional SEO tags */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Închiriază Moș Crăciun" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="geo.region" content="RO" />
        <meta name="geo.position" content="44.4268;26.1025" />
        <meta name="ICBM" content="44.4268, 26.1025" />
        <meta name="language" content="Romanian" />

        {/* Phone number detection for mobile */}
        <meta name="format-detection" content="telephone=yes" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <Navbar />
      
      <main className="overflow-hidden">
        <CampaignHero />
        
        <div className="mt-16">
          <Photos />
        </div>

        <CampaignAbout />
        <CampaignFAQ />
        <ContactForm />
        <WhatsAppFloatingButton />
      </main>
      
      <PublicFooter />
    </div>
  );
};

export default DaruimImpreuna;