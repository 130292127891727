import React, { useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import Input from '../../shared/Input';
import Button from '../../shared/Button';
import { Tab } from '@headlessui/react';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login, characterLogin } = useAuth();
  
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
  }

  const handleSubmit = async (e: React.FormEvent, isCharacter: boolean) => {
    e.preventDefault();
    console.log(`LoginForm: handleSubmit început pentru ${isCharacter ? 'caracter' : 'admin'}`);
    setError('');

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email || !emailPattern.test(email)) {
      console.log('LoginForm: Email invalid');
      setError('Email invalid');
      return;
    }

    if (!password) {
      console.log('LoginForm: Parola lipsă');
      setError('Parola este obligatorie');
      return;
    }

    try {
      console.log('LoginForm: Încercare de autentificare');
      if (isCharacter) {
        await characterLogin(email, password);
      } else {
        await login(email, password);
      }
      console.log('LoginForm: Autentificare reușită');
    } catch (error) {
      console.error('LoginForm: Eroare la autentificare', error);
      setError('Eroare la autentificare');
    }
  };

  return (
    <Tab.Group>
      <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 mb-6">
        <Tab
          className={({ selected }) =>
            classNames(
              'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
              'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
              selected
                ? 'bg-white text-blue-700 shadow'
                : 'text-white hover:bg-white/[0.12] hover:text-blue-500'
            )
          }
        >
          Administrator
        </Tab>
        <Tab
          className={({ selected }) =>
            classNames(
              'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
              'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
              selected
                ? 'bg-white text-blue-700 shadow'
                : 'text-blue-500 hover:bg-white/[0.12] hover:text-white'
            )
          }
        >
          Model
        </Tab>
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel>
          <form onSubmit={(e) => handleSubmit(e, false)} className="space-y-6">
            <Input
              label="Email"
              id="admin-email"
              name="email"
              type="email"
              placeholder="admin@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={error && !email ? 'Email obligatoriu' : ''}
              autoComplete="email"
            />
            <Input
              label="Password"
              id="admin-password"
              name="password"
              type="password"
              placeholder="Introduceți parola"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={error && !password ? 'Parola obligatorie' : ''}
              autoComplete="current-password"
            />
            {error && <p className="text-red-500 text-sm">{error}</p>}
            <Button text="Autentificare Administrator" type="submit" className="w-full" />
          </form>
        </Tab.Panel>
        <Tab.Panel>
          <form onSubmit={(e) => handleSubmit(e, true)} className="space-y-6">
            <Input
              label="Email"
              id="character-email"
              name="email"
              type="email"
              placeholder="model@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={error && !email ? 'Email obligatoriu' : ''}
              autoComplete="email"
            />
            <Input
              label="Password"
              id="character-password"
              name="password"
              type="password"
              placeholder="Introduceți parola"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={error && !password ? 'Parola obligatorie' : ''}
              autoComplete="current-password"
            />
            {error && <p className="text-red-500 text-sm">{error}</p>}
            <Button text="Autentificare Model" type="submit" className="w-full" />
          </form>
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};

export default LoginForm;
