import React from 'react';

const Soon = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-start md:justify-center bg-white md:p-4">
      <div className="w-full max-w-4xl relative">
        <div className="relative max-h-[500px] overflow-hidden">
          <img 
            src="/img/underconstructionasset.jpg" 
            alt="Moș Crăciun" 
            className="w-full h-[500px] object-cover object-center"
          />
          <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-white via-white/80 to-transparent" />
        </div>
        <div className="mt-8 px-4 md:ml-8">
          <h1 className="text-4xl font-light mb-4 text-gray-800" style={{
            fontFamily: "'Playfair Display', serif"
          }}>
            Facem câteva lucrări de întreținere
          </h1>
          <p className="text-xl text-gray-600" style={{
            fontFamily: "'Open Sans', sans-serif"
          }}>
            Revenim în curând cu surprize magice de Crăciun!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Soon;