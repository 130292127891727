import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { getCurrentUserProfile } from '../../services/userService';
import AdminNavbar from '../components/AdminNavbar';
import Sidebar from '../components/Sidebar';
import ModelPage from './ModelPage';
import ModelDetailPage from './ModelDetailPage';
import CustomerPage from './CustomerPage';  
import DriverPage from './DriverPage'; 
import Stats from '../components/Stats';
import Charts from '../components/Charts';
import RecentOrders from '../components/RecentOrdersTable';
import CustomerDetailPage from '../components/CustomerDetailPage';
import DriverDetailPage from '../components/DriverDetailPage';
import OrderPage from './OrderPage';
import OrderView from '../components/OrderView';
import NewOrderPage from './NewOrderPage';
import Users from './Users';
import PublicModelPage from './PublicModelPage'; 
import PublicCharacterDetailPage from './PublicCharacterDetailPage';
import CharacterStatsPage from './CharacterEarningsPage';
import ExpensePage from './ExpensePage';
import LogsPage from './LogsPage';

const DashboardHome = () => {
  const [daysUntilChristmas, setDaysUntilChristmas] = useState<number>(0);
  const [userProfile, setUserProfile] = useState<{ firstName: string } | null>(null); // Starea pentru profilul utilizatorului

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profile = await getCurrentUserProfile(); // Obținem profilul utilizatorului
        setUserProfile({ firstName: profile.firstName });
      } catch (error) {
        console.error('Eroare la obținerea profilului utilizatorului', error);
      }
    };

    fetchUserProfile();
  }, []);

  useEffect(() => {
    const today = new Date();
    const christmas = new Date(today.getFullYear(), 11, 25);
    const diff = Math.ceil((christmas.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
    setDaysUntilChristmas(diff);
  }, []);

  return (
    <div className="">
      <h1 className="text-2xl font-semibold text-gray-900">
        👋 Salut, {userProfile ? userProfile.firstName : 'Utilizator'}! Mai sunt {daysUntilChristmas} zile până la Crăciun! 🎄
      </h1>
      <p className="mt-2 text-lg text-gray-700">
        Verifică mai jos ultimele statistici și activitatea din comenzi.
      </p>

      <div className="mt-8">
        <Stats />
      </div>

      <div className="mt-12">
        <Charts />
      </div>

      <div className="mt-12">
        <RecentOrders />
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div>
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="lg:pl-72">
        <AdminNavbar setSidebarOpen={setSidebarOpen} />

        <main className="py-10">
          <div className="px-4 sm:px-6 lg:px-8">
            <Routes>
              <Route path="/" element={<DashboardHome />} />
              <Route path="modele" element={<ModelPage />} />
              <Route path="modele/:modelId" element={<ModelDetailPage />} />
              <Route path="clienti" element={<CustomerPage />} />
              <Route path="clienti/:clientSlug" element={<CustomerDetailPage />} />
              <Route path="soferi" element={<DriverPage />} />
              <Route path="soferi/:driverSlug" element={<DriverDetailPage />} />
              <Route path="comenzi" element={<OrderPage />} />
              <Route path="comenzi/comanda-noua" element={<NewOrderPage />} />
              <Route path="comenzi/:orderSlug" element={<OrderView />} />
              <Route path="utilizatori" element={<Users />} /> 
              <Route path="public/personaje" element={<PublicModelPage />} /> 
              <Route path="public/personaje/:characterId" element={<PublicCharacterDetailPage />} />
              <Route path="financiar/modele" element={<CharacterStatsPage />} />
              <Route path="financiar/modele/:characterId" element={<CharacterStatsPage />} />
              <Route path="financiar/cheltuieli" element={<ExpensePage />} />
              <Route path="activitate" element={<LogsPage />} />
              <Route path="*" element={<Navigate to="/dashboard" replace />} />
            </Routes>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
