import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { formatInTimeZone } from 'date-fns-tz';
import { ro } from 'date-fns/locale';
import { Character, CharacterStats } from '../../services/characterService';

interface ExportToPDFProps {
  stats: CharacterStats;
  selectedCharacter: Character;
  filterDates: {
    startDate: string;
    endDate: string;
  };
  hideAmountInExport: boolean;
  setError: (error: string | null) => void;
}

const formatRomanianNumber = (value: number) => {
  const withDecimals = value.toFixed(2);
  const [integerPart, decimalPart] = withDecimals.split('.');
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return `${formattedInteger},${decimalPart}`;
};

const exportToPDF = ({
  stats,
  selectedCharacter,
  filterDates,
  hideAmountInExport,
  setError
}: ExportToPDFProps) => {
  if (!stats || !selectedCharacter) return;

  try {
    const doc = new jsPDF();
    let yPos = 20;

    // Header
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.text("Raport Venituri", doc.internal.pageSize.width / 2, yPos, { align: 'center' });
    
    yPos += 10;
    doc.setFontSize(12);
    doc.text(`${selectedCharacter.firstName} ${selectedCharacter.lastName}`, doc.internal.pageSize.width / 2, yPos, { align: 'center' });
    
    // Perioada
    yPos += 15;
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    const periodText = `Perioada: ${filterDates.startDate ? 
      formatInTimeZone(new Date(filterDates.startDate), 'Europe/Bucharest', 'dd.MM.yyyy', { locale: ro }) : 
      'Început'} - ${filterDates.endDate ? 
      formatInTimeZone(new Date(filterDates.endDate), 'Europe/Bucharest', 'dd.MM.yyyy', { locale: ro }) : 
      'Prezent'}`;
    doc.text(periodText, 15, yPos);
    
    // Sumar
    yPos += 10;
    doc.setFillColor(240, 240, 240);
    doc.rect(15, yPos, 180, 25, 'F');
    doc.setFont("helvetica", "bold");
    yPos += 7;
    doc.text(`Total Comenzi: ${stats.totalOrders}`, 20, yPos);
    yPos += 7;
    doc.text(`Total Venituri: ${formatRomanianNumber(stats.totalEarnings)} RON`, 20, yPos);
    yPos += 7;
    const totalEarnings = stats.earningsAsMain + stats.earningsAsExtra;
    doc.text(`Total Venituri Model: ${formatRomanianNumber(totalEarnings)} RON`, 20, yPos);

    // Tabel comenzi
    yPos += 15;
    const tableColumns = hideAmountInExport 
      ? [['Data', 'Adresă', 'Câștig Model (RON)']]
      : [['Data', 'Adresă', 'Total Comandă (RON)', 'Câștig Model (RON)']];

    const tableRows = stats.orders.map(order => {
      const date = formatInTimeZone(new Date(order.period.startDate), 'Europe/Bucharest', 'dd MMMM yyyy', { locale: ro });
      return hideAmountInExport
        ? [
            date, 
            order.address || 'N/A',
            formatRomanianNumber(order.characterEarnings)
          ]
        : [
            date,
            order.address || 'N/A',
            formatRomanianNumber(order.totalAmount),
            formatRomanianNumber(order.characterEarnings)
          ];
    });

    (doc as any).autoTable({
      startY: yPos,
      head: tableColumns,
      body: tableRows,
      styles: {
        fontSize: 9,
        cellPadding: 3,
        lineColor: [80, 80, 80],
        lineWidth: 0.1
      },
      headStyles: {
        fillColor: [63, 81, 181],
        textColor: 255,
        fontSize: 9,
        fontStyle: 'bold',
        halign: 'left'
      },
      columnStyles: {
        0: { cellWidth: 35 },
        1: { cellWidth: 'auto' },
        2: { cellWidth: 30, halign: 'right' },
        ...(hideAmountInExport ? {} : { 3: { cellWidth: 30, halign: 'right' } })
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245]
      },
      margin: { top: 70, left: 15, right: 15 }
    });

    // Footer
    const pageCount = (doc as any).internal.getNumberOfPages();
    const currentDate = formatInTimeZone(new Date(), 'Europe/Bucharest', 'dd.MM.yyyy HH:mm', { locale: ro });
    
    for(let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(8);
      doc.text(
        `www.inchiriazamoscraciun.ro | contact@inchiriazamoscraciun.ro | +40 749 318 211`,
        doc.internal.pageSize.width / 2,
        doc.internal.pageSize.height - 20,
        { align: 'center' }
      );
      doc.text(
        `Pagina ${i} din ${pageCount} | Generat la ${currentDate}`,
        doc.internal.pageSize.width / 2,
        doc.internal.pageSize.height - 10,
        { align: 'center' }
      );
    }

    doc.save(`raport-${selectedCharacter.firstName.toLowerCase()}-${selectedCharacter.lastName.toLowerCase()}.pdf`);
  } catch (err) {
    setError('Eroare la generarea raportului PDF');
    console.error('PDF generation error:', err);
  }
};

export default exportToPDF;