import React, { useState, useEffect } from 'react';
import { 
  ChevronRightIcon,
  PencilIcon, 
  TrashIcon,
  CurrencyDollarIcon 
} from '@heroicons/react/24/outline';
import { 
  getExpenses, 
  addExpense, 
  updateExpense,
  deleteExpense,
  calculateProfit, 
  Expense, 
  ProfitStats 
} from '../../services/expenseService';
import { format } from 'date-fns';
import { ro } from 'date-fns/locale';
import ReusableDialog from '../../shared/ReusableDialog';
import ExpenseStats from '../components/ExpenseStats';

const ExpensePageContent: React.FC = () => {
  const [expenses, setExpenses] = useState<Expense[]>([]);
  const [stats, setStats] = useState<ProfitStats | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState<Expense | null>(null);
  const [formData, setFormData] = useState({
    amount: '',
    provider: '',
    details: '',
    date: format(new Date(), 'yyyy-MM-dd')
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [expensesData, profitData] = await Promise.all([
        getExpenses(),
        calculateProfit()
      ]);
      setExpenses(expensesData);
      setStats(profitData);
      setLoading(false);
    } catch (error) {
      console.error('Eroare la încărcarea datelor:', error);
      setError('Nu s-au putut încărca datele');
      setLoading(false);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleOpenDialog = (expense?: Expense) => {
    if (expense) {
      setSelectedExpense(expense);
      setFormData({
        amount: expense.amount.toString(),
        provider: expense.provider || '',
        details: expense.details || '',
        date: format(new Date(expense.date || new Date()), 'yyyy-MM-dd')
      });
    } else {
      setSelectedExpense(null);
      setFormData({
        amount: '',
        provider: '',
        details: '',
        date: format(new Date(), 'yyyy-MM-dd')
      });
    }
    setIsDialogOpen(true);
  };

  const handleSubmit = async () => {
    try {
      const expenseData = {
        amount: Number(formData.amount),
        provider: formData.provider || undefined,
        details: formData.details || undefined,
        date: new Date(formData.date)
      };

      if (selectedExpense?._id) {
        await updateExpense(selectedExpense._id, expenseData);
      } else {
        await addExpense(expenseData);
      }
      
      setIsDialogOpen(false);
      fetchData();
    } catch (error) {
      console.error('Eroare la salvarea cheltuielii:', error);
    }
  };

  const handleDelete = async (expense: Expense) => {
    setSelectedExpense(expense);
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    try {
      if (selectedExpense?._id) {
        await deleteExpense(selectedExpense._id);
        setIsDeleteDialogOpen(false);
        fetchData();
      }
    } catch (error) {
      console.error('Eroare la ștergerea cheltuielii:', error);
    }
  };

  if (loading) return <div>Se încarcă...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="p-0">
      <div className="sm:flex sm:items-center mb-8">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">Lista cheltuielilor</h1>
          <p className="mt-2 text-sm text-gray-700">
            O listă completă cu toate cheltuielile companiei, inclusiv detalii despre furnizori și sume.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            onClick={() => handleOpenDialog()}
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Adaugă cheltuială
          </button>
        </div>
      </div>

      {/* Statistici */}
      {stats && <ExpenseStats stats={stats} />}

      {expenses.length > 0 ? (
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Data</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Furnizor</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Detalii</th>
                  <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Sumă</th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Acțiuni</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {expenses.map((expense) => (
                  <tr key={expense._id} className="hover:bg-gray-50">
                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm text-gray-900 sm:pl-0">
                      {format(new Date(expense.date!), 'dd MMMM yyyy', { locale: ro })}
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      {expense.provider || 'N/A'}
                    </td>
                    <td className="px-3 py-5 text-sm text-gray-500">
                      {expense.details || 'N/A'}
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-right font-medium text-gray-900">
                      {expense.amount} RON
                    </td>
                    <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <button
                        onClick={() => handleOpenDialog(expense)}
                        className="text-indigo-600 hover:text-indigo-900 mx-2"
                      >
                        <PencilIcon className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => handleDelete(expense)}
                        className="text-red-600 hover:text-red-900 mx-2"
                      >
                        <TrashIcon className="h-5 w-5" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    ) : (
      <div className="mt-8 text-center">
        <div className="rounded-lg border-2 border-dashed border-gray-300 p-12">
          <CurrencyDollarIcon className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-semibold text-gray-900">Nu există cheltuieli</h3>
          <p className="mt-1 text-sm text-gray-500">
            Începeți prin a adăuga prima cheltuială a companiei.
          </p>
          <div className="mt-6">
            <button
              type="button"
              onClick={() => handleOpenDialog()}
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <PencilIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              Adaugă cheltuială
            </button>
          </div>
        </div>
      </div>
    )}

      {/* Dialog Adăugare/Editare */}
      <ReusableDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        icon={<CurrencyDollarIcon className="h-6 w-6 text-green-600" aria-hidden="true" />}
        title={selectedExpense ? "Editează cheltuiala" : "Adaugă o nouă cheltuială"}
        primaryButton={{
          onClick: handleSubmit,
          className: "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2",
          children: selectedExpense ? "Salvează" : "Adaugă"
        }}
        secondaryButton={{
          onClick: () => setIsDialogOpen(false),
          className: "mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0",
          children: "Anulează"
        }}
      >
        <p className="mt-2 mb-6 text-sm text-gray-500">
          Completați informațiile despre cheltuială.
        </p>
        
        <div className="grid grid-cols-1 gap-x-6 gap-y-4">
          <div>
            <label htmlFor="amount" className="block text-sm font-medium leading-6 text-gray-900 text-left">
              Suma (RON)
            </label>
            <div className="mt-2 relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <CurrencyDollarIcon className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="number"
                step="0.01"
                name="amount"
                id="amount"
                required
                value={formData.amount}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 py-2 pl-10 pr-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="0.00"
              />
            </div>
          </div>

          <div>
            <label htmlFor="provider" className="block text-sm font-medium leading-6 text-gray-900 text-left">
              Furnizor
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="provider"
                id="provider"
                value={formData.provider}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label htmlFor="date" className="block text-sm font-medium leading-6 text-gray-900 text-left">
              Data
            </label>
            <div className="mt-2">
              <input
                type="date"
                name="date"
                id="date"
                value={formData.date}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label htmlFor="details" className="block text-sm font-medium leading-6 text-gray-900 text-left">
              Detalii
            </label>
            <div className="mt-2">
              <textarea
                name="details"
                id="details"
                rows={3}
                value={formData.details}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>
      </ReusableDialog>

      {/* Dialog de confirmare ștergere */}
      <ReusableDialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        icon={<TrashIcon className="h-6 w-6 text-white-600" aria-hidden="true" />}
        title="Confirmare ștergere"
        primaryButton={{
          onClick: confirmDelete,
          className: "inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 sm:col-start-2",
          children: "Șterge"
        }}
        secondaryButton={{
          onClick: () => setIsDeleteDialogOpen(false),
          className: "mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0",
          children: "Anulează"
        }}
      >
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            Sunteți sigur că doriți să ștergeți această cheltuială? 
            {selectedExpense && (
              <span className="font-medium">
                {` Suma: ${selectedExpense.amount} RON${selectedExpense.provider ? `, Furnizor: ${selectedExpense.provider}` : ''}`}
              </span>
            )}
          </p>
          <p className="mt-2 text-sm text-red-500">
            Această acțiune este permanentă și nu poate fi anulată.
          </p>
        </div>
      </ReusableDialog>
    </div>
  );
};

export default ExpensePageContent;