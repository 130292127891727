import React from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

interface Option {
  id: number;
  name: string;
}

interface SelectorProps {
  label: string;
  options: Option[];
  selected: Option;
  onChange: (option: Option) => void;
}

const Selector: React.FC<SelectorProps> = ({ label, options, selected, onChange }) => {
  return (
    <div className="w-full">
      <Listbox value={selected} onChange={onChange}>
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium text-gray-700 mb-1">{label}</Listbox.Label>
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm h-9">
                <span className="block truncate">{selected.name}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={React.Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.id}
                      className={({ active }) =>
                        `${active ? 'text-white bg-indigo-600' : 'text-gray-900'}
                        cursor-default select-none relative py-2 pl-3 pr-9`
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={`${selected ? 'font-semibold' : 'font-normal'} block truncate`}>
                            {option.name}
                          </span>
                          {selected ? (
                            <span
                              className={`${active ? 'text-white' : 'text-indigo-600'}
                                absolute inset-y-0 right-0 flex items-center pr-4`}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default Selector;