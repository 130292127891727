import React, { useState, useEffect } from 'react';
import { Dialog, Transition, Switch } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Input from '../../shared/Input';
import Upload from '../../shared/Upload';
import Selector from '../../shared/Selector';

interface AddPublicCharacterDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  onAddCharacter: (character: globalThis.FormData) => Promise<void>;
}

interface FormData {
  name: string;
  mainImage: File | null;
  gallery: File[];
  characterType: Option;
  isExclusivelyOnline: boolean;
}

interface Option {
  id: number;
  name: string;
}

const characterTypes = [
  { id: 1, name: 'Mos Craciun' },
  { id: 2, name: 'Mos Nicolae' },
  { id: 3, name: 'Craciunita' },
  { id: 4, name: 'Spiridus' },
];

const AddPublicCharacterDrawer: React.FC<AddPublicCharacterDrawerProps> = ({ 
  isOpen, 
  onClose, 
  onAddCharacter 
}) => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    mainImage: null,
    gallery: [],
    characterType: characterTypes[0],
    isExclusivelyOnline: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleCharacterTypeChange = (selectedType: Option) => {
    setFormData(prevData => ({ ...prevData, characterType: selectedType }));
  };

  const handleExclusiveOnlineChange = (checked: boolean) => {
    setFormData(prevData => ({ ...prevData, isExclusivelyOnline: checked }));
  };

  const isFormValid = () => {
    return formData.name.trim() !== '' && formData.mainImage !== null;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isFormValid()) return;

    setIsSubmitting(true);
    const data = new globalThis.FormData();

    data.append('name', formData.name);
    data.append('characterType', formData.characterType.name);
    data.append('isExclusivelyOnline', formData.isExclusivelyOnline.toString());
    
    if (formData.mainImage) {
      data.append('mainImage', formData.mainImage);
    }
    
    formData.gallery.forEach((file) => {
      data.append('gallery', file);
    });

    try {
      await onAddCharacter(data);
      onClose();
    } catch (error) {
      console.error('Eroare la adăugarea personajului public:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setFormData({
        name: '',
        mainImage: null,
        gallery: [],
        characterType: characterTypes[0],
        isExclusivelyOnline: false,
      });
    }
  }, [isOpen]);

  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={React.Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    {/* Header */}
                    <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">
                          Adaugă un personaj public
                        </Dialog.Title>
                        <button
                          type="button"
                          className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={onClose}
                        >
                          <span className="sr-only">Închide panoul</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>

                    {/* Form Content */}
                    <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                      <form onSubmit={handleSubmit} className="space-y-6">
                        {/* Nume */}
                        <Input
                          label="Nume"
                          id="name"
                          name="name"
                          type="text"
                          value={formData.name}
                          onChange={handleInputChange}
                        />

                        {/* Tip personaj */}
                        <Selector
                          label="Tip personaj"
                          options={characterTypes}
                          selected={formData.characterType}
                          onChange={handleCharacterTypeChange}
                        />

                        {/* Toggle Exclusiv Online */}
                        <div className="flex items-center justify-between bg-white rounded-lg border border-gray-200 p-4">
                          <div className="flex flex-col">
                            <span className="text-sm font-medium text-gray-900">
                              Exclusiv Online
                            </span>
                            <span className="text-sm text-gray-500">
                              Acest personaj va fi disponibil doar pentru întâlniri online
                            </span>
                          </div>
                          <Switch
                            checked={formData.isExclusivelyOnline}
                            onChange={handleExclusiveOnlineChange}
                            className={`${
                              formData.isExclusivelyOnline ? 'bg-indigo-600' : 'bg-gray-200'
                            } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                          >
                            <span
                              aria-hidden="true"
                              className={`${
                                formData.isExclusivelyOnline ? 'translate-x-5' : 'translate-x-0'
                              } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                            />
                          </Switch>
                        </div>

                        {/* Upload Imagine Reprezentativă */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-2">
                            Imagine Reprezentativă
                          </label>
                          <Upload
                            onImagesSelected={(files) => setFormData(prev => ({ ...prev, mainImage: files[0] }))}
                          />
                        </div>

                        {/* Upload Galerie */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-2">
                            Galerie Imagini
                          </label>
                          <Upload
                            onImagesSelected={(files) => setFormData(prev => ({ ...prev, gallery: files }))}
                          />
                        </div>
                      </form>
                    </div>

                    {/* Footer */}
                    <div className="flex flex-shrink-0 justify-end px-4 py-4 gap-3 border-t border-gray-200">
                      <button
                        type="button"
                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={onClose}
                        disabled={isSubmitting}
                      >
                        Anulează
                      </button>
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        disabled={!isFormValid() || isSubmitting}
                        className={`inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${
                          !isFormValid() || isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                      >
                        {isSubmitting ? (
                          <>
                            <svg 
                              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" 
                              xmlns="http://www.w3.org/2000/svg" 
                              fill="none" 
                              viewBox="0 0 24 24"
                            >
                              <circle 
                                className="opacity-25" 
                                cx="12" 
                                cy="12" 
                                r="10" 
                                stroke="currentColor" 
                                strokeWidth="4"
                              />
                              <path 
                                className="opacity-75" 
                                fill="currentColor" 
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              />
                            </svg>
                            Adăugăm personajul...
                          </>
                        ) : (
                          'Adaugă personaj'
                        )}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddPublicCharacterDrawer;