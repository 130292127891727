import axios from 'axios';

export const API_URL = process.env.REACT_APP_API_URL || 'https://64.226.116.172:5000';

export interface OrderDetails {
  orderId: string;
  period: {
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
  };
  client: {
    name: string;
    phone: string;
    email: string;
    type: string;
  };
  location: {
    address: string;
    addressDetails?: string;
    zone?: string;
  };
  financials: {
    orderTotal: number;
    characterEarnings: number;
    driverEarnings: number; // Adăugat pentru remunerația șoferului
  };
  driver?: {
    name: string;
    phone: string;
    email: string;
  } | null; // Adăugat pentru detaliile șoferului
  role: 'Main' | 'Extra';
  status: string;
}

export interface CharacterDetails {
  totalOrders: number;
  totalEarnings: number;
  earningsAsMain: number;
  earningsAsExtra: number;
  totalDriverEarnings: number; // Adăugat pentru totalul câștigurilor șoferului
  detailedOrders: OrderDetails[];
}

// Funcție pentru obținerea detaliilor unui personaj
export const getCharacterDetails = async (characterId: string): Promise<CharacterDetails> => {
  try {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      throw new Error('Token-ul nu există. Vă rugăm să vă autentificați.');
    }

    const response = await axios.get<CharacterDetails>(
      `${API_URL}/api/characters/details/${characterId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Eroare la obținerea detaliilor personajului:', error);
    throw error;
  }
};

// Funcție pentru curățarea unui text de adresă
export const cleanAddress = (text: string): string => {
  return text?.trim().replace(/"{2,}/g, '"').replace(/^"|"$/g, '').trim() || '';
};

// Funcție pentru sortarea comenzilor după dată
export const sortOrdersByDate = (orders: OrderDetails[]): OrderDetails[] => {
  return orders.sort(
    (a, b) => new Date(b.period.startDate).getTime() - new Date(a.period.startDate).getTime()
  );
};
