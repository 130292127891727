import React, { useState } from 'react';

interface CustomTimerInputProps {
  selectedTime: string;
  onChange: (time: string) => void;
  label: string;
  name: string;
  placeholder: string;
}

const CustomTimerInput: React.FC<CustomTimerInputProps> = ({ selectedTime, onChange, label, name, placeholder }) => {
  const [time, setTime] = useState<string>(selectedTime);

  // Funcție de validare pentru ora în format HH:mm
  const isValidTime = (time: string) => {
    const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
    return timeRegex.test(time);
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = e.target.value;

    // Verifică dacă timpul introdus este valid
    if (isValidTime(newTime)) {
      setTime(newTime); // Actualizează starea locală
      onChange(newTime); // Trimite ora validă către parent
    } else {
      setTime(newTime); // Actualizează starea locală chiar și pentru input nevalid
    }
  };

  return (
    <div className="custom-time-input-container w-full">
      <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <div className="relative w-full">
        <input
          type="text"
          value={time}
          onChange={handleTimeChange}
          name={name}
          placeholder={placeholder}
          className="block w-full rounded-md border border-gray-300 py-1.5 px-3 text-gray-900 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          pattern="[0-9]{2}:[0-9]{2}" // Forțează input-ul să fie HH:mm
        />
      </div>
    </div>
  );
};

export default CustomTimerInput;
