import React from 'react';

interface CharacterStatsDisplayProps {
  stats: {
    totalOrders: number;
    totalAmount: number;
    characterEarnings: number;
  };
}

// Funcție pentru formatarea valorilor monetare în format românesc corect
const formatMoneyValue = (value: number) => {
  const withDecimals = value.toFixed(2);
  const [integerPart, decimalPart] = withDecimals.split('.');
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return `${formattedInteger},${decimalPart}`;
};

// Funcție pentru formatarea numărului de comenzi (cu punct pentru mii)
const formatCountValue = (value: number) => {
  return Math.floor(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const StatValue: React.FC<{ 
  value: number; 
  isProfit?: boolean; 
  isCount?: boolean 
}> = ({ value, isProfit, isCount }) => {
  const getProfitColor = (profit: number) => {
    if (profit < 0) return 'text-red-500';
    if (profit < 50000) return 'text-orange-500';
    return 'text-green-500';
  };

  return (
    <div
      className={`relative inline-flex items-baseline text-4xl font-semibold tracking-tight ${
        isProfit ? getProfitColor(value) : 'text-white'
      }`}
    >
      <div className="flex items-baseline">
        <span>
          {isCount ? formatCountValue(value) : formatMoneyValue(value)}
        </span>
        {!isCount && <span className="text-lg font-medium ml-2">RON</span>}
      </div>
    </div>
  );
};

const CharacterStatsDisplay: React.FC<CharacterStatsDisplayProps> = ({ stats }) => {
  const statItems = React.useMemo(() => [
    { 
      name: 'Total Comenzi', 
      value: stats.totalOrders, 
      isCount: true 
    },
    { 
      name: 'Total Venituri', 
      value: stats.totalAmount 
    },
    { 
      name: 'Total Venituri Model', 
      value: stats.characterEarnings, 
      isProfit: true 
    },
  ], [stats]);

  return (
    <div className="bg-gray-900 rounded-lg overflow-hidden">
      <div className="grid grid-cols-1 gap-px bg-white/5 sm:grid-cols-3">
        {statItems.map((stat, index) => (
          <div
            key={stat.name}
            className={`bg-gray-900 px-4 py-6 sm:px-6 lg:px-8 ${
              index === 0 ? 'sm:rounded-tl-lg' : ''
            } ${index === statItems.length - 1 ? 'sm:rounded-br-lg' : ''}`}
          >
            <p className="text-sm font-medium leading-6 text-gray-400">
              {stat.name}
            </p>
            <p className="mt-2">
              <StatValue
                value={stat.value}
                isProfit={stat.isProfit}
                isCount={stat.isCount}
              />
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CharacterStatsDisplay;