import React from 'react';
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import { Character, CharacterStats } from '../../services/characterService';
import EarningsFilters from './EarningsFilters';
import exportToPDF from './EarningsExport';

interface EarningsHeaderProps {
  searchTerm: string;
  onSearchChange: (value: string) => void;
  selectedCharacter: Character | null;
  onCharacterChange: (character: Character | null) => void;
  characters: Character[];
  filterDates: {
    startDate: string;
    endDate: string;
  };
  onDateChange: (type: 'startDate' | 'endDate', date: string) => void;
  stats: CharacterStats | null;
  setError: (error: string | null) => void;
}

const EarningsHeader: React.FC<EarningsHeaderProps> = ({
  searchTerm,
  onSearchChange,
  selectedCharacter,
  onCharacterChange,
  characters,
  filterDates,
  onDateChange,
  stats,
  setError,
}) => {
  const [hideAmountInExport, setHideAmountInExport] = React.useState(false);

  const handleExportClick = () => {
    if (!stats || !selectedCharacter) return;

    exportToPDF({
      stats,
      selectedCharacter,
      filterDates,
      hideAmountInExport,
      setError
    });
  };

  const characterName = selectedCharacter 
    ? `${selectedCharacter.firstName} ${selectedCharacter.lastName}`
    : '';

  return (
    <>
      <div className="sm:flex sm:items-center mb-8">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">
            Raport Venituri - {characterName}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Listă detaliată cu toate veniturile și comenzile modelului.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex items-center gap-4">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={hideAmountInExport}
              onChange={(e) => setHideAmountInExport(e.target.checked)}
              className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            <span className="text-sm text-gray-700">Ascunde total comandă în export</span>
          </label>
          <button
            onClick={handleExportClick}
            disabled={!selectedCharacter || !stats}
            className={`inline-flex items-center rounded-md px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
              !selectedCharacter || !stats
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-indigo-600 hover:bg-indigo-500'
            }`}
          >
            <DocumentArrowDownIcon className="h-5 w-5 mr-2" />
            Exportă PDF
          </button>
        </div>
      </div>

      <EarningsFilters
        searchTerm={searchTerm}
        onSearchChange={onSearchChange}
        selectedCharacter={selectedCharacter}
        onCharacterChange={onCharacterChange}
        characters={characters}
        filterDates={filterDates}
        onDateChange={onDateChange}
      />
    </>
  );
};

export default EarningsHeader;