import React from 'react';

interface ExpenseStatsProps {
  stats: {
    totalRevenue: number;
    totalDriverFees: number;
    totalModelFees: number;
    totalExpenses: number;
    profit: number;
  };
}

// Funcție pentru formatarea valorilor monetare în format românesc 
const formatMoneyValue = (value: number) => {
  const roundedValue = Math.round(value); 
  return roundedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const StatValue: React.FC<{ value: number; isProfit?: boolean }> = ({ value, isProfit }) => {
  const getProfitColor = (profit: number) => {
    if (profit < 0) return 'text-red-500';
    if (profit < 50000) return 'text-orange-500';
    return 'text-green-500';
  };

  return (
    <div
      className={`relative inline-flex items-baseline text-4xl font-semibold tracking-tight ${
        isProfit ? getProfitColor(value) : 'text-white'
      }`}
    >
      <span>{formatMoneyValue(value)}</span>
      <span className="text-lg font-medium ml-2">RON</span>
    </div>
  );
};

const ExpenseStats: React.FC<ExpenseStatsProps> = ({ stats }) => {
  const statItems = React.useMemo(() => [
    { name: 'Sume din comenzi', value: stats.totalRevenue },
    { name: 'Costuri Șoferi', value: stats.totalDriverFees },
    { name: 'Costuri Modele', value: stats.totalModelFees },
    { name: 'Cheltuieli Totale', value: stats.totalExpenses },
    { name: 'Profit', value: stats.profit, isProfit: true },
  ], [stats]);

  return (
    <div className="bg-gray-900 rounded-lg overflow-hidden">
      <div className="grid grid-cols-1 gap-px bg-white/5 sm:grid-cols-2 lg:grid-cols-5">
        {statItems.map((stat, index) => (
          <div
            key={stat.name}
            className={`bg-gray-900 px-4 py-6 sm:px-6 lg:px-8 ${
              index === 0 ? 'sm:rounded-tl-lg' : ''
            } ${index === statItems.length - 1 ? 'sm:rounded-br-lg' : ''}`}
          >
            <p className="text-sm font-medium leading-6 text-gray-400">{stat.name}</p>
            <p className="mt-2">
              <StatValue value={stat.value} isProfit={stat.isProfit} />
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExpenseStats;