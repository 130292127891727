import React, { useEffect, useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { TruckIcon } from '@heroicons/react/24/outline';
import { getDrivers, addDriver, Driver } from '../../services/driverService';
import { useNavigate } from 'react-router-dom';
import ReusableDialog from '../../shared/ReusableDialog';
import { useNotification, NotificationProvider } from '../components/Notification';

const DriverPageContent: React.FC = () => {
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newDriver, setNewDriver] = useState<Partial<Driver>>({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  });
  const navigate = useNavigate();
  const { addNotification } = useNotification();

  useEffect(() => {
    fetchDrivers();
  }, []);

  const fetchDrivers = async () => {
    try {
      const data = await getDrivers();
      setDrivers(data);
      setLoading(false);
    } catch (err) {
      setError('Eroare la obținerea șoferilor');
      setLoading(false);
      addNotification('error', 'Eroare la obținerea șoferilor', 'Nu s-au putut încărca datele șoferilor.');
    }
  };

  const handleRowClick = (driver: Driver) => {
    if (driver._id) {
      const slugName = `${driver.firstName.toLowerCase()}-${driver.lastName.toLowerCase()}`.replace(/\s+/g, '-');
      navigate(`/dashboard/soferi/${slugName}-${driver._id}`);
    } else {
      console.error('Driver ID is undefined:', driver);
      addNotification('error', 'Eroare', 'Nu se poate accesa detaliile șoferului.');
    }
  };  

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewDriver(prev => ({ ...prev, [name]: value }));
  };

  const handleAddDriver = async () => {
    try {
      await addDriver(newDriver as Driver);
      setIsDialogOpen(false);
      fetchDrivers();
      handleResetForm(); // Resetare formular după adăugare
      addNotification('success', 'Șofer adăugat cu succes', 'Noul șofer a fost adăugat în baza de date.');
    } catch (error) {
      console.error('Eroare la adăugarea șoferului:', error);
      addNotification('error', 'Eroare la adăugarea șoferului', 'Nu s-a putut adăuga noul șofer. Vă rugăm să încercați din nou.');
    }
  };

  const handleResetForm = () => {
    setNewDriver({
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
    });
  };

  const formatDate = (dateString: string | Date | undefined): string => {
    if (!dateString) return 'N/A'; 
  
    const date = typeof dateString === 'string' ? new Date(dateString) : dateString; 
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };
  

  if (loading) return <div>Se încarcă...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="p-0">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">Lista șoferilor</h1>
          <p className="mt-2 text-sm text-gray-700">
            O listă completă cu toți șoferii noștri, inclusiv detalii despre nume, telefon, email și data adăugării.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            onClick={() => setIsDialogOpen(true)}
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Adaugă șofer
          </button>
        </div>
      </div>

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Prenume</th>
                  <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">Nume</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Telefon</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Data Adăugării</th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Acțiuni</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {drivers.map((driver, index) => (
                  <tr key={`${driver._id}-${index}`} onClick={() => handleRowClick(driver)} className="hover:bg-gray-50 cursor-pointer">
                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{driver.firstName}</td>
                    <td className="whitespace-nowrap py-5 text-sm text-gray-500">{driver.lastName}</td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{driver.phone}</td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{driver.email}</td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{formatDate(driver.createdAt)}</td>
                    <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ReusableDialog
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          handleResetForm(); // Resetare la închiderea dialogului
        }}
        icon={<TruckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />}
        title="Adaugă un nou șofer"
        primaryButton={{
          onClick: handleAddDriver,
          className: "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2",
          children: "Adaugă"
        }}
        secondaryButton={{
          onClick: () => setIsDialogOpen(false),
          className: "mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0",
          children: "Anulează"
        }}
      >
        <p className="mt-2 mb-6 text-sm text-gray-500 text-pretty">
          Completați informațiile despre noul șofer pentru a-l adăuga în baza de date.
        </p>
        <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900 text-left">
              Prenume
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="firstName"
                id="firstName"
                value={newDriver.firstName}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900 text-left">
              Nume
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="lastName"
                id="lastName"
                value={newDriver.lastName}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900 text-left">
              Telefon
            </label>
            <div className="mt-2">
              <input
                type="tel"
                name="phone"
                id="phone"
                value={newDriver.phone}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 text-left">
              Email
            </label>
            <div className="mt-2">
              <input
                type="email"
                name="email"
                id="email"
                value={newDriver.email}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>
      </ReusableDialog>
    </div>
  );
};

const DriverPage: React.FC = () => {
  return (
    <NotificationProvider>
      <DriverPageContent />
    </NotificationProvider>
  );
};

export default DriverPage;
