import React, { useState, useEffect } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Menu, Transition } from '@headlessui/react';
import { useSpring, animated, config } from '@react-spring/web';
import { getOrderStats } from '../../services/orderService';

interface Stat {
  name: string;
  value: number;
  change?: string;
  changeType?: 'positive' | 'negative';
  unit?: string;
}

const visitorStats: Stat[] = [
  { name: 'Vizitatori', value: 1543, change: '+12%', changeType: 'positive' },
  { name: 'Timp mediu pe site', value: 204, change: '+8%', changeType: 'positive', unit: 'sec' },
  { name: 'Pagini vizitate', value: 4721, change: '+15%', changeType: 'positive' },
  { name: 'Rata de conversie', value: 2.3, change: '+0.5%', changeType: 'positive', unit: '%' },
];

const Stats: React.FC = () => {
  const [stats, setStats] = useState<Stat[]>([
    { name: 'Comenzi', value: 0 },
    { name: 'Sume din comenzi', value: 0, unit: 'RON' },
    { name: 'Remunerații modele', value: 0, unit: 'RON' },
    { name: 'Remunerații șoferi', value: 0, unit: 'RON' },
  ]);
  const [isVisitorStats, setIsVisitorStats] = useState(false);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        if (isVisitorStats) {
          setStats(visitorStats);
        } else {
          const { totalOrders, totalAmount, totalModelFees, totalDriverFees } = await getOrderStats();

          setStats([
            { name: 'Comenzi', value: totalOrders },
            { name: 'Sume din comenzi', value: totalAmount, unit: 'RON' },
            { name: 'Remunerații modele', value: totalModelFees, unit: 'RON' },
            { name: 'Remunerații șoferi', value: totalDriverFees, unit: 'RON' },
          ]);
        }
      } catch (error) {
        console.error('Eroare la obținerea statisticilor', error);
      }
    };

    fetchStats();
  }, [isVisitorStats]);

  const toggleStats = () => {
    setIsVisitorStats(!isVisitorStats);
  };

  const AnimatedValue: React.FC<{ value: number; unit?: string }> = ({ value, unit }) => {
    const { number } = useSpring({
      from: { number: 0 },
      number: value,
      delay: 200,
      config: config.molasses,
    });

    return (
      <animated.span>
        {number.to((n) => {
          if (unit === 'sec') {
            const minutes = Math.floor(n / 60);
            const seconds = Math.floor(n % 60);
            return `${minutes}:${seconds.toString().padStart(2, '0')}`;
          } else if (unit === '%') {
            return n.toFixed(1) + '%';
          } else {
            return Math.floor(n).toLocaleString() + (unit ? ` ${unit}` : '');
          }
        })}
      </animated.span>
    );
  };

  return (
    <div className="bg-white mt-7 mb-7">
      <div className="mx-auto bg-gray-900 rounded-lg overflow-hidden">
        <div className="grid grid-cols-1 gap-px bg-white/5 sm:grid-cols-2 lg:grid-cols-4">
          {stats.map((stat, index) => (
            <div 
              key={stat.name} 
              className={`bg-gray-900 px-4 py-6 sm:px-6 lg:px-8 ${index === 0 ? "sm:rounded-tl-lg" : ""} ${index === stats.length - 1 ? "sm:rounded-br-lg" : ""}`}
            >
              <div className="flex justify-between items-center">
                <p className="text-sm font-medium leading-6 text-gray-400">
                  {index === 0 ? (
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="inline-flex justify-center items-center gap-x-1.5 text-sm font-semibold text-gray-400 py-1">
                          {stat.name}
                          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={React.Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute left-0 z-10 mt-2 w-auto origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={toggleStats}
                                  className={`${active ? 'bg-gray-100' : ''} block px-4 py-2 text-sm text-gray-700 whitespace-nowrap`}
                                >
                                  {isVisitorStats ? 'Comenzi' : 'Vizitatori și interacțiuni'}
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  ) : (
                    stat.name
                  )}
                </p>
              </div>
              <p className="mt-2 flex items-baseline gap-x-2">
                <span className="text-4xl font-semibold tracking-tight text-white">
                  <AnimatedValue value={stat.value} unit={stat.unit} />
                </span>
                {stat.change && (
                  <span
                    className={stat.changeType === 'negative' ? 'text-red-400' : 'text-green-400'}
                  >
                    {stat.change}
                  </span>
                )}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Stats;
