import axios from 'axios';

export const fetchLogs = async () => {
  const token = localStorage.getItem('accessToken');
  if (!token) throw new Error('Token lipsă. Vă rugăm să vă autentificați.');

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/logs`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Mapăm logurile pentru afișare
    const logs = response.data.map((log: any) => ({
      userName: log.userName || 'Un utilizator',
      message: log.message || 'a efectuat o acțiune.',
      timestamp: new Date(log.timestamp).toLocaleString('ro-RO', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }), // Formatarea personalizată a datei și orei
    }));

    return logs;
  } catch (error: any) {
    console.error('Eroare la obținerea log-urilor:', error.response?.data || error.message);
    throw new Error('Eroare la obținerea log-urilor.');
  }
};
