import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import Navbar from '../components/PublicNavbar';
import PublicFooter from '../components/PublicFooter';

export default function CookiePolicy() {
  return (
    <div className="min-h-screen bg-white flex flex-col">
      <Navbar />
      <main className="flex-grow">
        <div className="bg-white py-16 sm:py-24">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <p className="text-base font-semibold leading-7 text-red-600">Politica de Cookie-uri</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Politica de Cookie-uri inchiriazamoscraciun.ro</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
                Această politică de cookie-uri explică modul în care inchiriazamoscraciun.ro utilizează cookie-urile și tehnologiile similare pentru a vă oferi cea mai bună experiență pe site-ul nostru.
              </p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl lg:mx-0 lg:max-w-none">
              <div className="grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-700 lg:max-w-none lg:grid-cols-2">
                <div>
                  <h2 className="text-2xl font-bold tracking-tight text-gray-900">Ce sunt cookie-urile?</h2>
                  <p className="mt-6">
                    Cookie-urile sunt fișiere text mici care sunt plasate pe dispozitivul dumneavoastră atunci când vizitați un site web. Ele sunt utilizate pe scară largă pentru a face site-urile web să funcționeze mai eficient, precum și pentru a furniza informații proprietarilor site-ului.
                  </p>
                  
                  <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Cum folosim cookie-urile</h2>
                  <p className="mt-6">
                    Pe inchiriazamoscraciun.ro, utilizăm cookie-uri în mod limitat și specific:
                  </p>
                  <ul role="list" className="mt-8 space-y-8 text-gray-600">
                    <li className="flex gap-x-3">
                      <InformationCircleIcon className="mt-1 h-5 w-5 flex-none text-red-600" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-gray-900">Google Analytics:</strong> Utilizăm cookie-uri de la terți furnizate de Google Analytics pentru a înțelege mai bine cum este utilizat site-ul nostru. Aceste cookie-uri ne ajută să analizăm traficul și să îmbunătățim experiența utilizatorilor.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <InformationCircleIcon className="mt-1 h-5 w-5 flex-none text-red-600" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-gray-900">Cookie-uri funcționale:</strong> Aceste cookie-uri permit site-ului nostru să rețină alegerile pe care le faceți (cum ar fi limba sau regiunea în care vă aflați) și să ofere funcționalități îmbunătățite și mai personale.
                      </span>
                    </li>
                  </ul>
                </div>
                <div>
                  <h2 className="text-2xl font-bold tracking-tight text-gray-900">Tipuri de cookie-uri</h2>
                  <p className="mt-6">
                    Există mai multe tipuri de cookie-uri, fiecare cu un rol specific:
                  </p>
                  <ul role="list" className="mt-8 space-y-8 text-gray-600">
                    <li className="flex gap-x-3">
                      <InformationCircleIcon className="mt-1 h-5 w-5 flex-none text-red-600" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-gray-900">Cookie-uri strict necesare:</strong> Esențiale pentru funcționarea site-ului web.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <InformationCircleIcon className="mt-1 h-5 w-5 flex-none text-red-600" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-gray-900">Cookie-uri de performanță:</strong> Colectează informații anonime despre modul în care vizitatorii utilizează un site web.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <InformationCircleIcon className="mt-1 h-5 w-5 flex-none text-red-600" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-gray-900">Cookie-uri de funcționalitate:</strong> Rețin alegerile făcute de utilizator pentru a îmbunătăți experiența.
                      </span>
                    </li>
                  </ul>
                  
                  <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Controlul cookie-urilor</h2>
                  <p className="mt-6">
                    Puteți controla și/sau șterge cookie-urile după cum doriți. Pentru detalii, consultați aboutcookies.org. Puteți șterge toate cookie-urile care sunt deja pe computerul dumneavoastră și puteți seta majoritatea browserelor să le blocheze. Dacă faceți acest lucru, este posibil să trebuiască să ajustați manual unele preferințe de fiecare dată când vizitați un site, iar unele servicii și funcționalități s-ar putea să nu funcționeze.
                  </p>
                </div>
              </div>
              <div className="mt-16 max-w-2xl">
                <h2 className="text-2xl font-bold tracking-tight text-gray-900">Modificări ale politicii de cookie-uri</h2>
                <p className="mt-6">
                  Ne rezervăm dreptul de a modifica această politică de cookie-uri în orice moment. Orice modificări vor fi postate pe această pagină și vor intra în vigoare imediat. Vă încurajăm să revizuiți periodic această politică pentru a fi la curent cu modul în care protejăm informațiile dumneavoastră.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <PublicFooter />
    </div>
  );
}