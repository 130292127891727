import React, { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

interface UserDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (userData: UserData) => void;
  userToEdit?: UserData | null;  // Prop pentru utilizatorul care va fi editat
}

interface UserData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: 'Super Admin' | 'Admin' | 'Moderator';
  password: string;
}

const roles: ('Super Admin' | 'Admin' | 'Moderator')[] = ['Super Admin', 'Admin', 'Moderator'];

const initialUserData: UserData = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  role: 'Moderator',
  password: '',
};

const UserDialog: React.FC<UserDialogProps> = ({ isOpen, onClose, onSave, userToEdit }) => {
  const [userData, setUserData] = useState<UserData>(initialUserData);

  useEffect(() => {
    if (!isOpen) {
      setUserData(initialUserData);  // Resetare la datele inițiale când dialogul este închis
    } else if (userToEdit) {
      setUserData(userToEdit);  // Populăm formularul cu datele existente ale utilizatorului la editare
    }
  }, [isOpen, userToEdit]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData(prev => ({ ...prev, [name]: value }));  // Actualizare state în funcție de input
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(userData);  // Trimiterea datelor pentru salvare/adăugare
    onClose();  // Închidere dialog
  };

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      {userToEdit ? 'Editează utilizator' : 'Adaugă utilizator nou'}
                    </Dialog.Title>
                    <p className="mt-2 text-sm text-gray-500">
                      Completați informațiile de mai jos pentru a {userToEdit ? 'edita' : 'adăuga'} un utilizator în sistem.
                    </p>
                    <form onSubmit={handleSubmit} className="mt-4 space-y-4">
                      <div className="grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-2">
                        <div>
                          <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">Nume</label>
                          <input
                            type="text"
                            name="lastName"
                            id="lastName"
                            value={userData.lastName}
                            onChange={handleInputChange}
                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                        <div>
                          <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">Prenume</label>
                          <input
                            type="text"
                            name="firstName"
                            id="firstName"
                            value={userData.firstName}
                            onChange={handleInputChange}
                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                        <div className="sm:col-span-2">
                          <Listbox value={userData.role} onChange={(newRole) => setUserData(prev => ({ ...prev, role: newRole }))}>
                            <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Rol</Listbox.Label>
                            <div className="relative mt-2">
                              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <span className="block truncate">{userData.role}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                              </Listbox.Button>
                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {roles.map((role) => (
                                  <Listbox.Option
                                    key={role}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-3 pr-9 ${
                                        active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                      }`
                                    }
                                    value={role}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>
                                          {role}
                                        </span>
                                        {selected ? (
                                          <span className={`absolute inset-y-0 right-0 flex items-center pr-4 ${active ? 'text-white' : 'text-indigo-600'}`}>
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </div>
                          </Listbox>
                        </div>
                        <div className="sm:col-span-2">
                          <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email</label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            value={userData.email}
                            onChange={handleInputChange}
                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                        <div className="sm:col-span-2">
                          <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">Telefon</label>
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            value={userData.phone}
                            onChange={handleInputChange}
                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                        <div className="sm:col-span-2">
                          <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Parolă</label>
                          <input
                            type="password"
                            name="password"
                            id="password"
                            value={userData.password}
                            onChange={handleInputChange}
                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                        <button
                          type="submit"
                          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:ml-3 sm:w-auto"
                        >
                          Salvează
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={onClose}
                        >
                          Anulează
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default UserDialog;
