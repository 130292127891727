import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/PublicNavbar';
import Hero from '../components/Hero';
import ChristmasServicesBento from '../components/ChristmasServicesBento';
import PublicFooter from '../components/PublicFooter';
import AboutUs from '../components/AboutUs';
import CTA from '../components/Cta';
import WhatsAppFloatingButton from '../components/WhatsAppFloatingButton';

const Home = () => {
  const location = useLocation();
  const pageTitle = "Închiriază Moș Crăciun | Servicii Profesionale de Moș Crăciun în București și Ilfov";
  const pageDescription = "Servicii profesionale de închiriere Moș Crăciun pentru evenimente private, corporative și grădinițe în București și Ilfov. Bucură-te de magia sărbătorilor cu un Moș Crăciun autentic!";
  const canonicalUrl = "https://www.inchiriazamoscraciun.ro";

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Închiriază Moș Crăciun",
    "description": "Servicii profesionale de închiriere Moș Crăciun în București și Ilfov",
    "url": "https://www.inchiriazamoscraciun.ro",
    "logo": "https://www.inchiriazamoscraciun.ro/logo.png",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "0748161436",
      "contactType": "customer service",
      "areaServed": ["București", "Ilfov"],
      "availableLanguage": ["Romanian"]
    },
    "address": {
      "@type": "PostalAddress",
      "addressRegion": "București",
      "addressCountry": "RO"
    },
    "sameAs": [
      "https://www.facebook.com/inchiriazamoscraciun",
      "https://www.instagram.com/inchiriazamoscraciun"
    ]
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const section = searchParams.get('section');
    if (section) {
      const element = document.getElementById(section);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    }
  }, [location]);

  return (
    <div className="min-h-screen bg-white">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
        
        {/* Open Graph */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content="https://www.inchiriazamoscraciun.ro/og-image.jpg" />
        <meta property="og:locale" content="ro_RO" />
        <meta property="og:site_name" content="Închiriază Moș Crăciun" />

        {/* Keywords */}
        <meta name="keywords" content="mos craciun bucuresti, inchiriere mos craciun, mos craciun la domiciliu, mos craciun pentru evenimente, mos craciun corporate, mos craciun gradinite, servicii mos craciun, bucuresti, ilfov, evenimente craciun" />

        {/* Additional SEO tags */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Închiriază Moș Crăciun" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="geo.region" content="RO-B" />
        <meta name="geo.placename" content="București" />
        <meta name="geo.position" content="44.4268;26.1025" />
        <meta name="ICBM" content="44.4268, 26.1025" />
        <meta name="language" content="Romanian" />

        {/* Phone number detection for mobile */}
        <meta name="format-detection" content="telephone=yes" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <Navbar />
      
      <main>
        <Hero />
        
        <motion.div
          id="servicii"
          className="mx-auto max-w-2xl lg:max-w-7xl"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <ChristmasServicesBento />
        </motion.div>
        
        <motion.div
          id="despre-noi"
          className="mx-auto max-w-2xl lg:max-w-7xl"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <AboutUs />
        </motion.div>
        
        <WhatsAppFloatingButton />
      </main>
      
      <PublicFooter />
    </div>
  );
};

export default Home;